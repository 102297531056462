import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { Participant } from "../store/interfaces";
import { FILE_REGEX } from "../../../../helpers/consts";
import { addGpxToParticipant, hideNotificationMessage } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectNotificationHidden } from "../store/selectors";
import { useNotificationModal } from "../../../../shared/NotificationModal/NotificationModal";
import { ManagedForm } from "../../../../shared/form/Form/ManagedForm";
import { CheckboxControl } from "../../../../shared/form/controls/CheckboxControl/CheckboxControl";

function createValidationSchema() {
  return Yup.object({
    notification: Yup.boolean(),
  });
}

const GpxUploadButton = ({ rowData, isUpdateAction = false, callback }: GpxUploadButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNotificationHidden = useSelector(selectNotificationHidden);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(hideNotificationMessage(e.target.checked));
  };
  const showNotification = useNotificationModal(
    t("This is a paid service. The price is 2 euros per GPX file."),
    t("Create"),
    <ManagedForm validationSchema={createValidationSchema()} onSubmit={async () => {}} values={{ notification: false }}>
      <CheckboxControl
        name="notification"
        label={t("Don’t show again")}
        onChange={onCheckboxClick}
        classNames={{ formCheck: "notification-check", checkMimic: "notification-checkbox", formLabel: "notification-label" }}
      />
    </ManagedForm>
  );

  const onGpxUploadClick = () => {
    if (isNotificationHidden) {
      onGpxUpload();
    } else {
      showNotification(() => {
        onGpxUpload();
      });
    }
  };

  const onGpxUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChangeCapture = (e: ChangeEvent<HTMLInputElement>, participant: Participant) => {
    if (!e.target.files || !e.target.files.length) {
      return null;
    }
    const gpxFile = e.target.files[0];
    if (gpxFile.name.match(FILE_REGEX)) {
      const reader = new FileReader();
      reader.readAsDataURL(gpxFile);
      reader.onloadend = () => {
        const xmlContentBase64 = (reader.result as string).split("data:application/octet-stream;base64,")[1];
        const gpxFileName = gpxFile.name;
        if (!isUpdateAction) {
          dispatch(
            addGpxToParticipant({
              ...participant,
              xmlContentBase64,
              gpxFileName,
            })
          );
        }
        callback(xmlContentBase64, gpxFileName);
      };
    }
    e.target.value = "";
  };

  return (
    <div>
      <input ref={fileInputRef} type="file" className="d-none" accept=".gpx" onChange={(e) => onFileChangeCapture(e, rowData)} />
      <Button size="sm" variant="outline-default" className="ml-2 add-gpx-btn" onClick={onGpxUploadClick}>
        {!!rowData.gpxFileName && rowData.gpxFileName}
        {!rowData.gpxFileName && t("Upload GPX")}
      </Button>
    </div>
  );
};

type GpxUploadButtonProps = {
  rowData: any;
  isUpdateAction: boolean;
  callback: (xmlContentBase64: string, gpxFileName: string) => void;
};

export default GpxUploadButton;
