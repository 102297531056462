import { call, put, takeLatest } from "redux-saga/effects";
import { setAppInfo } from "../actions";
import { apiCall } from "../../../shared/useApi";
import { RELOAD } from "../types";
import { UNAUTHORIZED_REDIRECT_PATH } from "../../../helpers/consts";
import history from "../../../helpers/history";

export function* getAppInfo() {
  try {
    const { ok, status, data } = yield call(() => apiCall("/app-info"));
    if (!ok && status === 401) {
      history.push(UNAUTHORIZED_REDIRECT_PATH);
      yield put(setAppInfo(true, false, data));
    } else {
      yield put(setAppInfo(true, true, data.user));
    }
  } catch (err) {
    if (process.env.NODE_ENV === "development") {
      console.error(err);
    }
  }
}
// long-running saga
export function* watchReloadRequest() {
  // listens for the latest `RELOAD` action,
  // `takeLatest` cancels any currently executing `getAppInfo` so that is always up-to-date
  yield takeLatest(RELOAD, getAppInfo);
}
