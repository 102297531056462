import { Participant } from "../../event/Results/store/interfaces";
import { CheckoutItem } from "./interfaces";
import { SET_CART_ITEMS, SET_PAY_PAL_PAYMENT_STATUS } from "./types";

const loadCartItems = (): CheckoutItem[] => {
  try {
    const participants = localStorage.getItem("participantsToPurchase");
    if (participants?.length) {
      const createdParticipants = JSON.parse(participants) as { [eventId: string]: Participant[] };
      if (!createdParticipants) {
        return [] as CheckoutItem[];
      }
      const values = Object.values(createdParticipants) as Participant[][];
      return values.reduce((previousValue, value) => {
        const newVal = value.map((participant) => ({
          activityId: participant.activityId,
          eventName: participant.eventName,
          eventId: participant.eventId,
          userId: participant.userId,
          trailId: participant.trailId,
          userName: participant.userName,
          gender: participant.gender,
          birthDate: participant.birthDate,
          gpxFileName: participant.gpxFileName,
          xmlContentBase64: participant.xmlContentBase64,
          title: `Upload file ${participant.gpxFileName} for user ${participant.userName} for trail ${participant.trailId} in event ${participant.eventName}`,
        })) as CheckoutItem[];
        return [...previousValue, ...newVal] as CheckoutItem[];
      }, [] as CheckoutItem[]) as CheckoutItem[];
    }
    return [] as CheckoutItem[];
  } catch (e) {
    console.error(e);
    return [] as CheckoutItem[];
  }
};

const setCartItems = (items: CheckoutItem[]) => {
  return {
    type: SET_CART_ITEMS,
    payload: { items },
  };
};

const setPayPalPaymentInProcess = (payPalPaymentInProcess: boolean) => {
  return {
    type: SET_PAY_PAL_PAYMENT_STATUS,
    payload: { payPalPaymentInProcess },
  };
};

const clearCart = () => {
  localStorage.removeItem("participantsToPurchase");
};

export { loadCartItems, setCartItems, clearCart, setPayPalPaymentInProcess };
