import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useApi } from "../../../shared/useApi";
import { EventHeader } from "../EventHeader/EventHeader";
import { EventRouter } from "../EventRouter/EventRouter";

type EventDetailsProps = {
  path: string;
  url: string;
  onCreate: (raceId: number) => void;
};

export function EventDetails({ path, url, onCreate }: EventDetailsProps) {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const [raceData, setRaceData] = useState<any>(null);
  const [title, setTitle] = useState("");
  const { get, response } = useApi("/races");

  useEffect(() => {
    if (eventId) {
      loadRace();
    } else {
      setTitle(t("New event"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  async function loadRace() {
    const raceData = await get(`/${eventId}`);

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }

    setRaceData(raceData);
  }

  return (
    <>
      <EventHeader title={raceData ? raceData.name : title} url={url}></EventHeader>
      <EventRouter route={path} url={url} onCreate={onCreate} raceData={raceData} />
    </>
  );
}
