import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import querystring from "querystring";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { simpleTextRenderer, Table } from "../../shared/Table/Table";
import { selectUser } from "../../store/global/selectors";
import { useApi } from "../../shared/useApi";
import { formatDate } from "../../shared/utils";
import "./MyEvents.scss";
import { setEventId } from "../../store/global/actions";

type MyEventsProps = {
  onSelect(selectedId: number): void;
};

export function MyEvents({ onSelect }: MyEventsProps) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [races, setRaces] = useState([]);
  const [raceCount, setRaceCount] = useState<number>(0);
  const [maxPages, setMaxPages] = useState(0);

  const { get, response, error } = useApi("/races/index");

  const [query, setQuery] = useState({ page: 1, q: "" });
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    loadRaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const maxPages = Math.ceil(raceCount / 10);
    setMaxPages(maxPages);

    const currentPage = query.page;
    setHasPreviousPage(currentPage > 1);
    setHasNextPage(currentPage < maxPages);
  }, [raceCount, query]);

  const hasPages = maxPages > 1;
  const pageNumbers = hasPages ? createPageNumbers(query.page, maxPages) : [];

  const onRowSelect = (rowData: any) => {
    onSelect(rowData.id);
    dispatch(setEventId(rowData.id));
  };

  return (
    <div className="my-events d-flex justify-content-center">
      <div className="background"></div>
      <div className="content col-md-9">
        <h1 className="welcome-message">
          {t("Welcome")}, {user?.firstName}!
        </h1>

        <div className="event-list">
          <div className="d-md-flex list-toolbar">
            <h3 className="list-title">{t("Your Events")}</h3>
            <div className="tools">
              <div className="search">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} className="search-input-icon"></FontAwesomeIcon>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl id="q" placeholder={t("Search...")} onChange={(e) => setQuery((q) => ({ ...q, q: e.target.value }))} />
                </InputGroup>
              </div>
              <div className="ml-4">
                <LinkContainer to="event/create">
                  <Button size="sm" className="text-nowrap">
                    <FontAwesomeIcon icon={faPlusCircle} className="button-icon"></FontAwesomeIcon>
                    Create new
                  </Button>
                </LinkContainer>
              </div>
            </div>
          </div>
          <Table
            columns={[
              { header: t("ID"), render: simpleTextRenderer("id") },
              { header: t("Race"), render: simpleTextRenderer("raceName") },
              { header: t("Date"), render: (d) => formatDate(d.startTime, t) },
              {
                header: t("Participants"),
                render: simpleTextRenderer("participantCount"),
              },
              { header: "", render: () => "..." },
            ]}
            data={response.data ? races : null}
            dataKeyProperty="id"
            placeholderTitle={t("No events yet.")}
            placeholderDescription={t("Create a new one using the Create button above.")}
            onSelectRow={onRowSelect}
            pagination={{
              hasPages,
              hasPreviousPage,
              hasNextPage,
              pageNumbers,
              currentPage: query.page,
              onPreviousPage: () => setQuery((q) => ({ ...q, page: q.page - 1 })),
              onPageSelect: (p) => setQuery((q) => ({ ...q, page: p })),
              onNextPage: () => setQuery((q) => ({ ...q, page: q.page + 1 })),
            }}
            loadError={
              error
                ? {
                    error: t("Error loading events."),
                    details: t("Please try again later."),
                  }
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );

  async function loadRaces() {
    const raceData = await get(`?${querystring.stringify(query)}`);

    if (response.ok) {
      setRaces(raceData.races);
      setRaceCount(raceData.count);
    } else {
      setRaces([]);
      setRaceCount(0);
    }
  }
}

const pagerSize = 5;
function createPageNumbers(currentPage: number, maxPages: number) {
  const start = Math.max(currentPage - Math.floor(pagerSize / 2), 1);
  const end = Math.min(currentPage + pagerSize - (currentPage - start) - 1, maxPages);

  const pageNumbers = [];
  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  return pageNumbers;
}
