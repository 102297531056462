import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import ReactSlider from "react-slider";
import styled, { css } from "styled-components";
import SliderTrack from "./SliderTrack/SliderTrack";
import SliderThumb from "./SliderThumb/SliderThumb";
import { FIRST_RANGE_COLOR, LAST_RANGE_COLOR } from "../utils";
import { AgeGroupContent } from "../../helpers/types/ageGroups";
import { Gender } from "../../helpers/types/genders";
import { genderOptions } from "../../helpers/enums/genders";
import iconPlus from "../../assets/svg/iconPlus.svg";
import iconOverlayPointer from "../../assets/svg/iconOverlayPointer.svg";
import "./Slider.scss";

const STEP = 1;
const MIN = 1;
const MAX = 99;
const ADD_BTN_CONTAINER_SIZE = 169;

type CreateCss = {
  valuesArrLength: number;
  colors: string[];
};

const setSliderTrackColors = ({ valuesArrLength, colors }: CreateCss) => {
  let styles = "";

  for (let i = 0; i <= valuesArrLength; i++) {
    styles += `
       .slider-track-${i} {
         background: ${colors[i]};
       }
     `;
  }

  return css`
    ${styles}
  `;
};

const StyledSlider = styled.div.attrs((props: CreateCss) => props)`
  ${({ valuesArrLength, colors }) => setSliderTrackColors({ valuesArrLength, colors })}
`;

const Slider = ({
  ageGroups,
  gender,
  activeTab,
  disableAddButtons,
  rangeStart,
  rangeEnd,
  triggerSliderRender,
  onAfterTrigger,
  onSliderRangesChange,
  onSliderBordersChange,
  onRangeCreation,
  onRangeRemove,
}: SliderType) => {
  const [isTrackOrThumbUnderAction, setIsTrackOrThumbUnderAction] = useState(false);
  const [tabsViewed, setTabsViewed] = useState<any>(genderOptions.basic.map((val) => ({ [val.name]: false })));
  const [values, setValues] = useState<number[]>([]);
  const [colors, setColors] = useState([FIRST_RANGE_COLOR, LAST_RANGE_COLOR]);
  const [showAddPointButton, setShowAddPointButton] = useState(false);
  const [cursorStyle, setCursorStyle] = useState<StyleType>({ transform: "translate3d(0,-44px,0)", display: "none" });
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const sliderContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const sliderRef = useRef() as MutableRefObject<any>;
  const addRangeBtnContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (sliderRef.current) {
      const timeout = setTimeout(() => sliderRef.current.handleResize(), 400);

      return () => clearTimeout(timeout);
    }
  }, [sliderRef]);

  useEffect(() => {
    if (sliderRef.current && triggerSliderRender) {
      sliderRef.current.onMouseUp();
      onAfterTrigger();
    }
  }, [triggerSliderRender, sliderRef, onAfterTrigger]);

  useEffect(() => {
    if (sliderRef.current && activeTab && !tabsViewed[activeTab]) {
      sliderRef.current.onMouseUp();
      setTabsViewed({ ...tabsViewed, [activeTab]: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    setCursorStyle({ ...cursorStyle, display: "flex" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseMoving]);

  useEffect(() => {
    const colors = ageGroups.map(({ firstColor, color }) => firstColor || color);
    if (colors.length === 1) {
      setColors([...colors, FIRST_RANGE_COLOR]);
    } else {
      colors[colors.length - 1] = LAST_RANGE_COLOR;
      setColors([...colors, LAST_RANGE_COLOR]);
    }
    setValues(ageGroups.map(({ ageTo }) => ageTo));
  }, [ageGroups]);

  const onOverlayMouseEnter = () => {
    setIsTrackOrThumbUnderAction(true);
    setShowAddPointButton(false);
  };

  const onOverlayMouseLeave = () => {
    setIsTrackOrThumbUnderAction(false);
  };

  const onBarMouseMove = (event: any) => {
    if (!sliderRef.current || !sliderContainerRef.current) {
      return null;
    }
    if (!showAddPointButton && !isTrackOrThumbUnderAction) {
      setShowAddPointButton(true);
    }
    setIsMouseMoving(true);
    const containerX = sliderContainerRef.current.getBoundingClientRect().x;
    const eventX = event.clientX;
    const newPosX = eventX - containerX - ADD_BTN_CONTAINER_SIZE / 3;
    setCursorStyle({ transform: `translate3d(${newPosX}px,-44px,0px)`, display: "flex" });
  };

  const onBarMouseLeave = () => {
    setShowAddPointButton(false);
  };

  const addPoint = (event: any) => {
    if (addRangeBtnContainerRef.current && sliderRef.current) {
      const containerX = sliderContainerRef.current.getBoundingClientRect().x;
      const eventX = event.clientX;
      const sliderWidth = sliderRef.current.state.sliderLength;
      const clickX = eventX - containerX - 15;
      let age = Math.floor((clickX / sliderWidth) * (rangeEnd - rangeStart) + rangeStart);
      const biggerAgeGroups = values.filter((val) => val >= age);
      const smallerAgeGroups = values.filter((val) => val < age);

      if (age <= rangeStart + 2) {
        age = rangeStart + 2;
      }
      if (age >= rangeEnd - 2) {
        age = rangeEnd - 2;
      }

      if (
        (age - smallerAgeGroups[smallerAgeGroups.length - 1] === 1 && biggerAgeGroups[0] - age === 0) ||
        (age - smallerAgeGroups[smallerAgeGroups.length - 1] === 1 && age - smallerAgeGroups[smallerAgeGroups.length - 1] === 1)
      ) {
        age = 500;
      } else {
        if (biggerAgeGroups[0] - age === 0) {
          age -= 2;
        } else if (biggerAgeGroups[0] - age === 1) {
          age -= 1;
        } else if (age - smallerAgeGroups[smallerAgeGroups.length - 1] === 1) {
          age += 1;
        }
      }
      onRangeCreation(gender, age);
      setCursorStyle({ ...cursorStyle, display: "none" });
      setIsMouseMoving(false);
      setShowAddPointButton(false);
    }
  };

  const removePoint = (rangeIndex: number) => {
    if (sliderRef.current) {
      onRangeRemove(gender as Gender, rangeIndex);
    }
  };

  return (
    <StyledSlider valuesArrLength={values.length} colors={colors}>
      <div className="slider-container">
        <label className="slider-left-border">
          <input
            name="left-border"
            placeholder="1"
            type="number"
            min={MIN}
            step={STEP}
            max={rangeEnd - 1}
            value={rangeStart}
            onChange={(event) => {
              const value = +event.target.value;
              if (value > rangeEnd - 1 || value < 1) {
                return false;
              }
              onSliderBordersChange(gender, value, true);
            }}
          />
        </label>
        <div className="slider-inner-container" onMouseMove={onBarMouseMove} onMouseLeave={onBarMouseLeave} ref={sliderContainerRef}>
          <div
            className={`add-btn-container${!disableAddButtons[gender as Gender] && showAddPointButton ? " show" : " hide"}`}
            ref={addRangeBtnContainerRef}
            style={cursorStyle}
          >
            <div className="add-btn-label-wrapper">
              <p className="add-btn-label" id="action-description">
                Add new range
              </p>
              <img src={iconOverlayPointer} alt="" width={21} height={10} className="label-pointer" />
            </div>
            <button className="add-btn" type="button" onClick={addPoint} aria-labelledby="action-description">
              <img src={iconPlus} alt="" />
            </button>
          </div>
          <ReactSlider
            className="age-groups-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            min={rangeStart + 1}
            max={rangeEnd}
            ref={sliderRef}
            minDistance={STEP + 1}
            value={ageGroups.map(({ ageTo }) => ageTo)}
            onChange={(values) => onSliderRangesChange(gender, values)}
            snapDragDisabled
            renderTrack={(props, { index }) => (
              <SliderTrack
                key={`${colors[index]}-${index}`}
                props={props}
                index={index}
                isTheLastTrack={index === values.length}
                colors={colors}
                onOverlayMouseEnter={onOverlayMouseEnter}
                onOverlayMouseLeave={onOverlayMouseLeave}
                removePoint={removePoint}
              />
            )}
            renderThumb={(props, { index, valueNow }) => (
              <SliderThumb
                key={`${colors[index]}-${index}`}
                props={props}
                value={valueNow}
                index={index}
                isTheOnlyThumb={values.length === 1}
                isTheLastThumb={index === values.length - 1}
                rangeEnd={rangeEnd}
                firstColor={index === 0 ? colors[0] : ""}
                prevColor={colors[index]}
                color={colors[index + 1] ? colors[index + 1] : colors[index]}
                lastColor={index === values.length - 1 ? colors[colors.length - 1] : ""}
                onOverlayMouseEnter={onOverlayMouseEnter}
                onOverlayMouseLeave={onOverlayMouseLeave}
              />
            )}
          />
        </div>
        <label className="slider-right-border">
          <input
            name="right-border"
            placeholder="99"
            type="number"
            min={rangeStart + 1}
            max={MAX}
            value={rangeEnd}
            onChange={async (event) => {
              const value = +event.target.value;
              if (value > 99 || value < rangeStart + 1) {
                return false;
              }
              onSliderBordersChange(gender, value, false);
            }}
          />
        </label>
      </div>
    </StyledSlider>
  );
};

type SliderType = {
  ageGroups: AgeGroupContent[];
  gender: Gender;
  activeTab?: string | null;
  rangeStart: number;
  disableAddButtons: { [gender in Gender]: boolean };
  rangeEnd: number;
  triggerSliderRender: boolean;
  onAfterTrigger: () => void;
  onSliderRangesChange: (gender: Gender, values: number[]) => void;
  onSliderBordersChange: (gender: Gender, value: number, left: boolean) => boolean;
  onRangeCreation: (gender: Gender, ageTo: number) => void;
  onRangeRemove: (gender: Gender, rangeIndex: number) => void;
};
type StyleType = {
  transform: string;
  display: any;
};

export default Slider;
