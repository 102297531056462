import { PayPalButtons } from "@paypal/react-paypal-js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { useLoaderModal } from "../../../shared/LoaderModal/LoaderModal";
import { useApi } from "../../../shared/useApi";
import { selectCartItems } from "../../Cart/store/selectors";
import { clearCart, loadCartItems, setCartItems, setPayPalPaymentInProcess } from "../../Cart/store/actions";
import { ONE_GPX_COST } from "../../../helpers/consts";

export default function PayPalCheckout() {
  const { post } = useApi("/checkout");
  const { t } = useTranslation();
  const items = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const showLoaderModal = useLoaderModal(
    t("Waiting for PayPal"),
    t("Waiting for PayPal response. It may take up to several minutes. You will be notified and redirected after response is received.")
  );

  const onPayPalPaymentSuccess = () => {
    clearCart();
    dispatch(setPayPalPaymentInProcess(true));
    showLoaderModal(() => dispatch(setCartItems(loadCartItems())));
  };

  const onPayPalPaymentError = () => {
    toastr.error(t("Purchase error"), t("An unexpected error happened while purchasing. Try again or contact us."));
    return null;
  };

  const checkout = async () => {
    const total = items.length * ONE_GPX_COST;
    const { success, data, error } = await post({
      participants: items,
      total,
    });

    if (!success) {
      console.error(error);
      return { purchaseId: "", total };
    }

    return { purchaseId: data.purchaseId, total };
  };

  async function handleCreateOrder(data: Record<string, unknown>, actions: any) {
    const { purchaseId, total } = await checkout();

    if (!purchaseId) {
      return "";
    }

    return actions.order.create({
      purchase_units: [
        {
          custom_id: purchaseId,
          amount: {
            value: total.toString(),
          },
        },
      ],
    });
  }

  async function handleApprove(data: any, actions: any) {
    await actions.order?.capture();
    onPayPalPaymentSuccess();
  }

  function handleError(err: Record<string, unknown>) {
    onPayPalPaymentError();
    console.error("PayPal error", err);
  }

  return <PayPalButtons createOrder={handleCreateOrder} onApprove={handleApprove} onError={handleError} />;
}
