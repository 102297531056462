import React, {useEffect, useRef, useState} from 'react';
import ReactMapboxGl, {Layer, Source} from 'react-mapbox-gl';
import {FitBounds} from "react-mapbox-gl/lib/map";
import {LngLat} from "mapbox-gl";
import {Col, Row} from "react-bootstrap";
import ReactSlider from "react-slider";
import {MapMarker} from "./MapMarker/MapMarker";
import satellite from '../../../assets/svg/iconEarthSatellite.svg';
import regularView from '../../../assets/svg/iconEarth.svg';
import './MapOverlay.scss';

type MapOverlayProps = {
  imageUrl: string;
  trailStart?: number[];
  trailFinish?: number[];
  overlayCoordinates?: number[][];
  previousOverlayCoordinates: number[][];
  previousOverlay: string;
  hidePreviousOverlay: boolean;
  markers?: { lat: number, lng: number, color: string }[];
  onClick: (lat: number, lng: number) => void;
};

const SLIDER_STEP = 0.1;
const SLIDER_MAX = 1;
const INITIAL_RASTER_OPACITY = 0.7;

const Mapbox = ReactMapboxGl({
  accessToken: `${process.env.REACT_APP_MAPBOX_PUBLIC_KEY}`,
});

const MapOverlay = ({
                      imageUrl,
                      onClick,
                      trailStart,
                      trailFinish,
                      hidePreviousOverlay,
                      overlayCoordinates,
                      previousOverlayCoordinates,
                      previousOverlay,
                      markers
                    }: MapOverlayProps) => {
  const coordinatesRef = useRef(overlayCoordinates);
  const [bounds, setBounds] = useState<number[][]>(new LngLat(24.776920124141384, 45.99431035005913).toBounds(100000).toArray())
  const [toggleSatelliteStyle, setToggleSatelliteStyle] = useState(false);
  // used for styling
  const [hasMapMounted, setMapMounted] = useState(false);
  // the only way to change layer coordinates dynamically
  const [renderOverlay, setRenderOverlay] = useState(true);
  const [renderPreviousOverlay, setRenderPreviousOverlay] = useState(true);
  const [overlayPaint, setOverlayPaint] = useState({'raster-opacity': INITIAL_RASTER_OPACITY});
  // a workaround for React Mapbox onClick
  // callback isn't working without changes on every render
  const handleClickRef = useRef(onClick);
  handleClickRef.current = onClick;
  
  useEffect(() => {
    if (!!previousOverlay && !!previousOverlayCoordinates) {
      setBounds([previousOverlayCoordinates[0], previousOverlayCoordinates[2]]);
    } else if (!!trailStart && !!trailFinish) {
      setBounds([trailStart, trailFinish]);
    }
    
  }, [trailStart, trailFinish, previousOverlay, previousOverlayCoordinates]);
  
  useEffect(() => {
    coordinatesRef.current = overlayCoordinates;
    setRenderOverlay(false);
    setRenderPreviousOverlay(false);
    const timeout = setTimeout(() => {
      setRenderOverlay(overlayCoordinates?.length === 4);
      setRenderPreviousOverlay(overlayCoordinates?.length !== 4 && !hidePreviousOverlay);
    }, 100);
    
    return () => clearTimeout(timeout);
  }, [overlayCoordinates, hidePreviousOverlay]);
  
  const onThumbDrag = (value: number) => {
    setOverlayPaint({...overlayPaint, "raster-opacity": value});
  };
  
  const onMapStyleChange = () => {
    setToggleSatelliteStyle(!toggleSatelliteStyle);
  };
  
  return (
    <Mapbox
      style={toggleSatelliteStyle ? process.env.REACT_APP_MAPBOX_SATELLITE_STYLE as string : process.env.REACT_APP_MAPBOX_STYLE as string}
      containerStyle={{opacity: hasMapMounted ? 1 : 0, height: '600px', width: '100%'}}
      onClick={(_, {lngLat}: any) => handleClickRef.current(lngLat.lat, lngLat.lng)}
      onStyleLoad={(map) => {
        map.resize();
        setMapMounted(true);
      }}
      onError={((_, {error}: any) => {
        // console.error(error.message);
      })}
      fitBounds={bounds as FitBounds}
      className={`overlay-map${hasMapMounted ? " show" : ""}`}
    >
      <>
        {renderPreviousOverlay && (
          <>
            <Source
              id="previousOverlaySource"
              tileJsonSource={{
                type: 'image',
                url: previousOverlay,
                coordinates: previousOverlayCoordinates
              }}
            />
            <Layer id="previousOverlay" type="raster" sourceId="previousOverlaySource" paint={overlayPaint}/>
          </>
        )}
        {!coordinatesRef.current && !!markers && !!markers.length && markers.map(({lat, lng, color}, index) => (
          <MapMarker key={color} lat={lat} lng={lng} color={color} index={index}/>
        ))}
        {!!coordinatesRef.current && (
          <>
            <Row className="overlay-controls" noGutters>
              <Col sm={4}>
                <span className="control-label">
                  Overlay opacity:
                </span>
              </Col>
              <Col sm={8} className="overlay-opacity-col">
                <ReactSlider
                  className="opacity-slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={overlayPaint["raster-opacity"]}
                  step={SLIDER_STEP}
                  max={SLIDER_MAX}
                  renderThumb={(props, {value}) => (
                    <div {...props}><span className="prop-value">{value}</span></div>
                  )}
                  onChange={onThumbDrag}
                />
              </Col>
            </Row>
            {renderOverlay && (
              <>
                <Source
                  id="overlaySource"
                  tileJsonSource={{
                    type: 'image',
                    url: imageUrl,
                    coordinates: coordinatesRef.current
                  }}
                />
                <Layer id="overlay" type="raster" sourceId="overlaySource" paint={overlayPaint}/>
              </>
            )}
          </>
        )}
        <Row className={`markers-info justify-content-end${!coordinatesRef.current ? " no-coordinates" : ""}`} noGutters>
          <Col sm={1} className="map-control-col">
            <button onClick={onMapStyleChange} className={`icon-btn${toggleSatelliteStyle ? " satellite" : ""}`}>
              <img alt="" src={toggleSatelliteStyle ? satellite : regularView}/>
            </button>
          </Col>
        </Row>
      </>
    </Mapbox>
  );
}

export default MapOverlay;
