import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { FieldArray } from "formik";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { SelectControl } from "../../../shared/form/controls/SelectControl/SelectControl";
import { ManagedForm } from "../../../shared/form/Form/ManagedForm";
import { Placeholder } from "../../../shared/Placeholder/Placeholder";
import { useApi } from "../../../shared/useApi";
import { TrailNavPage } from "../TrailNavPage/TrailNavPage";
import "./CheckPoints.scss";

export function CheckPoints() {
  const { t } = useTranslation();

  return (
    <TrailNavPage
      title={t("Check points")}
      className="check-points"
      placeholder={() => <Placeholder title={t("No checkpoints yet.")} description={t("Add some trails on the Trail info tab.")}></Placeholder>}
    >
      {({ trailData }) => <ChecPointsForm trailId={trailData ? trailData.id : null}></ChecPointsForm>}
    </TrailNavPage>
  );
}

type CheckPointData = any;
type FormData = { checkPoints: Partial<CheckPointData>[] };
const initialFormData = createFormData([createEmptyCheckPoint()]);
type ChecPointsFormProps = { trailId: string | null };
function ChecPointsForm({ trailId }: ChecPointsFormProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>(initialFormData);

  const { get, post, response } = useApi(`/trails/${trailId}/check-points`);

  useEffect(() => {
    if (trailId) {
      loadCheckPoints();
    } else {
      setFormData(initialFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailId]);

  return (
    <ManagedForm
      values={formData}
      validationSchema={createValidationSchema(t)}
      onSubmit={(d) => saveCheckpoints(d)}
      toolbar={{ lastUpdateDate: findLatastChangeDate(formData.checkPoints) }}
    >
      {({ values }) => (
        <FieldArray name="checkPoints">
          {({ remove, push }) => (
            <div>
              {values.checkPoints.map((c, i) => (
                <Form.Row key={c.id || c.internalId} className="align-items-center array-item">
                  <Col sm="1">
                    <InputControl type="number" name={`checkPoints.${i}.number`} label={t("Order")} placeholder={t("Order")}></InputControl>
                  </Col>
                  <Col sm="3">
                    <InputControl type="text" name={`checkPoints.${i}.name`} label={t("Check point name")} placeholder={t("Enter name")}></InputControl>
                  </Col>
                  <Col sm="2">
                    <InputControl type="number" name={`checkPoints.${i}.lat`} label={t("Latitude")} placeholder={t("Latitude")}></InputControl>
                  </Col>
                  <Col sm="2">
                    <InputControl type="number" name={`checkPoints.${i}.lon`} label={t("Longitude")} placeholder={t("Longitude")}></InputControl>
                  </Col>
                  <Col sm="1">
                    <InputControl type="number" name={`checkPoints.${i}.alt`} label={t("Altitude")} placeholder={t("Altitude")}></InputControl>
                  </Col>
                  <Col>
                    <SelectControl name={`checkPoints.${i}.type`} options={[]} placeholder={t("Select type")}></SelectControl>
                  </Col>
                  <div>
                    <Button variant="link" size="sm" className="text-danger delete-item" onClick={() => remove(i)}>
                      <FontAwesomeIcon icon={faTrashAlt} className="button-icon"></FontAwesomeIcon>
                      {t("Delete")}
                    </Button>
                  </div>
                </Form.Row>
              ))}
              <Button
                variant="purple"
                size="sm"
                className="btn-block add-button"
                onClick={() => {
                  push(createEmptyCheckPoint());
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="button-icon"></FontAwesomeIcon>
                {t("Add check point")}
              </Button>
            </div>
          )}
        </FieldArray>
      )}
    </ManagedForm>
  );

  async function loadCheckPoints() {
    const checkPoints = await get("");

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to update"));
      return null;
    }

    setFormData(checkPoints.length > 0 ? createFormData(checkPoints) : initialFormData);
  }

  async function saveCheckpoints(formData: FormData) {
    const savedCheckpoints = await post(
      "",
      formData.checkPoints.map(({ internalId, ...checkpointData }) => checkpointData)
    );

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to save"));
      return null;
    }

    setFormData(createFormData(savedCheckpoints));
  }
}

function createValidationSchema(t: TFunction) {
  return Yup.object({
    checkPoints: Yup.array().of(
      Yup.object({
        number: Yup.number().required(t("Order is required")),
        lat: Yup.number().required(t("Latitude is required")),
        lon: Yup.number().required(t("Latitude is required")),
        type: Yup.number().required(t("Type is required")),
      })
    ),
  });
}

function createFormData(checkPoints: CheckPointData[]) {
  return {
    checkPoints: [...checkPoints].sort((c1, c2) => c1.number - c2.number),
  };
}

function createEmptyCheckPoint() {
  return {
    internalId: Math.random(),
    number: "",
    name: "",
    lat: "",
    lon: "",
    alt: "",
    type: 0,
  };
}

function findLatastChangeDate(checkPoints: CheckPointData[]) {
  const latestCheckPoint = checkPoints.reduce((latest, current) => {
    return latest == null || new Date(latest).getTime() < new Date(current.changeDate).getTime() ? current : latest;
  }, null);

  return latestCheckPoint ? latestCheckPoint.changeDate : "";
}
