import useFetch, { CachePolicies, IncomingOptions } from "use-http";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { UNAUTHORIZED_REDIRECT_PATH } from "../helpers/consts";

type ApiOptions = { suspense?: boolean };

export function useApi(endpoint: string, options?: ApiOptions) {
  const history = useHistory();
  return useFetch(process.env.REACT_APP_API_BASE + endpoint, buildOptions(history, options));
}

export function useApiGet(endpoint: string, initialData: any, deps?: any[]) {
  const history = useHistory();

  const managedRequest = useFetch(process.env.REACT_APP_API_BASE + endpoint, buildOptions(history), deps || []);

  if (managedRequest.error) {
    managedRequest.data = initialData;
  }

  return managedRequest;
}

export function download(endpoint: string) {
  window.open(process.env.REACT_APP_API_BASE + endpoint, "_blank");
}

export const apiCall = async (endpoint: string, options?: ApiOptions) => {
  const response = await fetch(process.env.REACT_APP_API_BASE + endpoint, { ...options, cache: CachePolicies.NO_CACHE, credentials: "include" });
  if (!response.ok && response.status === 401) {
    return { ok: response.ok, status: response.status, data: null };
  }
  return { ok: response.ok, status: response.status, data: await response.json() };
};

function buildOptions(history: History, options?: ApiOptions): IncomingOptions {
  return {
    ...options,
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: "include",
    interceptors: {
      response: async (res) => {
        const { response } = res;

        if (!response.ok && response.status === 401) {
          history.push(UNAUTHORIZED_REDIRECT_PATH);

          throw new Error("Unauthorized");
        }

        return response;
      },
    },
  };
}
