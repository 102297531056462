import { GlobalState } from "./interfaces";

const globalState: GlobalState = {
  user: {},
  loaded: false,
  loggedIn: false,
  isCartPage: false,
  eventId: null,
  trailId: null,
  reload: () => Promise.resolve(),
};

export default globalState;
