import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs } from "../../../shared/Tabs/Tabs";
import { ReactComponent as Cart } from "../../../assets/svg/iconCart.svg";
import { selectCartItemsCount } from "../../Cart/store/selectors";
import { emptyChar } from "../../../shared/utils";
import { eventTabs } from "../event-tabs";
import "./EventHeader.scss";

type EventHeaderProps = { title: string; url: string };
type CountClassNames = "one-digit" | "two-digit" | "three-digit";

export function EventHeader({ title, url }: EventHeaderProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const itemsCount = useSelector(selectCartItemsCount);
  const [countClassName, setCountClassName] = useState<CountClassNames>("one-digit");

  useEffect(() => {
    switch (true) {
      case itemsCount > 9 && itemsCount < 100:
        setCountClassName("two-digit");
        break;
      case itemsCount > 99:
        setCountClassName("three-digit");
        break;
      case itemsCount > 0 && itemsCount < 10:
      default:
        setCountClassName("one-digit");
        break;
    }
  }, [itemsCount]);

  const onCartClick = () => {
    history.push(`/checkout`);
  };

  return (
    <div className="d-flex justify-content-center event-header">
      <Col md="9">
        <div className="header d-flex align-items-center">
          <Link to="/" className="align-self-center">
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" />
          </Link>
          <h2 className="title mr-auto">{title || emptyChar}</h2>
          <Button size="sm" variant="secondary" disabled className="preview-btn mr-4">
            <FontAwesomeIcon icon={faEye} className="button-icon" />
            {t("Preview")}
          </Button>
          <Button size="sm" variant="transparent" className="cart-btn shadow-none" onClick={onCartClick}>
            <Cart />
            {!!itemsCount && <div className={`items-count ${countClassName}`}>{itemsCount}</div>}
          </Button>
        </div>
        <Tabs
          tabs={
            eventTabs
              .map((tab, index) => {
                if (tab.hideTab) return null;
                return {
                  url: `${url}/${tab.path}`,
                  name: t(tab.name),
                  disabled: index > 0 && !eventId,
                };
              })
              .filter((val) => !!val) as Tab[]
          }
        />
      </Col>
    </div>
  );
}
