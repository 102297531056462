import { ReactNode, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { ReactComponent as Times } from "../.././assets/svg/iconTimes.svg";
import "./NotificationModal.scss";

export function useNotificationModal(title: string, confirmText: string, children: ReactNode) {
  const callbackRef = useRef<() => void>(() => {});

  const [showModal, hideModal] = useModal(() => (
    <NotificationModal title={title} onHide={hideModal} onConfirm={callbackRef.current} confirmText={confirmText}>
      {children}
    </NotificationModal>
  ));

  return (callback: () => void) => {
    callbackRef.current = callback;
    showModal();
  };
}

type NotificationModalProps = {
  title: string;
  confirmText: string;
  cancelText?: string;
  onHide: () => void;
  onConfirm: () => void;
  children?: ReactNode;
};
function NotificationModal({ title, confirmText, cancelText = "Cancel", onHide, onConfirm, children }: NotificationModalProps) {
  const { t } = useTranslation();

  const onConfirmClick = () => {
    onHide();
    onConfirm();
  };

  return (
    <Modal className="notification-modal" onHide={onHide} show centered>
      <Modal.Header className="d-flex align-items-center justify-content-end border-0 pb-0">
        <Button aria-label="Close the modal" variant="none" onClick={onHide}>
          <Times />
        </Button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center border-0 pt-0 pb-0">
        <Modal.Title className="h6 font-weight-500 mb-4">{title}</Modal.Title>
        <div>{children}</div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center border-0 pt-0 pb-5">
        <Button size="sm" variant="outline-default" className="btn-small btn-rounded footer-btn mr-3" onClick={onHide}>
          {t(cancelText)}
        </Button>
        <Button type="submit" size="sm" variant="default" className="btn-small btn-rounded footer-btn" onClick={onConfirmClick}>
          {t(confirmText)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
