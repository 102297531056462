import {Feature, Layer} from 'react-mapbox-gl';

type MapMarkerProps = {
  lat: number;
  lng: number;
  color: string;
  index: number;
  id?: string;
  isDraggable?: boolean;
  onDragEnd?: (lat: number, lng: number, index: number) => void
};
export const MapMarker = ({lat, lng, color, id, index, isDraggable = false, onDragEnd}: MapMarkerProps) => (
  <Layer
    id={id}
    type="circle"
    paint={{
      "circle-color": "transparent",
      "circle-stroke-width": 8,
      "circle-stroke-color": color,
      "circle-radius": 20,
    }}
  >
    <Feature coordinates={[lng, lat]} draggable={isDraggable} onDragEnd={({lngLat}: any) => onDragEnd && onDragEnd(lngLat.lat, lngLat.lng, index)}/>
  </Layer>
);
