import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Form as BootstrapForm } from "react-bootstrap";
import { contentWidthCls } from "../../../pages/consts";
import { FormToolbar } from "../FormToolbar/FormToolbar";

type FormProps<T> = {
  values: T;
  validationSchema: any;
  onSubmit(formData: T): Promise<any>;
  toolbar?: {
    lastUpdateDate: string;
    toolbarClassName?: string;
  };
  children:
    | React.ReactElement
    | ((formik: {
        values: T;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
        handleChange: (e: React.ChangeEvent<any>) => void;
        saving: boolean;
        saved: boolean;
      }) => React.ReactElement);
};
export function ManagedForm<T>({
  values,
  validationSchema,
  onSubmit,
  toolbar,
  children,
}: FormProps<T>) {
  const [saved, setSaved] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema,
    onSubmit: async (formData: T) => {
      setSaved(false);

      await onSubmit(formData);

      setSaved(true);
    },
  });

  const saving = formik.isSubmitting;

  return (
    <FormikProvider value={formik}>
      <BootstrapForm
        onSubmit={formik.handleSubmit}
        onChange={() => setSaved(false)}
      >
        {typeof children === "function"
          ? children({ ...formik, saving, saved })
          : children}
        {toolbar && (
          <FormToolbar
            className={toolbar.toolbarClassName || contentWidthCls}
            lastUpdateDate={toolbar.lastUpdateDate}
            saving={saving}
            saved={saved}
          ></FormToolbar>
        )}
      </BootstrapForm>
    </FormikProvider>
  );
}
