import { useField } from "formik";
import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import "./DateTimeControl.scss";

type DatetimeControlProps = {
  name: string;
  label: string;
  placeholder: string;
  showTimeInput?: boolean;
  dateFormat?: string;
};

export function DatetimeControl({ name, label, placeholder, showTimeInput = true, dateFormat = "dateTimeFormatDatepicker" }: DatetimeControlProps) {
  const { t } = useTranslation();
  const [field, meta, { setValue }] = useField(name);

  return (
    <Form.Group controlId={name} className="date-time-control">
      <Form.Label>{label}</Form.Label>
      <DatePicker
        selected={field.value || null}
        {...field}
        onChange={(date) => {
          setValue(date);
        }}
        showTimeInput={showTimeInput}
        timeInputLabel={t("Time") + ":"}
        dateFormat={t(dateFormat)}
        timeFormat="HH:mm:ss"
        customInput={<Input error={meta.error} isInvalid={meta.touched && !!meta.error} />}
        placeholderText={placeholder}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}

type InputProps = {
  value?: string;
  onChange?: any;
  onClick?: any;
  error?: string;
  isInvalid?: boolean;
  placeholder?: string;
};
const Input = forwardRef(({ value, onChange, onClick, error, isInvalid, placeholder }: InputProps, ref) => {
  return (
    <>
      <Form.Control
        onClick={onClick}
        value={value}
        isInvalid={isInvalid}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        ref={ref as any}
      ></Form.Control>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  );
});
