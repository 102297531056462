import { useField } from "formik";
import { Form } from "react-bootstrap";

type SelectControlProps = {
  name: string;
  label?: string;
  placeholder?: string;
  options: { value: string | number; name: string, disabled?: boolean }[];
};

export function SelectControl({
  name,
  label,
  placeholder,
  options,
}: SelectControlProps) {
  const [field, meta] = useField(name);

  return (
    <Form.Group controlId={name}>
      <Form.Label>{label || ""}</Form.Label>
      <Form.Control
        as="select"
        {...field}
        isInvalid={meta.touched && !!meta.error}
        className={field.value ? "" : "placeholder"}
      >
        {placeholder && (
          <option className="placeholder" value="">
            {placeholder}
          </option>
        )}
        {options.map((v) => (
          <option value={v.value} key={v.value} disabled={v.disabled}>
            {v.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}
