import { useField } from "formik";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./ImageControl.scss";

type ImageControlProps = {
  name: string;
  label: string;
  uploadText: string;
  changeText: string;
  previewClassName: string;
};
export function ImageControl({
  name,
  label,
  uploadText,
  changeText,
  previewClassName,
}: ImageControlProps) {
  const [field, , { setValue, setTouched }] = useField(name);
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    loadPreview(field.value);
  }, [field.value]);

  return (
    <Form.Group controlId={name} className="image-control">
      <Form.Label>{label}</Form.Label>
      <div
        className={`image-preview ${previewClassName}`}
        style={{
          backgroundImage: `url(${previewUrl})`,
        }}
      ></div>
      <label
        className="upload-button btn btn-sm btn-outline-light btn-block"
        htmlFor={name}
      >
        {field.value ? changeText : uploadText}
      </label>
      <input
        id={name}
        type="file"
        name={name}
        style={{ display: "none" }}
        accept="image/*"
        onChange={(e) => {
          setTouched(true);
          setValue(e.target?.files?.[0]);
        }}
      ></input>
    </Form.Group>
  );

  async function loadPreview(value: string | File) {
    if (!value) {
      return;
    }

    const previewUrl =
      typeof value == "string" ? value : await encodeImageFileAsURL(value);

    setPreviewUrl(previewUrl);
  }
}

function encodeImageFileAsURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => reject();

    reader.readAsDataURL(file);
  });
}
