import React, { useEffect, useState } from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import './Map.scss';

import { StartMarker } from './Marker/StartMarker'

const Mapbox = ReactMapboxGl({
    accessToken: `${process.env.REACT_APP_MAPBOX_PUBLIC_KEY}`,
});

type MapProps = {
    latitude: number
    longitude: number
    onClick: (latitude: number, longitude: number) => void
};

export function Map({
    latitude,
    longitude,
    onClick
}: MapProps) {
    const [
        mapLocation,
        setMapLocation
    ] = useState([{
        latitude: 0,
        longitude: 0
    }]);

    const [
        mapZoom,
        setMapZoom
    ] = useState(1);

    useEffect(() => {
        if (
            Number(latitude) && Number(longitude) &&
            !isNaN(latitude) && !isNaN(longitude) &&
            latitude !== undefined && longitude !== undefined
        ) {
            setMapLocation([{latitude, longitude}])
            setMapZoom(15)
        } else {
            setMapZoom(1)
        }

    }, [latitude, longitude]);

    const _renderLocationOnMap = (mapLocation: any) => {
        if (mapLocation.length > 0) {
            return mapLocation.map((props: any) => {
                return (
                    <StartMarker
                        key={`marker`}
                        latitude={props.latitude}
                        longitude={props.longitude}
                    />
                );
            });
        }
    };
    
    return (
        <Mapbox
            // eslint-disable-next-line react/style-prop-object
            style={`${process.env.REACT_APP_MAPBOX_STYLE}`}
            center={[mapLocation[0].longitude, mapLocation[0].latitude]}
            zoom={[mapZoom]}
            containerStyle={{
                height: '300px',
                width: '100%'
            }}
            onClick={(_, evt) => {
                const event = (evt as unknown) as mapboxgl.MapMouseEvent
                onClick(event.lngLat.lat, event.lngLat.lng);
                setMapLocation([{
                    latitude: event.lngLat.lat,
                    longitude: event.lngLat.lng
                }])
            }}
        >
            {_renderLocationOnMap(mapLocation)}
        </Mapbox>
    );
}
