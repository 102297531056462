import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../shared/useApi";
import "./EventStatistics.scss";

type RaceStatisticsData = any;
type EventStatisticsProps = {
  raceId: number;
};

const initialStatisticsData = {
  participants: {
    inProgress: 0,
    finished: 0,
    abandoned: 0,
  },
  gender: {
    male: 0,
    female: 0,
  },
  ageGroups: [],
  devices: [],
  locations: [],
};

export function EventStatistics({ raceId }: EventStatisticsProps) {
  const { get, response: loadResponse } = useApi("/races");
  const { t } = useTranslation();

  const [raceStatisticsData, setRaceStatisticsData] = useState<RaceStatisticsData>(initialStatisticsData);

  useEffect(() => {
    if (raceId) {
      loadRace();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raceId]);

  async function loadRace() {
    const raceStatistics = await get(`/${raceId}/statistics`);

    if (!loadResponse.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }

    setRaceStatisticsData(raceStatistics);
  }

  var sortedDevices = [];
  for (var device in raceStatisticsData.devices) {
    sortedDevices.push([device, raceStatisticsData.devices[device as any]]);
  }

  sortedDevices.sort(function (a, b) {
    return b[1] - a[1];
  });

  const displayDeviceName = (device: string) => {
    switch (device) {
      case "android":
        return "Android";

      case "manual":
        return "Manual";

      case "ios":
        return "Apple iOS";

      case "nonSpecified":
        return "Unrecognized";

      default:
        return "Unrecognized";
    }
  };
  const sortedDevicesFragment = sortedDevices.map((data, idx) => {
    return (
      <div key={idx}>
        <span>
          {idx + 1}. {displayDeviceName(data[0])}
        </span>
      </div>
    );
  });

  let participantGraphDataTransformed: any[][] = [];
  if (raceStatisticsData) {
    participantGraphDataTransformed.push(["City", "Men", "Women", "Both"]);

    for (const graphLine in raceStatisticsData.participantGraphData) {
      participantGraphDataTransformed.push([
        graphLine,
        parseInt(raceStatisticsData.participantGraphData[graphLine].M),
        parseInt(raceStatisticsData.participantGraphData[graphLine].F),
        parseInt(raceStatisticsData.participantGraphData[graphLine].W),
      ]);
    }
  }

  return (
    <div className="event-statistics">
      <Row className="event-statistics__row">
        <Col sm="6">
          <div className="event-statistics__column">
            <h3 className="event-statistics__title">Participant status</h3>

            <div className="event-statistics__line">
              <span className="event-statistics__circle event-statistics__circle--in-progress"></span>
              <span className="event-statistics__name">{raceStatisticsData.participants.inProgress} in progress</span>
            </div>

            <div className="event-statistics__line">
              <span className="event-statistics__circle event-statistics__circle--finished"></span>
              <span className="">{raceStatisticsData.participants.finished} finished</span>
            </div>

            <div className="event-statistics__line">
              <span className="event-statistics__circle event-statistics__circle--abandoned"></span>
              <span className="">{raceStatisticsData.participants.abandoned} abandoned</span>
            </div>

            <div className="event-statistics__line">
              <span className="event-statistics__circle event-statistics__circle--disqualified"></span>
              <span className="">{raceStatisticsData.participants.disqualified} disqualified</span>
            </div>
          </div>
        </Col>
        <Col sm="6">
          <div className="event-statistics__column event-statistics__column--gender-distribution">
            <h3 className="event-statistics__title">Gender distribution</h3>
            <Chart
              chartType="PieChart"
              loader={<div style={{ width: "275px", height: "200px" }}>Loading Chart</div>}
              data={[
                ["Gender", "Gender distribution"],
                ["Men", raceStatisticsData.gender.male],
                ["Women", raceStatisticsData.gender.female],
              ]}
              options={{
                sliceVisibilityThreshold: 0.2, // 20%
                backgroundColor: "transparent",
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </div>
        </Col>
      </Row>
      {participantGraphDataTransformed.length > 1 && (
        <Row className="event-statistics__row">
          <Col sm="12">
            <div className="event-statistics__column">
              <h3 className="event-statistics__title">Participants age</h3>
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={participantGraphDataTransformed}
                options={{
                  legend: "none",
                  chartArea: { width: "75%" },
                  backgroundColor: "transparent",
                  hAxis: {
                    minValue: 0,
                  },
                  vAxis: {
                    title: "Participants",
                  },
                }}
                // For tests
                rootProps={{ "data-testid": "1" }}
              />
            </div>
          </Col>
        </Row>
      )}
      <Row className="event-statistics__row">
        <Col sm="4">
          <div className="event-statistics__column">
            <h3 className="event-statistics__title">Top devices</h3>

            {sortedDevicesFragment}
          </div>
        </Col>
        <Col sm="8" style={{ visibility: "hidden" }}>
          <div className="event-statistics__column">
            <h3 className="event-statistics__title">Top locations</h3>
          </div>
        </Col>
      </Row>
    </div>
  );
}
