import { Participant, ResultsState } from "./interfaces";

const resultsInitialState: ResultsState = {
  reload: false,
  participantsToPurchase: [],
  notificationHidden: localStorage.getItem("notificationHidden") === "true" || false,
  addGpxToParticipant: (updatedParticipant: Participant) => {},
  removeGpxFromParticipant: (eventId: number, activityId: number, loadedParticipants: Participant[]) => {},
  setParticipantsToPurchase: (participants: Participant[]) => {},
  hideNotificationMessage: (notificationHidden: boolean) => {},
  reloadResults: (reload: boolean) => {},
};

export { resultsInitialState };
