import { GenderI, GenderOption, ListOptions } from "../types/genders";

const genders: GenderI[] = [
  {
    name: "male",
    value: "M",
    list: "basic",
  },
  {
    name: "female",
    value: "F",
    list: "basic",
  },
  {
    name: "agender",
    value: "Ag",
    list: "basic",
  },
];

function sortGenders(genders: GenderOption[]): GenderOption[] {
  return genders.sort(({ name: a }, { name: b }) => {
    return a < b ? -1 : a > b ? 1 : 0;
  });
}

function GenderOptions(genders: GenderI[], listName: ListOptions) {
  const list = genders.filter((gender) => gender.list === listName).map(({ name, value }): GenderOption => ({ name, value }));

  sortGenders(list);

  return list;
}

const basic = GenderOptions(genders, "basic");
const standard = basic.concat(GenderOptions(genders, "standard"));
const extended = standard.concat(GenderOptions(genders, "extended"));

const genderOptions = {
  basic,
  standard,
  extended,
};

export { genderOptions };
