import { Dispatch } from "redux";
import { Participant } from "./interfaces";
import { ADD_GPX_TO_PARTICIPANT, REMOVE_GPX_FROM_PARTICIPANT, SET_PARTICIPANTS_TO_PURCHASE, HIDE_PRICE_NOTIFICATION, SET_RELOAD_RESULTS } from "./types";
import { AppState } from "../../../../store/interfaces";

const loadParticipantsToPurchaseFromStorage = (eventId: number | null): Participant[] => {
  try {
    if (!eventId) {
      return [] as Participant[];
    }
    const participants = localStorage.getItem("participantsToPurchase");
    if (participants?.length) {
      const participantsToPurchase = JSON.parse(participants) as { [eventId: number]: Participant[] };
      return participantsToPurchase[eventId] ? participantsToPurchase[eventId] : ([] as Participant[]);
    }
    return [] as Participant[];
  } catch (err) {
    console.error("Can not load participants from localStorage");
    return [] as Participant[];
  }
};

const saveParticipantsToStorage = (eventId: number, participants: Participant[]) => {
  const savedParticipants = localStorage.getItem("participantsToPurchase");
  if (savedParticipants?.length) {
    const parsedParticipants = JSON.parse(savedParticipants) as { [trailId: number]: Participant[] };
    parsedParticipants[eventId] = participants;
    localStorage.setItem("participantsToPurchase", JSON.stringify(parsedParticipants));
  } else {
    localStorage.setItem("participantsToPurchase", JSON.stringify({ [eventId]: participants }));
  }
};

const addGpxToParticipant = (updatedParticipant: Participant) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { participantsToPurchase } = getState().results;
    const participantsToPurchaseCopy = [...participantsToPurchase];
    const indexOfEdited = participantsToPurchaseCopy.findIndex((val) => val.activityId === updatedParticipant.activityId);

    if (indexOfEdited === -1) {
      participantsToPurchaseCopy.push(updatedParticipant);
    } else {
      participantsToPurchaseCopy[indexOfEdited] = updatedParticipant;
    }

    saveParticipantsToStorage(updatedParticipant.eventId, participantsToPurchaseCopy);
    dispatch({
      type: ADD_GPX_TO_PARTICIPANT,
      payload: {
        participantsToPurchase: participantsToPurchaseCopy,
      },
    });
  };
};

const removeGpxFromParticipant = (eventId: number, activityId: number) => {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const { participantsToPurchase } = getState().results;

    const participantsToPurchaseCopy = [...participantsToPurchase]
      .filter((participant) => participant.activityId !== activityId)
      .map((val, i) => ({ ...val, rank: i + 1 })) as Participant[];

    saveParticipantsToStorage(eventId, participantsToPurchaseCopy);
    dispatch({
      type: REMOVE_GPX_FROM_PARTICIPANT,
      payload: {
        participantsToPurchase: participantsToPurchaseCopy,
      },
    });
  };
};

const setParticipantsToPurchase = (participants: Participant[]) => {
  return {
    type: SET_PARTICIPANTS_TO_PURCHASE,
    payload: {
      participantsToPurchase: participants,
    },
  };
};

const hideNotificationMessage = (notificationHidden: boolean) => {
  localStorage.setItem("notificationHidden", `${notificationHidden}`);

  return {
    type: HIDE_PRICE_NOTIFICATION,
    payload: {
      notificationHidden,
    },
  };
};

const setReloadResults = (reload: boolean) => {
  return {
    type: SET_RELOAD_RESULTS,
    payload: {
      reload,
    },
  };
};

const loadNotificationMessageStatus = () => {
  const notificationHidden = localStorage.getItem("notificationHidden") === "true";

  return {
    type: HIDE_PRICE_NOTIFICATION,
    payload: {
      notificationHidden,
    },
  };
};

export {
  addGpxToParticipant,
  removeGpxFromParticipant,
  loadParticipantsToPurchaseFromStorage,
  saveParticipantsToStorage,
  setParticipantsToPurchase,
  hideNotificationMessage,
  loadNotificationMessageStatus,
  setReloadResults,
};
