import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation, useHistory, Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalProvider } from "react-modal-hook";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { io } from "socket.io-client";
import { SignIn } from "./pages/auth/SignIn/SignIn";
import { SignUp } from "./pages/auth/SignUp/SignUp";
import { Profile } from "./pages/Profile/Profile";
import { EventDetails } from "./pages/event/EventDetails/EventDetails";
import { Help } from "./pages/Help/Help";
import { MyEvents } from "./pages/MyEvents/MyEvents";
import { AppHeader } from "./shared/AppHeader/AppHeader";
import { loadCartItems, setCartItems, setPayPalPaymentInProcess } from "./pages/Cart/store/actions";
import { selectPayPalPaymentStatus } from "./pages/Cart/store/selectors";
import { Cart } from "./pages/Cart/Cart";
import { selectLoaded, selectLoggedIn } from "./store/global/selectors";
import { reload } from "./store/global/actions";
import { UNAUTHORIZED_REDIRECT_PATH } from "./helpers/consts";
import history from "./helpers/history";
import "./App.scss";

const socket = io(process.env.REACT_APP_WS_SERVER as string);

export default function App() {
  return (
    <ModalProvider>
      <Router history={history}>
        <AppRouter />
      </Router>
    </ModalProvider>
  );
}

function AppRouter() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loaded = useSelector(selectLoaded);
  const loggedIn = useSelector(selectLoggedIn);
  const payPalPaymentInProcess = useSelector(selectPayPalPaymentStatus);

  useEffect(() => {
    dispatch(setCartItems(loadCartItems()));
  }, [dispatch]);

  useEffect(() => {
    if (payPalPaymentInProcess) {
      socket.connect();
      socket.on("purchase-success", () => {
        history.goBack();
        dispatch(setCartItems(loadCartItems()));
        toastr.success(t("Purchase completed"), t("You have successfully purchased a GPX trail for a user. You can update it without extra fee."));
      });
      socket.on("purchase-denied", () => {
        toastr.error(t("Purchase denied"), t("PayPal has denied your purchase."));
      });
      socket.on("purchase-error", () => {
        toastr.error(t("Purchase error"), t("An unexpected error happened while purchasing. Try again or contact us."));
      });

      return () => {
        dispatch(setPayPalPaymentInProcess(true));
        socket.off("connect");
        socket.off("disconnect");
        socket.off("purchase-success");
        socket.off("purchase-denied");
        socket.off("purchase-error");
      };
    }
  }, [payPalPaymentInProcess, history, t, dispatch]);

  useEffect(() => {
    if (loaded && !loggedIn) {
      history.push(UNAUTHORIZED_REDIRECT_PATH);
    }
  }, [history, loggedIn, loaded]);

  return (
    <Switch>
      <Route path="/sign-in" component={SignIn} />
      <Route path="/sign-up" component={SignUp} />
      <Route
        path="/"
        render={() => {
          if (!loaded) {
            dispatch(reload());
            return <div />;
          }
          return <Content />;
        }}
      />
    </Switch>
  );
}

function Content() {
  const history = useHistory();

  return (
    <div className="app-content">
      <AppHeader />
      <Switch location={useLocation()}>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/checkout">
          <Cart />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/event/create">
          <EventDetails
            path="/event/create"
            url="/event/create"
            onCreate={(raceId) => {
              history.push(`/event/${raceId}`);
            }}
          />
        </Route>
        <Route
          path="/event/:eventId"
          render={({ match }) => <EventDetails path={`/event/:eventId`} url={`/event/${match.params.eventId}`} onCreate={() => null} />}
        />
        <Route path="/" exact>
          <MyEvents
            onSelect={(selectedId) => {
              history.push(`/event/${selectedId}`);
            }}
          />
        </Route>
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
}
