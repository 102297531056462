import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { eventTabs } from "../event-tabs";

type EventRouterProps = {
  route: string;
  url: string;
  raceData: any;
  onCreate: (raceId: number) => void;
};

export function EventRouter({ route, url, raceData, onCreate }: EventRouterProps) {
  return (
    <Switch location={useLocation()}>
      {eventTabs.map(({ path, Component }) => (
        <Route path={`${route}/${path}`} key={path}>
          {Component && <Component onCreate={onCreate} raceData={raceData} />}
          {!Component && <div />}
        </Route>
      ))}
      <Redirect from="*" to={`${url}/${eventTabs[0].path}`} />
    </Switch>
  );
}
