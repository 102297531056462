import "./Logo.scss";
import logo_purple from "./logo_purple.png";
import racelog_purple from "./racelog_purple.png";

export function Logo() {
  return (
    <div>
      <img className="logo" src={logo_purple} alt="Race Log"/>
      <img className="racelog" src={racelog_purple} alt=""/>
    </div>
  );
}
