import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      dateFormat: "DD.MM.YYYY",
      dateFormatDatepicker: "dd.MM.yyyy",
      dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
      dateTimeFormatDatepicker: "dd.MM.yyyy HH:mm:ss",
      Welcome: "Welcome",
      "Your Events": "Your Events",
      "Search...": "Search...",
      ID: "ID",
      Race: "Race",
      Date: "Date",
      Participants: "Participants",
      "Error loading data.": "Error loading data.",
      "My Events": "My Events",
      Help: "Help",
      "New event": "New event",
      Preview: "Preview",
      "Event overview": "Event overview",
      "Trail info": "Trail info",
      "Age groups": "Age groups",
      "Check points": "Check points",
      Vouchers: "Vouchers",
      "Event details": "Event details",
      "Race name": "Race name",
      "Enter name": "Enter name",
      "Location name": "Location name",
      "Enter location name": "Enter location name",
      Country: "Country",
      "Enter country": "Enter country",
      Starting: "Starting",
      "Enter starting": "Enter starting",
      Ending: "Ending",
      "Enter ending": "Enter ending",
      Visibility: "Visibility",
      "Select visibility": "Select visibility",
      HIDDEN: "Hidden",
      VISIBLE: "Visible",
      "Event type": "Event type",
      "Select event type": "Select event type",
      Latitude: "Latitude",
      "Select race start coordinates": "Select race start coordinates",
      "Enter latitude": "Enter latitude",
      Longitude: "Longitude",
      "Enter longitude": "Enter longitude",
      Image: "Image",
      "Upload image": "Upload image",
      "Race name is required": "Race name is required",
      "Location name is required": "Location name is required",
      "Starting is required": "Starting is required",
      "Ending is required": "Ending is required",
      "Visivbility is required": "Visivbility is required",
      "Event type is required": "Event type is required",
      "Latitude is required": "Latitude is required",
      "Longitude is required": "Longitude is required",
      Save: "Save",
      Saved: "Saved",
      "Saving...": "Saving...",
      "Last update:": "Last update:",
      Import: "Import",
      Export: "Export",
      "Trail name": "Trail name",
      "Trail name is required": "Trail name is required",
      "Trail length": "Trail length",
      Length: "Length",
      "Trail length is required": "Trail length is required",
      "Trail length unit is required": "Trail length unit is required",
      "Start lat": "Start lat",
      "End lat": "End lat",
      "Start long": "Start long",
      "End long": "End long",
      "Pass ID": "Pass ID",
      "Enter pass ID": "Enter pass ID",
      "Target distance": "Target distance",
      Distance: "Distance",
      "Target elevation": "Target elevation",
      Elevation: "Elevation",
      "Check point name": "Check point name",
      Altitude: "Altitude",
      "Select type": "Select type",
      Delete: "Delete",
      "Add check point": "Add check point",
      Order: "Order",
      "Order is required": "Order is required",
      "Name is required": "Name is required",
      "Type is required": "Type is required",
      "Sign in": "Sign in",
      or: "or",
      "create an account": "create an account",
      Email: "Email",
      "Your email": "Your email",
      Password: "Password",
      "Your password": "Your password",
      "Email is required": "Email is required",
      "Password is required": "Password is required",
      "Forgot your password?": "Forgot your password?",
      "Username or password invalid": "Username or password invalid",
      "Sign up": "Sign up",
      login: "login",
      "Can't sign up. Do you mind trying with another email address": "Can't sign up. Do you mind trying with another email address",
      "First name": "First name",
      "Enter your first name": "Enter your first name",
      "Last name": "Last name",
      "Enter your last name": "Enter your last name",
      "Enter your password": "Enter your password",
      "I agree with RaceLog": "I agree with RaceLog",
      "terms & conditions": "terms & conditions",
      "First is required": "First is required",
      "Last is required": "Last is required",
      "You must agree with RaceLog terms & conditions": "You must agree with RaceLog terms & conditions",
      "Email should be a valid email address": "Email should be a valid email address",
      "Password should have at least {{num}} characters": "Password should have at least {{num}} characters",
      Register: "Register",
      "Log in": "Log in",
      "My profile": "My profile",
      "My details": "My details",
      Change: "Change",
      "Add trail": "Add trail",
      "No checkpoints yet.": "No checkpoints yet.",
      "Add some trails on the Trail info tab.": "Add some trails on the Trail info tab.",
      "Organization name": "Organization name",
      "Your organization's name": "Your organization's name",
      "Organiation name is required": "Organiation name is required",
      "Error loading events.": "Error loading events.",
      "Please try again later.": "Please try again later.",
      "No events yet.": "No events yet.",
      "Create a new one using the Create button above.": "Create a new one using the Create button above.",
      Results: "Results",
      "No results yet.": "No results yet.",
      "Here you will find the results list when available.": "Here you will find the results list when available.",
      "Error loading results.": "Error loading results.",
      Rank: "Rank",
      Name: "Name",
      Time: "Time",
      Status: "Status",
      Participating: "Participating",
      "Did not start": "Did not start",
      "Did not finish": "Did not finish",
      Ready: "Ready",
      Running: "Running",
      Paused: "Paused",
      Stopped: "Stopped",
      "No Signal": "No Signal",
      "No GPS": "No GPS",
      Finished: "Finished",
      Aborted: "Aborted",
      Disqualified: "Disqualified",
      Unknown: "Unknown",
      "Edit result": "Edit result",
      Close: "Close",
      "Select status": "Select status",
      "Start time": "Start time",
      "Enter start time": "Enter start time",
      "Finish time": "Finish time",
      "Enter finish time": "Enter finish time",
      "Enter distance": "Enter distance",
      "Enter elevation": "Enter elevation",
      "Upload GPX": "Upload GPX",
      "Upload a GPX file": "Upload a GPX file",
      "Select file": "Select file",
      "API version": "API version",
      "Select API version": "Select API version",
      "Are you sure?": "Are you sure?",
      "Do you really want to delete this result?": "Do you really want to delete this result?",
      Yes: "Yes",
      No: "No",
      "GPX line color": "GPX line color",
      "Line color": "Line color",
      "GPX line width": "GPX line width",
      "Line width": "Line width",
      "Gpx line color is required": "Gpx line color is required",
      "Gpx line width is required": "Gpx line width is required",
      "Gpx line width  should be at least 1": "Gpx line width  should be at least 1",
      "": "",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
