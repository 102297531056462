import { FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { CheckboxControl } from "../../../shared/form/controls/CheckboxControl/CheckboxControl";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { useApi } from "../../../shared/useApi";
import { AuthLayout } from "../AuthLayout/AuthLayout";
import "./SignUp.scss";
import { UNAUTHORIZED_REDIRECT_PATH } from "../../../helpers/consts";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationName: string;
  acceptTerms: boolean;
};
const initialFormData: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  organizationName: "",
  acceptTerms: false,
};

export function SignUp() {
  const { t } = useTranslation();
  const history = useHistory();
  const { post, response } = useApi("/sign-up");
  const [signUpError, setSignUpError] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormData,
    validationSchema: createValidationSchema(t),
    onSubmit: signup,
  });

  return (
    <AuthLayout className="sign-up">
      <>
        <div className="header">
          <h2 className="title">{t("Sign up")}</h2>
          <div className="or-sign-in">
            <span className="or">{t("or")}</span>
            <Link to="/sign-in" className="sign-in-link">
              {t("login")}
            </Link>
          </div>
        </div>

        <FormikProvider value={formik}>
          {signUpError && <Alert variant="danger">{t("Can't sign up. Do you mind trying with another email address")}</Alert>}
          <Form className="sign-in-form" onSubmit={formik.handleSubmit}>
            <InputControl type="text" name="organizationName" label={t("Organization name")} placeholder={t("Your organization's name")}></InputControl>
            <InputControl type="text" name="firstName" label={t("First name")} placeholder={t("Enter your first name")}></InputControl>
            <InputControl type="text" name="lastName" label={t("Last name")} placeholder={t("Enter your last name")}></InputControl>
            <InputControl type="text" name="email" label={t("Email")} placeholder={t("Enter your email")}></InputControl>
            <InputControl type="password" name="password" label={t("Password")} placeholder={t("Enter your password")}></InputControl>
            <CheckboxControl
              name="acceptTerms"
              label={
                <span>
                  {t("I agree with RaceLog")}
                  <a href="#terms" className="terms-link">
                    {t("terms & conditions")}
                  </a>
                </span>
              }
            ></CheckboxControl>
            <Button type="submit" size="sm" className="btn-block mt-4">
              {t("Sign up")}
            </Button>
          </Form>
        </FormikProvider>
      </>
    </AuthLayout>
  );

  async function signup(formData: FormData) {
    setSignUpError(false);

    const { acceptTerms, ...signupData } = formData;
    await post("", signupData);

    if (!response.ok) {
      setSignUpError(true);

      return;
    }
    history.push(UNAUTHORIZED_REDIRECT_PATH);
  }
}

function createValidationSchema(t: TFunction) {
  const minPassChars = 6;
  return Yup.object({
    firstName: Yup.string().required(t("First is required")),
    lastName: Yup.string().required(t("Last is required")),
    password: Yup.string()
      .required(t("Password is required"))
      .min(
        minPassChars,
        t("Password should have at least {{num}} characters", {
          num: minPassChars,
        })
      ),
    email: Yup.string().required(t("Email is required")).email("Email should be a valid email address"),
    organizationName: Yup.string().required(t("Organiation name is required")),
    acceptTerms: Yup.boolean().is([true], t("You must agree with RaceLog terms & conditions")),
  });
}
