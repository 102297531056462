import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import ReduxToastr from "react-redux-toastr";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./index.scss";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import appReducer from "./store";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const PAYPAL_OPTIONS = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
  currency: "EUR",
  "disable-funding": "bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo",
};

const sagaMiddleware = createSagaMiddleware();
let store = createStore(appReducer, applyMiddleware(sagaMiddleware, thunk));
if (process.env.NODE_ENV === "development") {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(appReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk)));
}
const appSaga = require("./store/sagas").default;

sagaMiddleware.run(appSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReduxToastr preventDuplicates position="top-right" progressBar closeOnToastrClick />
      <PayPalScriptProvider options={PAYPAL_OPTIONS}>
        <App />
      </PayPalScriptProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
