import { ReactElement, useEffect, useState } from "react";
import { Link, Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventTabContentLayout } from "../shared/EventTabContentLayout/EventContentTabLayout";
import { withSuspense } from "../../../shared/withSuspense";
import { SideNav } from "../../../shared/SideNav/SideNav";
import { AddParticipantModal } from "../Results/AddParticipantModal/AddParticipantModal";
import { useApi } from "../../../shared/useApi";
import { selectIsCartPage } from "../../../store/global/selectors";
import { contentWidth } from "../../consts";
import "./TrailNavPage.scss";
import { toastr } from "react-redux-toastr";

export type TrailData = any;

type TrailNavPageProps = {
  title: string;
  className: string;
  allowCreate?: boolean;
  placeholder?: () => ReactElement;
  onExport?: () => void;
  hideButtons?: boolean;
  children: (props: { trailData: TrailData; onTrailCreate: (trailId: number) => void; onTrailUpdate: (data: TrailData) => void }) => ReactElement;
};

export const TrailNavPage = withSuspense(function ({ title, className, allowCreate, placeholder, onExport, hideButtons, children }: TrailNavPageProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const isCartPage = useSelector(selectIsCartPage);
  const { eventId } = useParams<{ eventId: string }>();
  const { path, url, isExact } = useRouteMatch();
  const { get, response } = useApi(`/races/${eventId}/trails`);
  const [trails, setTrails] = useState<TrailData[]>([]);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [isCreatingParticipant, setIsCreatingParticipant] = useState(false);
  const [showAddParticipantModal, hideAddParticipantModal] = useModal(() => {
    return <AddParticipantModal onHide={hideAddParticipantModal} onSave={onSaveParticipant} />;
  }, [isCreatingParticipant]);

  useEffect(() => {
    if (eventId) {
      loadTrails().then((loadedTrails) => {
        // if (!loadedTrails) {
        //   return null;
        // }
        let redirectPath = null;
        if (isExact) {
          if (loadedTrails.length > 0) {
            redirectPath = loadedTrails[0].id;
          } else if (allowCreate) {
            redirectPath = "create";
          }
        }

        setShowPlaceholder(loadedTrails.length === 0);

        if (redirectPath) {
          history.replace(`${url}/${redirectPath}`);
        }
      });
    } else {
      setTrails([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (isExact && allowCreate) {
      history.replace(`${url}/create`);
    }
  }, [url, isExact, history, allowCreate]);

  const loadTrails = async () => {
    const trails = await get("");
    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }
    setTrails(trails);
    return trails;
  };

  const onSaveParticipant = () => {
    hideAddParticipantModal();
  };

  return (
    <div className={`d-flex justify-content-center trail-nav-page ${className}`}>
      <Col sm={contentWidth}>
        <Row>
          {!isCartPage && (
            <Col sm="2">
              <SideNav
                className="trails-nav"
                items={trails.map((t) => ({
                  url: `${url}/${t.id}`,
                  label: t.trailName,
                }))}
              />
              {allowCreate && (
                <div className="mt-4">
                  <Link to={`${url}/create`} className="btn btn-primary btn-sm btn-block btn-purple">
                    <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
                    {t("Add trail")}
                  </Link>
                </div>
              )}
            </Col>
          )}
          <Col sm={isCartPage ? 12 : 10}>
            <EventTabContentLayout
              title={title}
              className="trail-nav-content"
              width={12}
              hideButtons={hideButtons}
              onExport={onExport}
              onAddUser={() => {
                setIsCreatingParticipant(true);
                showAddParticipantModal();
              }}
            >
              <Route
                path={`${path}/:trailId`}
                render={({ match }) => {
                  const trailId = match.params.trailId;
                  return children({
                    trailData: trails.find((t) => t.id === Number(trailId)),
                    onTrailCreate: (trailId) => {
                      loadTrails().then(() => {
                        history.push(`${url}/${trailId}`);
                      });
                    },
                    onTrailUpdate: (data) => {
                      setTrails((current) =>
                        current.map((t) => {
                          if (t.id === Number(trailId)) {
                            return data;
                          }

                          return t;
                        })
                      );
                    },
                  });
                }}
              />
            </EventTabContentLayout>
          </Col>
        </Row>
        {showPlaceholder && placeholder && placeholder()}
      </Col>
    </div>
  );
});
