import { Button, Modal } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DatetimeControl } from "../../../../shared/form/controls/DateTimeControl/DateTimeControl";
import { InputControl } from "../../../../shared/form/controls/InputControl/InputControl";
import { SelectControl } from "../../../../shared/form/controls/SelectControl/SelectControl";
import { ManagedForm } from "../../../../shared/form/Form/ManagedForm";
import { ReactComponent as Times } from "../../../../assets/svg/iconTimes.svg";
import { genderOptions } from "../../../../helpers/enums/genders";
import { selectTrailId } from "../../../../store/global/selectors";
import { useApi } from "../../../../shared/useApi";
import { PARTICIPANT_STATUS_WAITING } from "../statusHelper";
import { setReloadResults } from "../store/actions";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";

type AddParticipantModalProps = {
  onHide: () => void;
  onSave: () => void;
};

const initialFormData = {
  userName: "",
  birthDate: null,
  gender: null,
};
export function AddParticipantModal({ onHide, onSave }: AddParticipantModalProps) {
  const { t } = useTranslation();
  const trailId = useSelector(selectTrailId);
  const dispatch = useDispatch();
  const { post, response } = useApi("/participant");

  async function onSubmit(formData: any) {
    await post({
      ...formData,
      trailId,
      status: PARTICIPANT_STATUS_WAITING,
      startTime: moment(formData.startTime).valueOf(),
      finishTime: moment(formData.finishTime).valueOf(),
    });

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to save activity"));
      return null;
    }
    dispatch(setReloadResults(true));
    onSave();
  }

  return (
    <Modal onHide={onHide} show centered>
      <Modal.Header className="d-flex align-items-center border-0">
        <Modal.Title className="h6">{t("Add new user")}</Modal.Title>
        <Button aria-label="Close the modal" variant="none" onClick={onHide}>
          <Times />
        </Button>
      </Modal.Header>
      <ManagedForm validationSchema={createValidationSchema(t)} onSubmit={onSubmit} values={initialFormData}>
        {() => (
          <>
            <Modal.Body>
              <div>
                <InputControl type="text" name="userName" label={t("Name")} placeholder={t("Name")} />
                <DatetimeControl
                  name="birthDate"
                  label={t("Birthdate")}
                  placeholder={t("dd.mm.yyyy")}
                  showTimeInput={false}
                  dateFormat="dateFormatDatepicker"
                />
                <SelectControl
                  name="gender"
                  label={t("Gender")}
                  placeholder={t("Select gender")}
                  options={genderOptions.basic.map((gender) => ({
                    value: gender.value,
                    name: gender.name,
                  }))}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button size="sm" variant="outline-default" className="btn-small btn-rounded mr-4" onClick={onHide}>
                {t("Cancel")}
              </Button>
              <Button type="submit" size="sm" variant="default" className="btn-small btn-rounded">
                {t("Add")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </ManagedForm>
    </Modal>
  );
}

function createValidationSchema(t: TFunction) {
  return Yup.object({
    userName: Yup.string().required(t("Name of the user is required")),
    birthDate: Yup.date().required(t("Birth date of the user is required")),
    gender: Yup.string().required(t("Gender of the user is required")),
  });
}
