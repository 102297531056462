import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useModal } from "react-modal-hook";
import GpxUploadButton from "./components/GpxUploadButton";
import { Placeholder } from "../../../shared/Placeholder/Placeholder";
import { simpleTextRenderer, Table } from "../../../shared/Table/Table";
import { TrailNavPage } from "../TrailNavPage/TrailNavPage";
import { EditActivityModal } from "./EditActivityModal/EditActivityModal";
import { useConfirmationModal } from "../../../shared/ConfirmationModal/ConfirmationModal";
import { ReactComponent as IconTrash } from "../../../assets/svg/iconTrash.svg";
import { ReactComponent as IconEdit } from "../../../assets/svg/iconEdit.svg";
import { loadParticipantsToPurchaseFromStorage, setReloadResults, setParticipantsToPurchase, removeGpxFromParticipant } from "./store/actions";
import { selectParticipantsToPurchase, selectReloadResults } from "./store/selectors";
import { Participant } from "./store/interfaces";
import { getRaceStatus, getStatusLabel, useParticipantStatuses } from "./statusHelper";
import { loadCartItems, setCartItems } from "../../Cart/store/actions";
import { selectCartItemsCount } from "../../Cart/store/selectors";
import { download, useApi } from "../../../shared/useApi";
import { setTrailId } from "../../../store/global/actions";
import "./Results.scss";

type ResultsProps = { raceData: any };
export function Results({ raceData }: ResultsProps) {
  const { t } = useTranslation();
  const resultListRef = useRef();

  return (
    <TrailNavPage
      title={t("Results")}
      className="results"
      placeholder={() => <Placeholder title={t("No results yet.")} description={t("Add some trails on the Trail info tab.")} />}
      onExport={() => {
        (resultListRef.current as any)?.export();
      }}
    >
      {({ trailData }) => <ResultsList trailId={trailData ? trailData.id : null} raceStatus={getRaceStatus(raceData)} ref={resultListRef} />}
    </TrailNavPage>
  );
}

type ResultsListProps = { trailId: number; raceStatus: string };
const ResultsList = forwardRef(({ trailId, raceStatus }: ResultsListProps, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const participantsToPurchase = useSelector(selectParticipantsToPurchase);
  const reload = useSelector(selectReloadResults);
  const itemsCount = useSelector(selectCartItemsCount);
  const participantStatuses = useParticipantStatuses(t);
  const { get, response, error } = useApi("/results/" + trailId);
  const { del, response: deleteResponse } = useApi("/activities");
  const { put, response: putResponse } = useApi("/user-trail");
  const [editingActivity, setEditingActivity] = useState<number>();
  const [loadedParticipants, setLoadedParticipants] = useState<Participant[]>([]);
  const [showEditModal, hideEditModal] = useModal(() => {
    return <EditActivityModal raceStatus={raceStatus} onHide={hideEditModal} onSave={onSaveActivity} activityId={editingActivity} />;
  }, [editingActivity]);
  const showDeleteConfirmation = useConfirmationModal(t("Are you sure?"), t("Do you really want to delete this result?"));

  useEffect(() => {
    if (trailId) {
      dispatch(setTrailId(trailId));
      loadResults().then(() => dispatch(setReloadResults(false)));
    }
    return () => {
      dispatch(setTrailId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailId, raceStatus, reload]);

  useEffect(() => {
    if (trailId) {
      loadResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailId, participantsToPurchase]);

  useImperativeHandle(ref, () => ({
    export() {
      download("/results/" + trailId + "/xlsx");
    },
  }));

  async function loadResults(setParticipants = true) {
    const res: any[] = await get();

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }

    if (!res.length) return null;
    const savedParticipants = loadParticipantsToPurchaseFromStorage(res[0].eventId);

    if (setParticipants) {
      dispatch(setParticipantsToPurchase(savedParticipants));
    }

    const filteredParticipants = res.map((val) => {
      const participantInSaved = savedParticipants.find((s) => s.activityId === val.activityId);

      if (participantInSaved) {
        return participantInSaved;
      }
      return val;
    });

    setLoadedParticipants(
      filteredParticipants.map((r, i) => {
        const status = participantStatuses[r.statusNum];
        const finishDuration =
          status.isFinished && r.startTime && r.finishTime ? formatDuration(moment.duration(moment(r.finishTime).diff(moment(r.startTime)))) : null;
        return {
          ...r,
          rank: i + 1,
          time: finishDuration,
          isGpxPurchased: r.isGpxPurchased === "true",
          status,
          xmlContentBase64: r.xmlContentBase64 || null,
          gpxFileName: r.gpxFileName || null,
        };
      })
    );
  }

  const onSaveActivity = async () => {
    hideEditModal();
    await loadResults();
  };

  const deleteActivity = (participant: Participant) => {
    showDeleteConfirmation(async () => {
      await del(`/${participant.activityId}`);

      if (!deleteResponse.ok) {
        console.error("Can not delete activity");
      }
      dispatch(removeGpxFromParticipant(participant.eventId, participant.activityId));
      await loadResults();
    });
  };

  const updateGpxFile = async (participant: Partial<Participant>) => {
    const { activityId, trailId, userId, gpxFileName, xmlContentBase64 } = participant;
    await put({
      activityId,
      trailId,
      userId,
      gpxFileName,
      xmlContentBase64,
    });

    if (!putResponse.ok) {
      console.error("Can not update GPX file");
    }
    await loadResults();
  };

  const onPurchaseClick = () => {
    history.push(`/checkout`);
  };

  const gpxUploadCallback = async (xmlContentBase64: string, gpxFileName: string, rowData: any) => {
    if (rowData.isGpxPurchased) {
      await updateGpxFile({
        activityId: rowData.activityId,
        trailId,
        userId: rowData.userId,
        gpxFileName,
        xmlContentBase64,
      });
    } else {
      dispatch(setCartItems(loadCartItems()));
    }
    await loadResults();
  };

  return (
    <>
      <Table
        columns={[
          { header: t("Rank"), render: simpleTextRenderer("rank") },
          { header: t("Name"), render: simpleTextRenderer("userName") },
          { header: t("Birthdate"), render: (d) => (d.birthDate ? moment(d.birthDate).format("DD.MM.yyyy") : "") },
          { header: t("Gender"), render: simpleTextRenderer("gender") },
          { header: t("Time"), render: simpleTextRenderer("time") },
          {
            header: t("Distance"),
            render: (d) => `${(d.currentDistanceM / 1000).toFixed(2)}km`,
          },
          {
            header: t("Elevation"),
            render: (d) => `${d.currentElevationM}m`,
          },
          {
            header: t("Status"),
            render: (d) => <div>{getStatusLabel(d.status, raceStatus)}</div>,
          },
          {
            header: t("GPX purchased"),
            render: (d) => (d.isGpxPurchased ? "✓" : "✕"),
          },
          {
            header: "",
            render: (d) => (
              <div className="d-flex">
                <div>
                  <Button
                    size="sm"
                    variant="outline-light"
                    className="square-btn"
                    onClick={() => {
                      setEditingActivity(d.activityId);
                      showEditModal();
                    }}
                  >
                    <IconEdit />
                  </Button>
                </div>
                <div>
                  <Button size="sm" variant="outline-light" className="ml-2 square-btn" onClick={() => deleteActivity(d)}>
                    <IconTrash />
                  </Button>
                </div>
                <GpxUploadButton
                  rowData={d}
                  callback={(xmlContentBase64: string, gpxFileName: string) => gpxUploadCallback(xmlContentBase64, gpxFileName, d)}
                  isUpdateAction={d.isGpxPurchased}
                />
              </div>
            ),
          },
        ]}
        data={[...loadedParticipants]}
        dataKeyProperty="activityId"
        placeholderTitle={t("No results yet.")}
        placeholderDescription={t("Here you will find the results list when available.")}
        pagination={{
          hasPages: false,
          hasPreviousPage: false,
          hasNextPage: false,
          pageNumbers: [],
          currentPage: 0,
          onPreviousPage: () => null,
          onPageSelect: () => null,
          onNextPage: () => null,
        }}
        loadError={
          error
            ? {
                error: t("Error loading results."),
                details: t("Please try again later."),
              }
            : undefined
        }
      />
      <div className="mt-4 d-flex justify-content-end">
        <Button size="sm" variant="default" className="btn-wide btn-rounded" disabled={!itemsCount} onClick={onPurchaseClick}>
          {t("Make a purchase")}
        </Button>
      </div>
    </>
  );
});

export function formatDuration(duration: moment.Duration) {
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = Math.floor(duration.asHours());

  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `00:${formatInt(seconds)}`;

  function formatInt(int: number) {
    if (int < 10) {
      return `0${int}`;
    }
    return `${int}`;
  }
}

// fix add to cart, add paypal, test
