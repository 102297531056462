import React, {useEffect, useRef, useState} from "react";
import {Overlay, Tooltip} from "react-bootstrap";
import iconTrash from "../../../assets/svg/iconTrashWhite.svg";
import "./SliderTrack.scss";

const SliderTrack = ({props, colors, index, isTheLastTrack, removePoint, onOverlayMouseEnter, onOverlayMouseLeave}: SliderTrackProps) => {
  const trackRef = useRef(null);
  const [showRemoveRangeOverlay, setShowRemoveRangeOverlay] = useState<null | number>(null);
  
  useEffect(() => {
    const element = trackRef.current;
    document.addEventListener("click", (event) => {
      const show = event.target === element;
      if (!show) {
        onOverlayMouseLeave();
        setShowRemoveRangeOverlay(null);
      }
    });
    return () => document.removeEventListener("click", (event) => {
      const show = event.target === element;
      if (!show) {
        onOverlayMouseLeave();
        setShowRemoveRangeOverlay(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackRef]);
  
  useEffect(() => {
    if (showRemoveRangeOverlay === index) {
      onOverlayMouseEnter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRemoveRangeOverlay]);
  
  const onTrackMouseEnter = () => {
    setShowRemoveRangeOverlay(index);
    const timeout = setTimeout(() => {
      setShowRemoveRangeOverlay(null);
      onOverlayMouseLeave();
    }, 2000);
    return () => clearTimeout(timeout);
  }
  
  return (
    <>
      {!isTheLastTrack && (
        <Overlay target={trackRef.current} show={showRemoveRangeOverlay === index} placement="top">
          {(props) => (
            <Tooltip
              {...props}
              id={`tooltip-${colors[index]}-${index}`}
              className="remove-range-tooltip"
              onMouseEnter={onOverlayMouseEnter}
              onMouseLeave={onOverlayMouseLeave}>
              <button className="remove-btn" type="button" style={{background: colors[index]}} onClick={() => removePoint(index)}>
                <img src={iconTrash} alt="" className="remove-btn-icon"/>
                <span>Delete</span>
              </button>
            </Tooltip>
          )}
        </Overlay>
      )}
      <div
        {...props}
        ref={trackRef}
        className={`slider-track slider-track-${index}${Number.isInteger(showRemoveRangeOverlay) && showRemoveRangeOverlay !== index ? " low-opacity" : ""}`} // style
        onMouseEnter={!isTheLastTrack ? onTrackMouseEnter : () => {}}
      />
    </>
  );
}

type SliderTrackProps = {
  props: any;
  index: number;
  isTheLastTrack: boolean;
  colors: string[];
  onOverlayMouseEnter: () => void;
  onOverlayMouseLeave: () => void;
  removePoint: (ageTo: number) => void;
};

export default SliderTrack;
