import { Marker }  from 'react-mapbox-gl';

export const StartMarker = (props: any) => {
    const {
        longitude,
        latitude
    } = props;

    const coordinates = [
        longitude,
        latitude
    ]

    return (
      <Marker
        coordinates={coordinates}
        anchor="bottom"
      >
        <img
            width="32"
            alt="Current Race Start"
            height="44"
            src="https://static.wixstatic.com/media/2cd43b_355767b937cf431ebdbd851fc2f5254c~mv2.png/v1/fill/w_320,h_435,q_90/2cd43b_355767b937cf431ebdbd851fc2f5254c~mv2.png"
        />
      </Marker>
    );
  };
  