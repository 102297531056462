import { CheckPoints } from "./CheckPoints/CheckPoints";
import { EventOverview } from "./EventOverview/EventOverview";
import { Results } from "./Results/Results";
import { TrailInfo } from "./TrailInfo/TrailInfo";
import AgeGroups from "./AgeGroups/AgeGroups";

type EventTab = {
  path: string;
  name: string;
  Component: any;
  hideTab?: boolean;
};

export const eventTabs: EventTab[] = [
  {
    path: "overview",
    name: "Event overview",
    Component: EventOverview,
  },
  {
    path: "trail-info",
    name: "Trail info",
    Component: TrailInfo,
  },
  {
    path: "age-groups",
    name: "Age groups",
    Component: AgeGroups,
  },
  {
    path: "check-points",
    name: "Check points",
    Component: CheckPoints,
  },
  // {
  //   path: "vouchers",
  //   name: "Vouchers",
  // },
  // {
  //   path: "participants",
  //   name: "Participants",
  // },
  {
    path: "results",
    name: "Results",
    Component: Results,
  },
];
