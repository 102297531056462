import { ChangeEvent, useEffect, useRef, useState } from "react";
import { TFunction } from "i18next";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import moment from "moment-timezone";
import * as Yup from "yup";
import { Map } from "../Map/Map";
import { EventStatistics } from "../EventStatistics/EventStatistics";
import { InputArrayControl } from "../../../shared/form/controls/InputArrayControl/InputArrayControl";
import { DatetimeControl } from "../../../shared/form/controls/DateTimeControl/DateTimeControl";
import { EventTabContentLayout } from "../shared/EventTabContentLayout/EventContentTabLayout";
import { SelectControl } from "../../../shared/form/controls/SelectControl/SelectControl";
import { ImageControl } from "../../../shared/form/controls/ImageControl/ImageControl";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { ManagedForm } from "../../../shared/form/Form/ManagedForm";
import { utcTime, localizedTime } from "../../../shared/utils";
import { withSuspense } from "../../../shared/withSuspense";
import { useApi, useApiGet } from "../../../shared/useApi";
import { MAX_EMERGENCY_NUMBER } from "../../../helpers/consts";
import { contentWidth } from "../../consts";
import { eventVisibilities } from "../event-visibilities";
import "./EventOverview.scss";

type EventOverviewProps = {
  onCreate(raceId: number): void;
};

type RaceData = any;

const initialRaceData = {
  id: undefined,
  raceName: "",
  locationName: "",
  country: Intl.DateTimeFormat().resolvedOptions().timeZone ?? "",
  startTime: "",
  endTime: "",
  visible: "",
  raceTypeId: "",
  locationLat: "",
  locationLong: "",
  headerImg: "",
  emergencyNumbers: [""],
  apiVersion: "v3",
};

export const EventOverview = withSuspense(function ({ onCreate }: EventOverviewProps) {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId?: string }>();
  const [raceData, setRaceData] = useState<RaceData>(initialRaceData);

  const { get, response: loadResponse } = useApi("/races", {
    //   suspense: true
  });
  const { post, put, response: saveResponse } = useApi("/races");

  useEffect(() => {
    if (eventId) {
      loadRace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  async function loadRace() {
    const race = await get(`/${eventId}`);
    if (!loadResponse.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }

    setRaceData(convertServerData(race));
  }

  function setRaceStartCoordinates(latitude: number, longitude: number, values: Partial<RaceData>) {
    setRaceData({
      ...values,
      locationLat: latitude,
      locationLong: longitude,
    });
  }

  function handleLatitudeChange(e: ChangeEvent<HTMLInputElement>, values: Partial<RaceData>) {
    setRaceData({
      ...values,
      locationLat: e.currentTarget.valueAsNumber,
    });
  }

  function handleLongitudeChange(e: ChangeEvent<HTMLInputElement>, values: Partial<RaceData>) {
    setRaceData({
      ...values,
      locationLong: e.currentTarget.valueAsNumber,
    });
  }

  async function saveRace(values: Partial<RaceData>) {
    const { headerImg, ...restValues } = values;

    const saveData = await post("", convertFormData(restValues));

    if (!saveResponse.ok) {
      toastr.error(t("Error"), t("Couldn't create"));
      return null;
    }

    if (headerImg) {
      await uploadImage(saveData.id, headerImg).catch(() => {
        toastr.error(t("Error"), t("Couldn't upload image"));
        return null;
      });
    }

    onCreate(saveData.id);
  }

  async function updateRace(values: Partial<RaceData>) {
    const { headerImg, ...restValues } = values;
    const updatedRace = await put(`/${raceData.id}`, convertFormData(restValues));

    if (!saveResponse.ok) {
      toastr.error(t("Error"), t("Couldn't update"));
      return null;
    }

    if (headerImg instanceof File) {
      await uploadImage(raceData.id, headerImg).catch(() => {
        toastr.error(t("Error"), t("Couldn't upload image"));
        return null;
      });
    }

    setRaceData(convertServerData(updatedRace));
  }

  async function uploadImage(raceId: number, image: File) {
    const uploadUrl = await post(`/${raceId}/image-upload-url`);

    await fetch(uploadUrl, { method: "PUT", body: image });
  }

  return (
    <EventTabContentLayout title={t("Event details")} className="event-overview" width={contentWidth}>
      <EventOverviewForm
        raceData={raceData}
        setRaceStartCoordinates={setRaceStartCoordinates}
        handleLatitudeChange={handleLatitudeChange}
        handleLongitudeChange={handleLongitudeChange}
        onSubmit={raceData.id == null ? saveRace : updateRace}
      />
    </EventTabContentLayout>
  );
});

type EventOverviewFormProps = {
  raceData: Partial<RaceData>;
  onSubmit: (values: Partial<RaceData>) => Promise<any>;
  setRaceStartCoordinates: (latitude: number, longitude: number, values: Partial<RaceData>) => void;
  handleLatitudeChange: (e: ChangeEvent<HTMLInputElement>, values: Partial<RaceData>) => void;
  handleLongitudeChange: (e: ChangeEvent<HTMLInputElement>, values: Partial<RaceData>) => void;
};

function EventOverviewForm({ raceData, onSubmit, setRaceStartCoordinates, handleLatitudeChange, handleLongitudeChange }: EventOverviewFormProps) {
  const { t } = useTranslation();
  const valuesRef = useRef() as any;
  let { data: raceTypes = [] } = useApiGet("/race-types", []);
  let mappedTimezones = moment.tz.names().map((timezone) => {
    return { value: timezone, name: t(timezone) };
  });

  return (
    <ManagedForm values={raceData} validationSchema={createValidationSchema(t)} toolbar={{ lastUpdateDate: raceData.changeDate }} onSubmit={onSubmit}>
      {({ values }) => {
        valuesRef.current = { ...values };

        return (
          <Row>
            <Col sm="4">
              <InputControl type="text" name="raceName" label={t("Race name")} placeholder={t("Enter name")} />
              <InputControl type="text" name="locationName" label={t("Location name")} placeholder={t("Enter location name")} />
              <SelectControl name="country" label={t("Time Zone")} placeholder={t("Select Time Zone")} options={mappedTimezones} />
              <DatetimeControl name="startTime" label={t("Starting")} placeholder={t("Enter starting")} />
              <DatetimeControl name="endTime" label={t("Ending")} placeholder={t("Enter ending")} />
              <SelectControl
                name="visible"
                label={t("Visibility")}
                placeholder={t("Select visibility")}
                options={eventVisibilities.map((v) => ({
                  value: v.value,
                  name: t(v.name),
                }))}
              />
              <SelectControl
                name="raceTypeId"
                label={t("Event type")}
                placeholder={t("Select event type")}
                options={raceTypes.map((rt: any) => ({
                  value: rt.id,
                  name: rt.raceTypeName,
                }))}
              />
              <Form.Row>
                <Col sm="12">
                  <Form.Label>{t("Select race start coordinates")}</Form.Label>
                </Col>
                <Col sm="12">
                  <Map
                    latitude={raceData.locationLat}
                    longitude={raceData.locationLong}
                    onClick={(latitude, longitude) => setRaceStartCoordinates(latitude, longitude, valuesRef.current)}
                  />
                </Col>
                <Col sm="6">
                  <InputControl
                    type="number"
                    name="locationLat"
                    label={t("Latitude")}
                    onChange={(e) => handleLatitudeChange(e, valuesRef.current)}
                    placeholder={t("Enter latitude")}
                  />
                </Col>
                <Col sm="6">
                  <InputControl
                    type="number"
                    name="locationLong"
                    label={t("Longitude")}
                    onChange={(e) => handleLongitudeChange(e, valuesRef.current)}
                    placeholder={t("Enter longitude")}
                  />
                </Col>
              </Form.Row>
              <ImageControl
                name="headerImg"
                label={t("Image")}
                uploadText={t("Upload image")}
                changeText={t("Change image")}
                previewClassName="header-image-preview"
              />
              <InputArrayControl
                values={values}
                maxItems={MAX_EMERGENCY_NUMBER}
                name="emergencyNumbers"
                label={t("Emergency numbers")}
                placeholder={t("Enter a phone number")}
                type="text"
              />
            </Col>
            <Col sm="8">
              <EventStatistics raceId={raceData.id} />
            </Col>
          </Row>
        );
      }}
    </ManagedForm>
  );
}

function createValidationSchema(t: TFunction) {
  return Yup.object({
    raceName: Yup.string().required(t("Race name is required")),
    locationName: Yup.string().required(t("Location name is required")),
    startTime: Yup.date().required(t("Starting is required")).max(Yup.ref("endTime"), "Starting should be before ending"),
    endTime: Yup.date().required(t("Ending is required")).min(Yup.ref("startTime"), "Ending should be after starting"),
    visible: Yup.number().required(t("Visibility is required")),
    raceTypeId: Yup.string().required(t("Event type is required")),
    locationLat: Yup.number().min(-90).max(90).required(t("Latitude is required")),
    locationLong: Yup.number().min(-90).max(90).required(t("Longitude is required")),
  });
}

function convertServerData(serverData: RaceData): RaceData {
  try {
    const startTime = localizedTime(new Date(serverData.startTime), serverData.country);
    const endTime = localizedTime(new Date(serverData.endTime), serverData.country);
    const emergencyNumbers = JSON.parse(serverData.emergencyNumbers);

    if (!emergencyNumbers.length) {
      emergencyNumbers.push("");
    }

    return {
      ...serverData,
      emergencyNumbers,
      startTime,
      endTime,
    };
  } catch (e) {
    console.error(e);
  }
}

function convertFormData(formData: Partial<RaceData>) {
  const startTime = utcTime(new Date(formData.startTime), formData.country);
  const endTime = utcTime(new Date(formData.endTime), formData.country);
  const emergencyNumbers = JSON.stringify(formData.emergencyNumbers);

  return {
    ...formData,
    emergencyNumbers,
    startTime,
    endTime,
  };
}
