import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowBack } from "../../../../assets/svg/iconArrowBack.svg";
import { ReactComponent as Export } from "../../../../assets/svg/iconExport.svg";
import { ReactComponent as Import } from "../../../../assets/svg/iconImport.svg";
import { ReactComponent as Plus } from "../../../../assets/svg/iconPlus.svg";
import { selectIsCartPage } from "../../../../store/global/selectors";
import { clearCart, loadCartItems, setCartItems } from "../../../Cart/store/actions";
import { selectCartItemsCount } from "../../../Cart/store/selectors";
import "./EventContentTabLayout.scss";

type EventTabLayoutProps = {
  title: string;
  className: string;
  headerClassName?: string;
  width: number;
  onExport?: () => void;
  onAddUser?: () => void;
  hideButtons?: boolean;
  children: ReactNode;
};
export function EventTabContentLayout({ title, className, headerClassName, width, onExport, onAddUser, hideButtons, children }: EventTabLayoutProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCartPage = useSelector(selectIsCartPage);
  const cartItemsCount = useSelector(selectCartItemsCount);

  const onCartClear = () => {
    clearCart();
    dispatch(setCartItems(loadCartItems()));
  };

  const onGoBackClick = () => {
    history.goBack();
  };

  return (
    <div className={`event-tab-content-layout d-flex justify-content-center ${className}`}>
      <Col md={width}>
        <div className="header d-flex align-items-center">
          {isCartPage && (
            <Button size="sm" variant="none" className="go-back-link align-self-center" aria-label={t("Go back to the Results tab")} onClick={onGoBackClick}>
              <ArrowBack className="back-icon" />
            </Button>
          )}
          <h4 className={`mr-auto ${headerClassName ? headerClassName : ""}`}>{title}</h4>
          {isCartPage && (
            <Button
              variant="outline-default"
              size="sm"
              className="btn-mid btn-rounded d-flex align-items-center mr-4 font-weight-500"
              onClick={onCartClear}
              disabled={!cartItemsCount}
            >
              {t("Clear cart")}
            </Button>
          )}
          {!isCartPage && (
            <>
              {!hideButtons && (
                <Button variant="outline-default" size="sm" className="btn-mid btn-rounded d-flex align-items-center mr-4 p-3 font-weight-500" disabled>
                  <Import className="mr-3" style={{ transition: "0.15s" }} />
                  {t("Import")}
                </Button>
              )}
              {!hideButtons && (
                <Button
                  variant="outline-default"
                  size="sm"
                  className="btn-mid btn-rounded d-flex align-items-center p-3 font-weight-500"
                  disabled={onExport == null}
                  onClick={onExport}
                >
                  <Export className="mr-3" style={{ transition: "0.15s" }} />
                  {t("Export")}
                </Button>
              )}
              {!hideButtons && onExport && (
                <Button variant="default" size="sm" className="btn-mid btn-rounded d-flex align-items-center ml-5 p-3 font-weight-500" onClick={onAddUser}>
                  <Plus fill="#ffffff" className="mr-3" />
                  {t("Add user")}
                </Button>
              )}
            </>
          )}
        </div>
        {children}
      </Col>
    </div>
  );
}
