import { AppState } from "../interfaces";

const selectLoaded = (state: AppState) => state.global.loaded;
const selectLoggedIn = (state: AppState) => state.global.loggedIn;
const selectUser = (state: AppState) => state.global.user;
const selectIsCartPage = (state: AppState) => state.global.isCartPage;
const selectEventId = (state: AppState) => state.global.eventId;
const selectTrailId = (state: AppState) => state.global.trailId;

export { selectLoaded, selectLoggedIn, selectUser, selectIsCartPage, selectEventId, selectTrailId };
