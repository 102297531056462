import "./Placeholder.scss";

type PlaceholderProps = { title: string; description: string };
export function Placeholder({ title, description }: PlaceholderProps) {
  return (
    <div className="app-placeholder pt-5">
      <h4 className="title">{title}</h4>
      <p className="details">{description}</p>
    </div>
  );
}
