import { Col } from "react-bootstrap";
import { AppHeader } from "../../shared/AppHeader/AppHeader";
import { contentWidth } from "../consts";
import "./Help.scss";

export function Help() {
  return (
    <div className="help">
      <AppHeader></AppHeader>
      <div className="d-flex justify-content-center header">
        <Col sm={contentWidth}>
          <h2>Need help?</h2>

          <p>Lorem ipsum dolor sit amet</p>
        </Col>
      </div>
      <div className="d-flex justify-content-center content">
        <Col sm={contentWidth}>
          <Col sm="6">
            <h4 className="content-title">Lorem ipsum dolor sit amet</h4>

            <section>
              <h5>Nulla lorem turpis</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                sodales mauris metus, nec pellentesque lacus congue a. Sed nulla
                nibh, vehicula nec tellus nec, elementum lacinia sapien. Nam
                elit velit, laoreet eu felis id, iaculis blandit ex. Curabitur
                ac justo fermentum nibh rhoncus consectetur. Vestibulum euismod
                mi et diam rhoncus sagittis. Nulla lorem turpis, placerat
                posuere massa et, viverra vestibulum libero.
              </p>
            </section>
            <section>
              <h5>Nullam in pellentesque tortor.</h5>
              <p>
                Mauris tempus consequat ante, id varius leo. Nunc condimentum
                consequat turpis, porta tempus enim interdum at. Etiam tincidunt
                quam quis ultrices viverra. Ut lacus nunc, scelerisque id
                euismod at, elementum eget arcu. Nullam vehicula, erat non
                mattis elementum, massa felis? Nunc eu rhoncus orci. Morbi
                dignissim augue enim, at vehicula tellus pellentesque id.
                Curabitur placerat, tellus vel rutrum aliquet, tellus nulla
                ultricies metus, ut sagittis erat libero vel est. Aenean
                eleifend rhoncus nulla, eget vestibulum eros bibendum a. Nam
                diam nisi, molestie nec orci id, hendrerit venenatis metus. Duis
                vitae pharetra mi. Nullam vehicula, erat non mattis elementum,
                massa felis fermentum nibh, quis dignissim orci ex suscipit
                arcu. Ut quis sem imperdiet, pellentesque nisl quis, hendrerit
                tortor. Phasellus ac suscipit tellus.
              </p>

              <p>
                Duis sit amet congue tellus, et aliquet velit. Donec pulvinar
                felis ut odio accumsan aliquet. Duis non turpis et ligula
                aliquam maximus. Etiam pellentesque, tortor nec suscipit luctus,
                quam nunc dapibus ex, eu iaculis odio mi sed felis. Sed augue
                enim, mollis sed placerat quis, feugiat at lorem. Pellentesque
                fringilla, erat non porttitor dapibus, lectus nisl ullamcorper
                ipsum, vitae feugiat lorem odio vel urna. Fusce in volutpat
                augue, et rhoncus risus. Interdum et malesuada fames ac ante
                ipsum primis in faucibus. Cras lobortis facilisis leo a
                volutpat. Phasellus magna metus, ultricies quis tempor vitae,
                fermentum eget neque. Vivamus aliquam vitae libero a tempus.
                Aenean viverra sapien eu diam auctor eleifend. Morbi molestie ut
                elit in fermentum. Interdum et malesuada fames Morbi non libero
                in diam laoreet tincidunt at eu nisi. Curabitur tempus quam quis
                nisi fermentum aliquam. Phasellus sit amet massa ut nisl
                pulvinar pretium. Phasellus id scelerisque est. Donec eu sem id
                quam sodales mattis. In hac habitasse platea dictumst. Cras eget
                molestie leo. Ut quis ipsum mi. Pellentesque scelerisque a dolor
                et semper. Nunc auctor volutpat orci ac dapibus. Etiam aliquam
                iaculis eros, ac porta ante tristique eu. Donec ac odio ut lacus
                malesuada maximus. Mauris at leo eu nibh mollis eleifend. Mauris
                imperdiet orci eget dolor pharetra tempor. Curabitur odio risus,
                maximus non nulla quis, hendrerit tincidunt neque. Aliquam erat
                volutpat. Suspendisse nec mauris commodo dui ultrices fermentum.
                Praesent fringilla, ex eget sodales elementum, neque nibh
                interdum lacus, vitae placerat enim turpis quis lectus.
                Phasellus finibus dictum elit non ornare. Nulla ligula nulla,
                consectetur eu aliquet in, condimentum a risus. Sed eu nisi eu
                neque congue molestie. Sed vitae accumsan mi. Mauris posuere ut
                ipsum quis cursus. Nulla iaculis est nunc, ut sodales ligula
                bibendum ut. In euismod tempor est et accumsan.
              </p>
            </section>
          </Col>
        </Col>
      </div>
    </div>
  );
}
