import { useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import "./LoadedModal.scss";

export function useLoaderModal(title: string, message: string) {
  const callbackRef = useRef<() => void>(() => {});

  const [showModal, hideModal] = useModal(() => <LoaderModal title={title} message={message} onHide={hideModal} />);

  return (callback: () => void) => {
    callbackRef.current = callback;
    showModal();
  };
}

type ConfirmModalProps = {
  title: string;
  message: string;
  onHide: () => void;
};
function LoaderModal({ title, message, onHide }: ConfirmModalProps) {
  const { t } = useTranslation();
  return (
    <Modal backdrop="static" className="loader-modal" onHide={onHide} show centered keyboard={false}>
      <Modal.Header className="border-0">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content">
          {message}
          <Spinner animation="border" variant="default" title={t("Waiting")} className="spinner mt-5 mb-5" />
        </div>
      </Modal.Body>
    </Modal>
  );
}
