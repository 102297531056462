import { useMemo } from "react";
import { TFunction } from "react-i18next";
import moment from "moment";

const PARTICIPANT_STATUS_WAITING = 0;
const PARTICIPANT_STATUS_RUNNING = 1;
const RACE_STATUS_STARTING = "starting";
const RACE_STATUS_LIVE = "live";
const RACE_STATUS_ENDED = "ended";
const RACE_STATUS_UNKNOWN = "unknown";

export type ParticipantStatus = {
  isActive?: boolean;
  isFinished?: boolean;
  labelBeforeStart: string;
  labelLive: string;
  labelAfterEnd: string;
};
function useParticipantStatuses(t: TFunction) {
  return useMemo(
    () =>
      ({
        [PARTICIPANT_STATUS_WAITING]: {
          ...labels(t("Participating"), t("Did not start"), t("Did not finish")),
        },
        [PARTICIPANT_STATUS_RUNNING]: {
          ...labels(t("Ready"), t("Running"), t("Did not finish")),
          isActive: true,
        },
        2: {
          ...labels(t("Paused"), t("Paused"), t("Did not finish")),
          isActive: true,
        },
        3: {
          ...labels(t("Stopped"), t("Stopped"), t("Stopped")),
          isFinished: true,
        },
        4: {
          ...labels(t("No Signal"), t("No Signal"), t("Did not finish")),
          isActive: true,
        },
        5: {
          ...labels(t("No GPS"), t("No GPS"), t("Did not finish")),
          isActive: true,
        },
        31: {
          ...labels(t("Finished"), t("Finished"), t("Finished")),
          isFinished: true,
        }, // by going near the finish point
        32: {
          ...labels(t("Finished"), t("Finished"), t("Finished")),
          isFinished: true,
        }, // by completing the targetDistance and/or targetElevation
        60: { ...labels(t("Aborted"), t("Aborted"), t("Aborted")) },
        61: {
          ...labels(t("Disqualified"), t("Disqualified"), t("Disqualified")),
        },
        62: { ...labels("", t("Did not finish"), t("Did not finish")) }, // user never completed the race nor pressed stop until the race time ended
        100: {
          ...labels(t("Unknown"), t("Unknown"), t("Unknown")),
          isActive: true,
        },
        undefined: { ...labels(t("Unknown"), t("Unknown"), t("Unknown")) },
      } as {
        [key: number]: ParticipantStatus;
      }),
    [t]
  );
}

function getStatusLabel(status: ParticipantStatus, raceStatus: string) {
  switch (raceStatus) {
    case RACE_STATUS_STARTING:
      return status.labelBeforeStart;
    case RACE_STATUS_LIVE:
      return status.labelLive;
    case RACE_STATUS_ENDED:
      return status.labelAfterEnd;
    default:
      return "";
  }
}

function getRaceStatus(race: any) {
  if (!race) {
    return RACE_STATUS_UNKNOWN;
  }

  const currentTime = moment();
  const startTime = moment.unix(race.startTime);
  const endTime = moment.unix(race.endTime);

  if (currentTime.isBefore(startTime)) {
    return RACE_STATUS_STARTING;
  } else if (currentTime.isBefore(endTime)) {
    return RACE_STATUS_LIVE;
  } else {
    return RACE_STATUS_ENDED;
  }
}

function labels(beforeStart: string, live: string, afterEnd: string) {
  return {
    labelBeforeStart: beforeStart,
    labelLive: live,
    labelAfterEnd: afterEnd,
  };
}

export {
  getStatusLabel,
  getRaceStatus,
  useParticipantStatuses,
  PARTICIPANT_STATUS_WAITING,
  PARTICIPANT_STATUS_RUNNING,
  RACE_STATUS_STARTING,
  RACE_STATUS_LIVE,
  RACE_STATUS_ENDED,
  RACE_STATUS_UNKNOWN,
};
