import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { AuthLayout } from "../AuthLayout/AuthLayout";
import { useApi } from "../../../shared/useApi";
import { reload } from "../../../store/global/actions";
import "./SignIn.scss";

type LoginFormData = { email: string; password: string };
const initialFormData: LoginFormData = { email: "", password: "" };

export function SignIn() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { post, response } = useApi("/login");
  const [loginError, setLoginError] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormData,
    validationSchema: createValidationSchema(t),
    onSubmit: login,
  });

  return (
    <AuthLayout className="sign-in">
      <>
        <div className="header">
          <h2 className="title">{t("Sign in")}</h2>
          <div className="or-sign-up">
            <span className="or">{t("or")}</span>
            <Link to="/sign-up" className="sign-up-link">
              {t("create an account")}
            </Link>
          </div>
        </div>
        <FormikProvider value={formik}>
          {loginError && <Alert variant="danger">{t("Username or password invalid")}</Alert>}
          <Form className="sign-in-form" onSubmit={formik.handleSubmit}>
            <InputControl type="text" name="email" label={t("Email")} placeholder={t("Your email")}></InputControl>
            <InputControl type="password" name="password" label={t("Password")} placeholder={t("Your password")}></InputControl>
            <Button type="submit" size="sm" className="btn-block mt-4">
              {t("Sign in")}
            </Button>
            <Link to="/forgot-passwod" className="forgot-password-link">
              {t("Forgot your password?")}
            </Link>
          </Form>
        </FormikProvider>
      </>
    </AuthLayout>
  );

  async function login(loginData: LoginFormData) {
    setLoginError(false);

    await post("", {
      email: loginData.email,
      password: loginData.password,
    });

    if (response.ok) {
      dispatch(reload());
      history.push("/");
    } else {
      setLoginError(true);
    }
  }
}

function createValidationSchema(t: TFunction) {
  return Yup.object({
    email: Yup.string().required(t("Email is required")),
    password: Yup.string().required(t("Password is required")),
  });
}
