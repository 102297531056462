import React, {MouseEventHandler} from "react";
import iconArrowLeft from "../../../assets/svg/iconArrowLeft.svg";
import iconArrowRight from "../../../assets/svg/iconArrowRight.svg";
import "./SliderThumb.scss";

const SliderThumb = ({
                       props,
                       value,
                       index,
                       isTheOnlyThumb,
                       isTheLastThumb,
                       rangeEnd,
                       firstColor,
                       prevColor,
                       color,
                       lastColor,
                       onOverlayMouseEnter,
                       onOverlayMouseLeave
                     }: SliderThumbProps) => (
  <button
    {...props}
    className={`slider-thumb slider-thumb-${index}${value + 1 > rangeEnd ? " hidden" : ""}`}
    type="button"
    onMouseEnter={onOverlayMouseEnter}
    onMouseLeave={onOverlayMouseLeave}>
    <p className="slider-thumb-label">
      {value}
    </p>
    <div className="slider-thumb-inner">
      <div className="prev-range-border" style={{background: isTheLastThumb ? lastColor : firstColor || prevColor}}>
        <img src={iconArrowLeft} alt=""/>
      </div>
      <div className="next-range-border" style={{background: isTheOnlyThumb ? firstColor : lastColor || color}}>
        <img src={iconArrowRight} alt=""/>
      </div>
    </div>
    <p className="slider-thumb-label">
      {value + 1}
    </p>
  </button>
);

type SliderThumbProps = {
  props: any;
  value: number;
  index: number;
  isTheOnlyThumb: boolean;
  isTheLastThumb: boolean;
  rangeEnd: number;
  firstColor: string;
  prevColor: string;
  color: string;
  lastColor: string;
  onOverlayMouseEnter: MouseEventHandler<HTMLButtonElement>;
  onOverlayMouseLeave: MouseEventHandler<HTMLButtonElement>;
}

export default SliderThumb;
