import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";

export function useConfirmationModal(title: string, message: string) {
  const callbackRef = useRef<() => void>(() => {});

  const [showModal, hideModal] = useModal(() => (
    <ConfirmModal
      title={title}
      message={message}
      onHide={hideModal}
      onYes={callbackRef.current}
    ></ConfirmModal>
  ));

  return (callback: () => void) => {
    callbackRef.current = callback;
    showModal();
  };
}

type ConfirmModalProps = {
  title: string;
  message: string;
  onHide: () => void;
  onYes: () => void;
};
function ConfirmModal({ title, message, onHide, onYes }: ConfirmModalProps) {
  const { t } = useTranslation();
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-gray" onClick={onHide}>
          {t("No")}
        </Button>
        <Button
          type="submit"
          size="sm"
          variant="primary"
          onClick={() => {
            onHide();
            onYes();
          }}
        >
          {t("Yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
