import { RELOAD, SET_APP_INFO, SET_EVENT_ID, SET_IS_CART_PAGE, SET_TRAIL_ID } from "./types";

const setAppInfo = (loaded: boolean, loggedIn: boolean, user: any) => ({
  type: SET_APP_INFO,
  payload: {
    loaded,
    loggedIn,
    user,
  },
});
const reload = () => ({
  type: RELOAD,
});
const setIsCartPage = (isCartPage: boolean) => ({
  type: SET_IS_CART_PAGE,
  payload: {
    isCartPage,
  },
});
const setEventId = (eventId: number | null) => ({
  type: SET_EVENT_ID,
  payload: {
    eventId,
  },
});
const setTrailId = (trailId: number | null) => ({
  type: SET_TRAIL_ID,
  payload: {
    trailId,
  },
});

export { setAppInfo, reload, setIsCartPage, setEventId, setTrailId };
