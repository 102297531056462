import { ADD_GPX_TO_PARTICIPANT, REMOVE_GPX_FROM_PARTICIPANT, SET_PARTICIPANTS_TO_PURCHASE, HIDE_PRICE_NOTIFICATION, SET_RELOAD_RESULTS } from "./types";
import { resultsInitialState } from "./initial-state";
import { Action } from "../../../../store/interfaces";

export default function reducer(state = resultsInitialState, action: Action) {
  switch (action.type) {
    case ADD_GPX_TO_PARTICIPANT:
    case REMOVE_GPX_FROM_PARTICIPANT:
    case SET_PARTICIPANTS_TO_PURCHASE: {
      const { participantsToPurchase } = action.payload;
      return { ...state, participantsToPurchase };
    }
    case HIDE_PRICE_NOTIFICATION: {
      const { notificationHidden } = action.payload;
      return { ...state, notificationHidden };
    }
    case SET_RELOAD_RESULTS: {
      const { reload } = action.payload;
      return { ...state, reload };
    }
    default:
      return state;
  }
}
