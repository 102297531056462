import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../utils";
import "./FormToolbar.scss";

type FormToolbarProps = {
  className?: string;
  lastUpdateDate: string;
  saving?: boolean;
  saved?: boolean;
};
export function FormToolbar({
  className,
  lastUpdateDate,
  saving,
  saved,
}: FormToolbarProps) {
  const { t } = useTranslation();

  return (
    <div className={`form-toolbar d-flex align-items-center ${className}`}>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="info-icon"
      ></FontAwesomeIcon>
      <span className="info">{`${t("Last update")}: ${
        lastUpdateDate ? formatDateTime(lastUpdateDate, t) : "-"
      }`}</span>

      <Button
        variant="primary"
        size="sm"
        disabled={saving}
        className={`ml-auto save-btn btn-${
          saving ? "saving" : saved ? "saved" : "save"
        }`}
        type="submit"
      >
        <FontAwesomeIcon
          className="button-icon"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
        <div>
          <span
            className={`save-btn-label ${
              !saved && !saving ? "label-active" : ""
            }`}
          >
            {t("Save")}
          </span>
          <span className={`save-btn-label ${saving ? "label-active" : ""}`}>
            {t("Saving...")}
          </span>
          <span className={`save-btn-label ${saved ? "label-active" : ""}`}>
            {t("Saved")}
          </span>
        </div>
      </Button>
    </div>
  );
}
