import { cartInitialState } from "./initial-state";
import { REMOVE_ITEM_FROM_CART, SET_CART_ITEMS, SET_PAY_PAL_PAYMENT_STATUS } from "./types";
import { Action } from "../../../store/interfaces";

export default function reducer(state = cartInitialState, action: Action) {
  switch (action.type) {
    case REMOVE_ITEM_FROM_CART:
    case SET_CART_ITEMS: {
      const { items } = action.payload;
      return { ...state, items };
    }
    case SET_PAY_PAL_PAYMENT_STATUS: {
      const { payPalPaymentInProcess } = action.payload;
      return { ...state, payPalPaymentInProcess };
    }
    default:
      return state;
  }
}
