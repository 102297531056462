import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import * as Yup from "yup";
// import transformRotate from "@turf/transform-rotate";
// import bboxPolygon from "@turf/bbox-polygon";
// import {getCoords} from "@turf/invariant";
import angle from "@turf/angle";
import MapOverlay from "../MapOverlay";
import ImageMarker from "../ImageMarker/ImageMarker";
import {ManagedForm} from "../../../../shared/form/Form/ManagedForm";
import {FileControl} from "../../../../shared/form/controls/FileControl/FileControl";
import {FIRST_RANGE_COLOR, LAST_RANGE_COLOR} from "../../../../shared/utils";
import "./MapOverlayModal.scss";

type MapOverlayModalProps = {
  onHide: () => void;
  onSave: (overlayImage: File, overlayCoordinates: number[][]) => void;
  show: boolean;
  previousOverlayCoordinates: number[][];
  previousOverlay: string;
  trailStart?: number[];
  trailFinish?: number[];
};
type ImagePoints = { x: number, y: number, color: string }[];
type MapPoints = { lat: number, lng: number, color: string }[];

const steps = ["first", "second", "third", "fourth", "fifth", "sixth"];
const stepsDescription = {
  [steps[0]]: "Upload an image",
  [steps[1]]: "Choose the first point on the image",
  [steps[2]]: "Find the first point on the map",
  [steps[3]]: "Choose the second point on the image",
  [steps[4]]: "Find the second point on the map",
  [steps[5]]: "Save the result or manually move overlay corners",
}
const imageSteps = [1, 3];
const mapSteps = [2, 4];
const colors = [FIRST_RANGE_COLOR, LAST_RANGE_COLOR]
const MARKER_HALF_SIZE = 40;
const MIN_SCALE = 0.5;
const MAX_SCALE = 5;

export function MapOverlayModal({show, onHide, onSave, previousOverlayCoordinates, previousOverlay, trailStart, trailFinish}: MapOverlayModalProps) {
  const {t} = useTranslation();
  const previewContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const transformerRef = useRef() as any;
  const imgRef = useRef() as any;
  const [currentStep, setCurrentStep] = useState(0);
  const [overlayFile, setOverlayFile] = useState<any>();
  const [imageUrl, setImageUrl] = useState("");
  const [cropperImageUrl, setCropperImageUrl] = useState("");
  const [originalImageSize, setOriginalImageSize] = useState<{ width: number, height: number, aspectRatio: number }>();
  const [minimumScale, setMinimumScale] = useState(MIN_SCALE);
  const [previewIsDragged, setPreviewIsDragged] = useState(false);
  const [pointsOnMap, setPointsOnMap] = useState<MapPoints>([]);
  const [pointsOnImage, setPointsOnImage] = useState<ImagePoints>([]);
  const [overlayCoordinates, setOverlayCoordinates] = useState<number[][]>([]);
  
  useEffect(() => {
    if (previewContainerRef.current && originalImageSize) {
      let scaleCoefficient;
      const widthCoefficient = previewContainerRef.current.clientWidth / originalImageSize.width;
      const heightCoefficient = previewContainerRef.current.clientHeight / originalImageSize.height;
      
      if (widthCoefficient < heightCoefficient) {
        scaleCoefficient = widthCoefficient;
      } else {
        scaleCoefficient = heightCoefficient;
      }
      
      setMinimumScale(scaleCoefficient);
      transformerRef.current.centerView(scaleCoefficient);
    }
  }, [previewContainerRef, originalImageSize]);
  
  useEffect(() => {
    const imageHasTwoPoints = pointsOnImage.length === 2 && pointsOnImage.every(({x, y}) => x != null && y != null);
    const mapHasTwoPoints = pointsOnMap.length === 2 && pointsOnMap.every(({lat, lng}) => lat != null && lng != null);
    
    if (imageHasTwoPoints && mapHasTwoPoints && originalImageSize) {
      const points: any = {};
      pointsOnImage.forEach(({x, y}, index) => {
        points[steps[index]] = {image: {x, y}, map: {lat: pointsOnMap[index].lat, lng: pointsOnMap[index].lng}};
      });
      
      const firstPointOnImage = points.first.image;
      const firstPointOnMap = points.first.map;
      const secondPointOnImage = points.second.image;
      const secondPointOnMap = points.second.map;
      
      const imageAngleInDegrees = Math.atan2(secondPointOnImage.y - firstPointOnImage.y, secondPointOnImage.x - firstPointOnImage.x) * 180 / Math.PI;
      let mapAngleInDegrees = angle([secondPointOnMap.lng - 0.1, secondPointOnMap.lat], [secondPointOnMap.lng, secondPointOnMap.lat], [firstPointOnMap.lng, firstPointOnMap.lat]);
      
      if (secondPointOnMap.lat > firstPointOnMap.lat) {
        if (secondPointOnMap.lng < firstPointOnMap.lng) {
          mapAngleInDegrees = -360 + mapAngleInDegrees;
        } else if (secondPointOnMap.lng > firstPointOnMap.lng) {
          mapAngleInDegrees *= -1;
        }
      }
      
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')!
      var img = new Image();
      img.onload = function() {
        // Input data
        const alpha = (mapAngleInDegrees - imageAngleInDegrees) / 180 * Math.PI; // In radians

        var rotatedPoint = rotatePoint(firstPointOnImage, alpha, img.width, img.height)
        firstPointOnImage.x = rotatedPoint.x
        firstPointOnImage.y = rotatedPoint.y

        rotatedPoint = rotatePoint(secondPointOnImage, alpha, img.width, img.height)
        secondPointOnImage.x = rotatedPoint.x
        secondPointOnImage.y = rotatedPoint.y

        // Calculate sizes
        
        var rotatedWidth = Math.abs(img.width * Math.cos(alpha)) + Math.abs(img.height * Math.sin(alpha))
        var rotatedHeight = Math.abs(img.width * Math.sin(alpha)) + Math.abs(img.height * Math.cos(alpha))

        canvas.width = rotatedWidth
        canvas.height = rotatedHeight

        // Draw

        ctx.translate(rotatedWidth / 2, rotatedHeight / 2)
        ctx.rotate(alpha)
        ctx.drawImage(img, -img.width / 2, -img.height / 2)

        // Export

        canvas.toBlob((blob: any) => {
            let pointsOnImageDelta = {x: secondPointOnImage.x - firstPointOnImage.x, y: secondPointOnImage.y - firstPointOnImage.y};
            let pointsOnMapDelta = {lng: +(secondPointOnMap.lng - firstPointOnMap.lng), lat: +(secondPointOnMap.lat - firstPointOnMap.lat)};
            
            const xRatio = Math.abs(pointsOnMapDelta.lat / pointsOnImageDelta.y)
            const yRatio = Math.abs(pointsOnMapDelta.lng / pointsOnImageDelta.x)

            // debugger;

            // Derivate image corners

            // TL corner
            const topLeft = {
              lng: firstPointOnMap.lng + (0 - firstPointOnImage.x) * yRatio,
              lat: firstPointOnMap.lat - (0 - firstPointOnImage.y) * xRatio
            }
            // TR corner
            const topRight = {
              lng: firstPointOnMap.lng + (rotatedWidth - firstPointOnImage.x) * yRatio,
              lat: firstPointOnMap.lat - (0 - firstPointOnImage.y) * xRatio
            }
            // BR corner
            const bottomRight = {
              lng: firstPointOnMap.lng + (rotatedWidth - firstPointOnImage.x) * yRatio,
              lat: firstPointOnMap.lat - (rotatedHeight - firstPointOnImage.y) * xRatio
            }
            // BL corner
            const bottomLeft = {
              lng: firstPointOnMap.lng + (0 - firstPointOnImage.x) * yRatio,
              lat: firstPointOnMap.lat - (rotatedHeight - firstPointOnImage.y) * xRatio
            }
 
            const coordinates = [
              [topLeft.lng, topLeft.lat],
              [topRight.lng, topRight.lat],
              [bottomRight.lng, bottomRight.lat],
              [bottomLeft.lng, bottomLeft.lat]
            ];

            // debugger

            const imgFile = new File([blob], overlayFile.name, {type: overlayFile.type});
            const objectURL = URL.createObjectURL(blob);
            
            console.log(objectURL)
            setOverlayFile(imgFile);
            setCropperImageUrl(objectURL);

            setOverlayCoordinates(coordinates);
        })
      };

      img.src = imageUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointsOnMap, pointsOnImage, originalImageSize]);

  const onMapClick = (lat: number, lng: number) => {
    // setPointsOnImage([{x: 1012, y: 226, color: FIRST_RANGE_COLOR}, {x: 1083, y: 1815, color: LAST_RANGE_COLOR}])
    // setPointsOnMap([{lat: 50.089762436466415, lng: 14.411557831602956, color: FIRST_RANGE_COLOR}, {lat: 50.07601327172491, lng: 14.43494066941372, color: LAST_RANGE_COLOR}])
    // setCurrentStep(4);

    if (!mapSteps.includes(currentStep)) {
      return null;
    }
    setPointsOnMap([...pointsOnMap, {lng, lat, color: colors[pointsOnMap.length]}]);
    increaseStep();
  };
  
  const onImageClick = (event: any) => {
    if (!imageSteps.includes(currentStep) || previewIsDragged) {
      return null;
    }
    
    const transformedComponent = document.getElementsByClassName("react-transform-component")[0];
    const currentScale = transformerRef.current.state.scale;
    const {left, top} = transformedComponent.getBoundingClientRect();
    const {clientX, clientY} = event;
    const x = (clientX - left) / currentScale;
    const y = (clientY - top) / currentScale;
    
    setPointsOnImage([...pointsOnImage, {x, y, color: colors[pointsOnImage.length]}]);
    increaseStep();
  };
  
  const onImageMarkersReset = () => {
    if (currentStep <= 4) {
      setCurrentStep(1);
      setPointsOnMap([]);
      setPointsOnImage([]);
    }
  };
  
  const onAfterUpload = (source: File) => {
    onImageMarkersReset();
    const url = URL.createObjectURL(source);
    const img = new Image();
    img.src = url;
    img.onload = () => setOriginalImageSize({width: img.width, height: img.height, aspectRatio: img.width / img.height});
    setCurrentStep(1);
    setImageUrl(url);
    setOverlayFile(source);
  };
  
  const increaseStep = () => {
    setCurrentStep(currentStep + 1);
  };
  
  const onPreviewPanStart = () => {
    setPreviewIsDragged(true);
  };
  
  const onPreviewPanStop = () => {
    setPreviewIsDragged(false);
  };
  
  const onClose = () => {
    setCurrentStep(0);
    setOriginalImageSize(undefined);
    setPointsOnMap([]);
    setPointsOnImage([]);
    setOverlayCoordinates([]);
    setImageUrl("");
    setOverlayFile(undefined);
  };
  
  const createValidationSchema = (t: TFunction) => {
    return Yup.object({
      source: Yup.object().required(t("Source image is required")),
    });
  };
  
  const _ = async (formData: any) => null;
  
  useEffect(() => {
    if (!imgRef.current) {
      return;
    }
    
  }, [imgRef]);

  const rotatePoint = (point: any, alpha: number, imgWidth: number, imgHeight: number) => {
    const pointX = point.x;
    const pointY = point.y;

    // The angle of the point from the image's (0, 0) in radians
    const beta = Math.atan(pointY / pointX);
    // The distance of the point from the image's (0, 0)
    const radius = Math.sqrt(pointX * pointX + pointY * pointY);
    // The original image's (0, 0) after rotation, currently only works in (-90, 90) degrees interval
    const offsetX = Math.max(0, Math.sin(alpha) * imgHeight);
    const offsetY = Math.max(0, Math.cos(Math.PI / 2 + alpha) * imgWidth);

    console.log("Angle of selected point:", beta / Math.PI * 180);
    console.log("Radius:", radius);
    console.log("Enveloped offset:", offsetX, offsetY);

    // The given point in the enveloped image after rotation:
    const x = offsetX + Math.cos(alpha + beta) * radius;
    const y = offsetY + Math.sin(alpha + beta) * radius;
    console.log("Final position:", x, y);
    return {x: x, y: y}
  }
  
  return (
    <Modal show={show} onHide={() => {
      onClose();
      onHide();
    }} size="xl">
      <Modal.Header closeButton>
        <Container>
          <Modal.Title>
            {t("Add overlay")}
          </Modal.Title>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row noGutters>
            <p><span className="step">Step #{currentStep + 1}:</span> {stepsDescription[steps[currentStep]]}</p>
          </Row>
          <Row noGutters>
            <Col md={6} className='map-container'>
              <MapOverlay
                trailStart={trailStart}
                trailFinish={trailFinish}
                hidePreviousOverlay={!!imageUrl}
                imageUrl={cropperImageUrl}
                markers={pointsOnMap}
                overlayCoordinates={overlayCoordinates.length === 4 ? overlayCoordinates : undefined}
                previousOverlayCoordinates={previousOverlayCoordinates}
                previousOverlay={previousOverlay}
                onClick={onMapClick}
              />
            </Col>
            <Col md={6}>
              <ManagedForm
                values={{}}
                validationSchema={createValidationSchema(t)}
                onSubmit={_}
              >
                <div className="image-column">
                  <FileControl
                    name="source"
                    label={t("Overlay image")}
                    placeholder={t("Overlay image")}
                    accept=".jpg,.jpeg,.png,.gif"
                    onAfterUpload={onAfterUpload}
                    resetValue={currentStep === 0}
                  />
                  <div
                    ref={previewContainerRef}
                    className={imageUrl ? 'image-preview-container' : 'no-preview'}
                    onMouseDown={onPreviewPanStop}
                    onMouseMove={onPreviewPanStart}
                    onMouseUp={onImageClick}
                  >
                    {imageUrl && (
                      <TransformWrapper
                        ref={transformerRef}
                        initialScale={minimumScale}
                        minScale={minimumScale}
                        maxScale={MAX_SCALE}
                        centerOnInit
                      >
                        <TransformComponent>
                          <div
                            className="image-preview"
                            style={{
                              width: originalImageSize?.width,
                              height: originalImageSize?.height,
                              backgroundImage: `url(${imageUrl})`,
                            }}
                          >
                            {!!pointsOnImage && !!pointsOnImage.length && pointsOnImage.map(({x, y, color}) => (
                              <ImageMarker key={color} color={color} x={x - MARKER_HALF_SIZE} y={y - MARKER_HALF_SIZE}/>
                            ))}
                          </div>
                        </TransformComponent>
                      </TransformWrapper>
                    )}
                  </div>
                </div>
              </ManagedForm>
            </Col>
          </Row>
        </Container>
        <img src={imageUrl} alt="" style={{display: "none"}} width={0} height={0} ref={imgRef}/>
      </Modal.Body>
      <Modal.Footer className="overlay-modal-footer">
        <div>
          <Button size="sm" variant="outline-danger" className="mr-3" disabled={currentStep === 0} onClick={onClose}>
            {t("Reset map and overlay")}
          </Button>
          <Button size="sm" variant="warning" disabled={currentStep <= 1 || currentStep > 4} onClick={onImageMarkersReset}>
            {t("Reset markers")}
          </Button>
        </div>
        <div>
          <Button size="sm" variant="outline-dark" className="mr-3" onClick={() => {
            onHide();
            onClose();
          }}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="primary"
            disabled={currentStep !== 5 && overlayCoordinates.length === 4}
            onClick={() => {
              onSave(overlayFile, overlayCoordinates);
              onClose();
            }}
          >
            {t("Save")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
