import { RELOAD, SET_APP_INFO, SET_EVENT_ID, SET_IS_CART_PAGE, SET_TRAIL_ID } from "./types";
import appInitialState from "./initial-state";
import { Action } from "../interfaces";

export default function reducer(state = appInitialState, action: Action) {
  switch (action.type) {
    case SET_APP_INFO: {
      const { loaded, loggedIn, user } = action.payload;
      return { ...state, loaded, loggedIn, user };
    }
    case SET_IS_CART_PAGE: {
      const { isCartPage } = action.payload;
      return { ...state, isCartPage };
    }
    case SET_EVENT_ID: {
      const { eventId } = action.payload;
      return { ...state, eventId };
    }
    case SET_TRAIL_ID: {
      const { trailId } = action.payload;
      return { ...state, trailId };
    }
    case RELOAD:
    default:
      return state;
  }
}
