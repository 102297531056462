import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { EventTabContentLayout } from "../event/shared/EventTabContentLayout/EventContentTabLayout";
import { Placeholder } from "../../shared/Placeholder/Placeholder";
import { simpleTextRenderer, Table } from "../../shared/Table/Table";
import PayPalCheckout from "../event/PayPal/PayPal";
import { ReactComponent as IconTrash } from "../../assets/svg/iconTrash.svg";
import { ONE_GPX_COST } from "../../helpers/consts";
import { removeGpxFromParticipant } from "../event/Results/store/actions";
import { loadCartItems, setCartItems } from "./store/actions";
import { setIsCartPage } from "../../store/global/actions";
import { selectCartItems } from "./store/selectors";
import "./Cart.scss";

export function Cart() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  useEffect(() => {
    dispatch(setIsCartPage(true));
    return () => {
      dispatch(setIsCartPage(false));
    };
  }, [dispatch]);

  const onItemRemove = (d: any) => {
    dispatch(removeGpxFromParticipant(d.eventId, d.activityId));
    dispatch(setCartItems(loadCartItems()));
  };

  return (
    <div className="d-flex justify-content-center trail-nav-page">
      <Col sm={8} lg={7}>
        <Row>
          <Col sm={12}>
            <EventTabContentLayout title={t("Checkout")} className="trail-nav-content" headerClassName="h3" width={12} hideButtons>
              {!!cartItems.length && (
                <>
                  <Table
                    columns={[
                      { header: t("Title"), render: simpleTextRenderer("title") },
                      { header: t("Price"), render: () => `€${ONE_GPX_COST}` },
                      {
                        header: "",
                        render: (d) => (
                          <Button size="sm" variant="outline-light" className="ml-2 square-btn" onClick={() => onItemRemove(d)}>
                            <IconTrash />
                          </Button>
                        ),
                      },
                    ]}
                    data={cartItems}
                    placeholderTitle={t("Cart is empty.")}
                    placeholderDescription={t("Here you will find the purchased items.")}
                    pagination={{
                      hasPages: false,
                      hasPreviousPage: false,
                      hasNextPage: false,
                      pageNumbers: [],
                      currentPage: 0,
                      onPreviousPage: () => null,
                      onPageSelect: () => null,
                      onNextPage: () => null,
                    }}
                    dataKeyProperty="activityId"
                    className="checkout-table"
                  />
                  <div className="paypal-btns-container mt-5">
                    <PayPalCheckout />
                  </div>
                </>
              )}
            </EventTabContentLayout>
          </Col>
        </Row>
        {!cartItems.length && <Placeholder title={t("Cart is empty")} description={t("You can add some items on the Results tab.")} />}
      </Col>
    </div>
  );
}
