import React, { ChangeEvent } from "react";
import { FieldArray } from "formik";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InputControl } from "../InputControl/InputControl";
import "./InputArrayControl.scss";

type InputControlProps = {
  values: { [key: string]: any };
  maxItems: number;
  name: string;
  label: string;
  placeholder: string;
  type: string;
  step?: number;
  min?: number;
  max?: number;
  popUpError?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  readonly?: boolean;
};

export function InputArrayControl({ values, maxItems, name, label, placeholder, type, min, step, max, popUpError, onChange, readonly }: InputControlProps) {
  const { t } = useTranslation();

  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          const onAddItemClick = () => {
            if (values[name].length !== maxItems) {
              arrayHelpers.insert(values[name].length, "");
            }
          };
          const onRemoveItemClick = (index: number) => {
            if (values[name].length > 1) {
              arrayHelpers.remove(index);
            }
          };
          return (
            <div>
              {!!values[name].length &&
                values[name].map((val: any, index: number) => (
                  <div key={index} className="array-field-container">
                    <InputControl
                      type={type}
                      name={`${name}.${index}`}
                      placeholder={placeholder}
                      onChange={onChange}
                      min={min}
                      step={step}
                      max={max}
                      popUpError={popUpError}
                      readonly={readonly}
                      removeBtnAriaLabel={t("Remove")}
                      onRemove={() => onRemoveItemClick(index)}
                      withRemoveBtn={values[name].length > 1}
                    />
                  </div>
                ))}
              {values[name].length !== maxItems && (
                <Button type="button" size="sm" variant="dark" onClick={onAddItemClick}>
                  {t("Add more")}
                </Button>
              )}
            </div>
          );
        }}
      />
    </Form.Group>
  );
}
