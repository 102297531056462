import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table as BSTable } from "react-bootstrap";
import { Placeholder } from "../Placeholder/Placeholder";
import "./Table.scss";

type TableProps = {
  columns: {
    header: string;
    render: (data: any, rowIndex: number) => React.ReactElement | string | number;
  }[];
  loadError?: { error: string; details: string };
  data: any[] | null;
  dataKeyProperty: string;
  placeholderTitle: string;
  placeholderDescription: string;
  onSelectRow?: (rowData: any) => void;
  pagination: {
    hasPages: boolean;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    pageNumbers: number[];
    currentPage: number;
    onPreviousPage: () => void;
    onPageSelect: (page: number) => void;
    onNextPage: () => void;
  };
  className?: string;
};

export function Table({ columns, loadError, data, dataKeyProperty, placeholderTitle, placeholderDescription, pagination, onSelectRow, className }: TableProps) {
  const columnCount = columns.length;
  const { hasPages, hasPreviousPage, hasNextPage, currentPage, pageNumbers, onPreviousPage, onPageSelect, onNextPage } = pagination;

  return (
    <div className={`paginated-table ${className ? className : ""}`}>
      <BSTable hover responsive>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.header} className={column.header.toLowerCase()}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loadError && (
            <tr className="tr-placeholder">
              <td colSpan={columnCount}>
                <Placeholder title={loadError.error} description={loadError.details}></Placeholder>
              </td>
            </tr>
          )}
          {!loadError && data && data.length === 0 && (
            <tr className="tr-placeholder">
              <td colSpan={columnCount}>
                <Placeholder title={placeholderTitle} description={placeholderDescription}></Placeholder>
              </td>
            </tr>
          )}
          {data &&
            data.map((d, rowIndex) => (
              <tr className={onSelectRow ? "selectable" : ""} key={d[dataKeyProperty]} onClick={() => onSelectRow && onSelectRow(d)}>
                {columns.map((c) => (
                  <td key={c.header || d.id || d.activityId} className={c.header.toLowerCase()}>
                    {c.render(d, rowIndex)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </BSTable>
      {hasPages && (
        <div className="d-flex">
          <Pagination className="ml-auto table-pagination" size="sm">
            <Pagination.Item onClick={() => onPreviousPage()} disabled={!hasPreviousPage}>
              <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
            </Pagination.Item>
            {pageNumbers.map((p) => (
              <Pagination.Item key={p} active={p === currentPage} onClick={() => onPageSelect(p)}>
                {p}
              </Pagination.Item>
            ))}
            <Pagination.Item onClick={() => onNextPage()} disabled={!hasNextPage}>
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </Pagination.Item>
          </Pagination>
        </div>
      )}
    </div>
  );
}

export function simpleTextRenderer(column: string) {
  return (data: any) => data[column];
}
