import { useEffect } from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { FILE_REGEX } from "../../../../helpers/consts";
import "./FileControl.scss";

type FileControlProps = {
  name: string;
  label: string;
  placeholder: string;
  accept?: string;
  onAfterUpload?: (source: File) => void;
  isGpxControl?: boolean;
  resetValue?: boolean;
};

export function FileControl({ name, label, placeholder, accept, onAfterUpload, isGpxControl = false, resetValue }: FileControlProps) {
  const { t } = useTranslation();
  const [field, , { setValue, setTouched }] = useField(name);
  const fileName = field.value instanceof File ? field.value.name : field.value;

  useEffect(() => {
    if (resetValue) {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValue]);

  return (
    <Form.Group controlId={name} className="file-control">
      <Form.Label>{label}</Form.Label>
      <Form.File id={name} custom>
        <Form.File.Input
          name={name}
          accept={accept}
          onChange={(e: any) => {
            setTouched(true);
            const file = e.target?.files?.[0];

            if (!file) {
              return null;
            }

            if (file.size > 2 * 1024 * 1024) {
              console.error("File is too big!");
              return null;
            }

            if (isGpxControl) {
              if (file.name.match(FILE_REGEX)) {
                setValue(file);
                if (onAfterUpload) {
                  onAfterUpload(file);
                }
              } else {
                toastr.error(t("Error"), t("Only GPX files are allowed."));
                return null;
              }
            } else {
              setValue(file);
              if (onAfterUpload) {
                onAfterUpload(file);
              }
            }
            e.target.value = "";
          }}
        />
        <Form.File.Label className="file-control-input" data-browse={t("Select file")}>
          {!fileName && <span className="placeholder">{placeholder}</span>}
          {fileName}
        </Form.File.Label>
      </Form.File>
    </Form.Group>
  );
}
