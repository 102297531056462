import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import iconArrowTrail from '../../assets/svg/iconArrowTrail.svg';
import './SideNav.scss';
import {useEffect, useState} from "react";

type SideNavProps = {
  items: { url: string; label: string }[];
  className: string;
};

export function SideNav({items, className}: SideNavProps) {
  const [selectedKey, setSelectedKey] = useState<null | string>(null);
  const [firstRender, setFirstRender] = useState(true);
  
  useEffect(() => {
    if (firstRender && !!items.length) {
      setSelectedKey(items[0].url);
      setFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  
  return (
    <Nav className={`side-nav flex-column ${className}`}>
      {items.map(({url, label}) => (
        <LinkContainer to={url} key={url} className="side-nav-link">
          <Nav.Link onSelect={(val) => setSelectedKey(val)}>
            <span>{label}</span>
            {url === selectedKey && <img src={iconArrowTrail} alt="" width={11} height={14}/>}</Nav.Link>
        </LinkContainer>
      ))}
    </Nav>
  );
}
