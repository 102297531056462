import React from "react";
import "./ImageMarker.scss";

type DraggableMarkerProps = {
  x: number;
  y: number;
  color: string;
};
const ImageMarker = ({x, y, color}: DraggableMarkerProps) => (
  <div className="image-marker" style={{top: y, left: x}}>
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="80px" height="80px" viewBox="0 0 94 94"
         xmlSpace="preserve" fill={color}>
      <title>Marker</title>
      <path
        d="M47,94C21.084,94,0,72.916,0,47S21.084,0,47,0s47,21.084,47,47S72.916,94,47,94z M47,12.186    c-19.196,0-34.814,15.618-34.814,34.814c0,19.195,15.618,34.814,34.814,34.814c19.195,0,34.814-15.619,34.814-34.814    C81.814,27.804,66.195,12.186,47,12.186z"/>
    </svg>
  </div>
);

export default ImageMarker;
