import moment from "moment";
import momentTimezone from "moment-timezone";
import { TFunction } from "react-i18next";

const emptyChar = "\u00a0";
const FIRST_RANGE_COLOR = "#651A98";
const LAST_RANGE_COLOR = "#f8c540";

function formatDate(date: string, t: TFunction) {
  return moment(date).format(t("dateFormat"));
}

export function formatDateTime(date: string, t: TFunction) {
  return moment.utc(date).local().format(t("dateTimeFormat"));
}

function formatLocalDateTime(date: Date) {
  return moment(date).format("YYYY-MM-DDThh:mm");
}

function generateColor() {
  const hue = Math.floor(Math.random() * 100) * 137.508; // use the golden angle approximation
  return `hsl(${hue},55%,75%)`;
}

// Convert UTC to specified timezone
function localizedTime(date: Date, timezone: string): Date {
  const delta = deltaMinutes(date, timezone);
  const newDateString = moment(date).subtract(delta, "minutes").format();
  return new Date(newDateString);
}

// Convert specified timezone to UTC
function utcTime(date: Date, timezone: string): Date {
  const delta = deltaMinutes(date, timezone);
  const newDateString = moment(date).add(delta, "minutes").format();
  return new Date(newDateString);
}

// Calculate minutes difference between local and specified timezone
function deltaMinutes(date: Date, timezone: string): number {
  if (!timezone) {
    return 0;
  }

  const zone = momentTimezone.tz.zone(timezone);

  if (!zone) {
    return 0;
  }

  const offset = zone.utcOffset(date.getTime());
  const currentOffset = new Date().getTimezoneOffset();
  return offset - currentOffset;
}

export { formatDate, formatLocalDateTime, generateColor, utcTime, localizedTime, emptyChar, FIRST_RANGE_COLOR, LAST_RANGE_COLOR };
