import { SyntheticEvent } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Tabs.scss";

export type Tab = {
  url: string;
  name: string;
  disabled?: boolean;
};
type TabsProps = {
  className?: string;
  tabs: Tab[];
};
export function Tabs({ className, tabs }: TabsProps) {
  return (
    <Nav className={`app-tabs ${className}`}>
      {tabs.map((tab) => (
        <Nav.Item className="app-tab" key={tab.url}>
          <LinkContainer
            to={tab.url}
            onClick={
              tab.disabled
                ? (e: SyntheticEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            <Nav.Link className={`app-tab-link${tab.disabled ? " disabled" : ""}`}>{tab.name}</Nav.Link>
          </LinkContainer>
          <div className="active-mark"></div>
        </Nav.Item>
      ))}
    </Nav>
  );
}
