import { Suspense } from "react";
import { Provider } from "use-http";

export function withSuspense<P extends object>(
  Component: React.ComponentType<P>
): React.FunctionComponent<P> {
  return (props: P) => (
    <Provider>
      <Suspense fallback>
        <Component {...props}></Component>
      </Suspense>
    </Provider>
  );
}
