import React, { ChangeEvent, useRef } from "react";
import { useField } from "formik";
import { Button, Form, Overlay, Tooltip } from "react-bootstrap";
import iconTrash from "../../../../assets/svg/iconTrashWhite.svg";

type InputControlProps = {
  name: string;
  label?: string;
  placeholder: string;
  type: string;
  step?: number;
  min?: number;
  max?: number;
  popUpError?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  readonly?: boolean;
  withRemoveBtn?: boolean;
  onRemove?(): void;
  removeBtnAriaLabel?: string;
};

export function InputControl({
  name,
  label,
  placeholder,
  type,
  min,
  step,
  max,
  popUpError,
  onChange,
  readonly,
  withRemoveBtn,
  onRemove,
  removeBtnAriaLabel,
}: InputControlProps) {
  const [field, meta] = useField(name);
  const fieldRef = useRef(null);

  return (
    <Form.Group controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type={type}
        placeholder={placeholder}
        {...field}
        onChange={(e) => {
          let isValid = true;
          if (type === "number" && min && max) {
            e.preventDefault();
            const value = +e.target.value;
            isValid = value >= min && value <= max;
          }
          if (isValid) {
            field.onChange(e);
            onChange && onChange(e as any);
          }
        }}
        ref={fieldRef}
        min={min}
        max={max}
        value={field.value || ""}
        isInvalid={meta.touched && !!meta.error}
        step={type === "number" ? step : "any"}
        readOnly={readonly}
        className={withRemoveBtn ? "with-remove-btn" : ""}
      />
      {withRemoveBtn && (
        <Button type="button" size="sm" variant="danger" className="remove-btn" aria-label={removeBtnAriaLabel} onClick={onRemove}>
          <img src={iconTrash} alt="" />
        </Button>
      )}
      {!popUpError && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
      {popUpError && meta.touched && (
        <Overlay target={fieldRef.current} show={!!meta.error} placement="bottom">
          {(props) => (
            <Tooltip {...props} id={`tooltip-${name}`} className="error-tooltip">
              <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            </Tooltip>
          )}
        </Overlay>
      )}
    </Form.Group>
  );
}
