import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import * as Yup from "yup";
import { TrailData, TrailNavPage } from "../TrailNavPage/TrailNavPage";
import { MapOverlayModal } from "../MapOverlay/Modal/MapOverlayModal";
import { Map } from "../Map/Map";
import { InputArrayControl } from "../../../shared/form/controls/InputArrayControl/InputArrayControl";
import { DatetimeControl } from "../../../shared/form/controls/DateTimeControl/DateTimeControl";
import { SelectControl } from "../../../shared/form/controls/SelectControl/SelectControl";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { FileControl } from "../../../shared/form/controls/FileControl/FileControl";
import { Placeholder } from "../../../shared/Placeholder/Placeholder";
import { ManagedForm } from "../../../shared/form/Form/ManagedForm";
import { utcTime, localizedTime } from "../../../shared/utils";
import { useApi } from "../../../shared/useApi";
import { MAX_EMERGENCY_NUMBER } from "../../../helpers/consts";
import "./TrailInfo.scss";

const initialRaceData = {
  id: undefined,
  raceName: "",
  locationName: "",
  country: "",
  startTime: "",
  endTime: "",
  visible: "",
  raceTypeId: "",
  locationLat: "",
  locationLong: "",
  headerImg: "",
  emergencyNumbers: [""],
  apiVersion: "v3",
};

export function TrailInfo() {
  const { t } = useTranslation();
  const { eventId } = useParams<{ eventId: string }>();
  const [raceData, setRaceData] = useState<RaceData>(initialRaceData);

  const { get, response: loadResponse } = useApi("/races");

  async function loadRace() {
    const race = await get(`/${eventId}`);
    if (!loadResponse.ok) {
      toastr.error(t("Error"), t("Failed to load"));
      return null;
    }

    setRaceData(race);
  }

  useEffect(() => {
    if (eventId) {
      loadRace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <TrailNavPage title={t("Trail info")} allowCreate className="trail-info">
      {({ trailData, onTrailCreate, onTrailUpdate }) => (
        <TrailForm raceId={eventId} trailData={trailData} raceData={raceData} onCreate={onTrailCreate} onSave={onTrailUpdate} />
      )}
    </TrailNavPage>
  );
}

type RaceData = any;
const unitM = "m";
const unitKm = "km";
const lengthUnitsMetersOnly = [{ value: unitM, name: "meters" }];
const lengthUnits = [...lengthUnitsMetersOnly, { value: unitKm, name: "kilometers" }];

const initialTrailDataConstructor = (raceData: RaceData) => {
  let emergencyNumbers = raceData.emergencyNumbers;

  if (!Array.isArray(emergencyNumbers)) {
    emergencyNumbers = JSON.parse(raceData.emergencyNumbers);
  }

  if (!emergencyNumbers.length) {
    emergencyNumbers.push("");
  }

  return {
    startTime: raceData.startTime ? localizedTime(new Date(raceData.startTime), raceData.country) : "",
    endTime: raceData.endTime ? localizedTime(new Date(raceData.endTime), raceData.country) : "",
    trailName: "",
    trailLength: "",
    trailLengthUnit: unitKm,
    startLat: raceData.locationLat,
    startLong: raceData.locationLong,
    finishLat: raceData.locationLat,
    finishLong: raceData.locationLong,
    passId: "",
    targetDistanceM: "",
    targetDistanceUnit: unitM,
    targetElevationM: "",
    targetElevationUnit: unitM,
    overlayFile: "",
    overlayCoordinates: "",
    gpxFile: "",
    gpxLineColor: "#651a98",
    gpxLineWidth: 3,
    emergencyNumbers,
  };
};

type TrailFormProps = {
  raceId: string;
  trailData: TrailData;
  raceData: RaceData;
  onCreate(trailId: number): void;
  onSave(trailData: TrailData): void;
};

function TrailForm({ raceId, trailData, raceData, onSave, onCreate }: TrailFormProps) {
  const { t } = useTranslation();
  const { put, post, response: saveResponse } = useApi(`/trails`);
  const { post: create, response: createResponse } = useApi(`/races/${raceId}/trails`);
  const [showOverlayModal, setShowOverlayModal] = useState(false);
  const [previousOverlayImage, setPreviousOverlayImage] = useState("");

  useEffect(() => {
    if (!trailData || !trailData.overlayUrl) {
      return;
    }

    fetch(trailData.overlayUrl)
      .then((res) => res.blob())
      .then((blob) => setPreviousOverlayImage(URL.createObjectURL(blob)));

    return () => setPreviousOverlayImage("");
  }, [trailData]);

  async function onSubmit(formData: Partial<TrailData>) {
    if (formData.id) {
      const { overlayUrl, ...serverData } = trailData;
      await saveTrail(formData, Object.keys(serverData).length ? convertServerData(serverData) : initialTrailDataConstructor(raceData));
    } else {
      await createTrail(formData);
    }
  }

  async function saveTrail(formData: Partial<TrailData>, previousFormData: TrailData) {
    const { overlayUrl, gpxFile, overlayFile, ...trailFormData } = formData;
    const { overlayUrl: url, ...serverData } = trailData;

    let { newOverlayUrl, ...savedData } = await put(`/${serverData.id}`, {
      ...serverData,
      ...convertFormData(trailFormData),
    });

    if (!saveResponse.ok) {
      toastr.error(t("Error"), t("Failed to save"));
      return null;
    }

    if (overlayUrl !== newOverlayUrl) {
      setPreviousOverlayImage(newOverlayUrl);
    }

    if (previousFormData?.gpxFile !== gpxFile) {
      if (await uploadGpx(serverData.id, gpxFile)) {
        savedData = { ...savedData, gpxFileName: gpxFile.name };
      }
    }

    if (previousFormData?.overlayFile !== overlayFile) {
      const overlayFilename = await uploadOverlay(serverData.id, overlayFile);
      if (overlayFilename) {
        savedData = { ...savedData, overlayFilename };
        const response = await fetch(`${process.env.REACT_APP_API_V3_BASE_URL}/get-overlay?trailId=${serverData.id}`);
        const { success, data, error } = await response.json();

        if (!success) {
          console.error(error);
          return null;
        }
        savedData.overlayUrl = data.overlayUrl;
      }
    }

    onSave(savedData);
  }

  async function createTrail(formData: Partial<TrailData>) {
    const { gpxFile, overlayFile, ...trailFormData } = formData;

    const createData = await create("", convertFormData(trailFormData));

    if (!createResponse.ok) {
      toastr.error(t("Error"), t("Failed to create"));
      return null;
    }

    if (gpxFile) {
      await uploadGpx(createData.id, gpxFile);
    }

    if (overlayFile) {
      await uploadOverlay(createData.id, overlayFile);
    }

    onCreate(createData.id);
  }

  async function uploadGpx(trailId: number, file: File | string) {
    try {
      if (!(file instanceof File)) {
        toastr.error(t("Error"), t("Upload a GPX file"));
        return null;
      }

      const { url: uploadUrl, gpxUploadUrl, gpxFileName } = await post(`/${trailId}/gpx-upload-url`);

      if (!saveResponse.ok) {
        toastr.error(t("Error"), t("Failed to upload a GPX file"));
        return null;
      }

      const updatedFile = new File([file], gpxFileName);

      await Promise.all([
        fetch(uploadUrl, {
          method: "PUT",
          body: updatedFile,
        }),
        fetch(gpxUploadUrl, { method: "PUT", body: updatedFile }),
      ]);

      return await post(`/${trailId}/gpx-upload`);
    } catch (e) {
      toastr.error(t("Error"), t("Failed to upload a GPX file"));
      return null;
    }
  }

  async function uploadOverlay(trailId: number, file: File | string) {
    try {
      if (!(file instanceof File)) {
        toastr.error(t("Error"), t("Upload an overlay"));
        return null;
      }

      const { uploadUrl, overlayFilename } = await post(`/${trailId}/overlay-upload-url`, { filetype: file.type.split("/")[1] });

      if (!saveResponse.ok) {
        toastr.error(t("Error"), t("Failed to upload the overlay"));
        return null;
      }

      const updatedFile = new File([file], overlayFilename);

      await fetch(uploadUrl, {
        method: "PUT",
        body: updatedFile,
      });
      return overlayFilename;
    } catch (e) {
      toastr.error(t("Error"), t("Failed to upload the overlay"));
      return null;
    }
  }

  function createValidationSchema(t: TFunction) {
    return Yup.object({
      trailName: Yup.string().required(t("Trail name is required")),
      trailLength: Yup.string().required(t("Trail length is required")),
      trailLengthUnit: Yup.string().required(t("Trail length unit is required")),
      startTime: Yup.date()
        .required(t("Starting is required"))
        .test(`start-time-min`, "Trail starting should be after race starting", (value) =>
          value ? moment(value).isAfter(moment(new Date(raceData.startTime)).subtract(1, "ms")) : true
        )
        .max(Yup.ref("endTime"), "Starting should be before ending"),
      endTime: Yup.date()
        .required(t("Ending is required"))
        .min(Yup.ref("startTime"), "Ending should be after starting")
        .test(`end-time-max`, "Trail ending should be before race ending", (value) =>
          value ? moment(value).isBefore(moment(new Date(raceData.endTime)).add(1, "ms")) : true
        ),
      gpxLineColor: Yup.string()
        .nullable()
        .when("gpxFile", {
          is: (v: any) => !!v,
          then: Yup.string().nullable().required(t("Gpx line color is required")),
        }),
      gpxLineWidth: Yup.number()
        .nullable()
        .min(1, t("Gpx line width should be at least 1"))
        .when("gpxFile", {
          is: (v: any) => !!v,
          then: Yup.number().nullable().required(t("Gpx line width is required")),
        }),
    });
  }

  function convertServerData(data: TrailData): TrailData {
    const startTime = localizedTime(new Date(data.startTime), raceData.country);
    const endTime = localizedTime(new Date(data.endTime), raceData.country);
    const lengthNum = parseFloat(data.trailLength);
    const trailLength = isNaN(lengthNum) ? null : lengthNum;
    const emergencyNumbers = JSON.parse(data.emergencyNumbers);

    if (!emergencyNumbers.length) {
      emergencyNumbers.push("");
    }

    return {
      ...data,
      gpxLineColor: "#651a98",
      gpxLineWidth: 3,
      startTime,
      endTime,
      emergencyNumbers,
      trailLength: trailLength,
      trailLengthUnit: (data.trailLength || "").replace(String(trailLength), "").trim(),
      targetDistanceUnit: unitM,
      targetElevationUnit: unitM,
      gpxFile: data.gpxFileName,
      overlayFile: data.overlayFilename,
    };
  }

  function convertFormData(formData: Partial<TrailData>) {
    const { trailLength, trailLengthUnit, targetDistanceUnit, targetElevationUnit, ...serverData } = formData;
    const startTime = utcTime(new Date(formData.startTime), raceData.country);
    const endTime = utcTime(new Date(formData.endTime), raceData.country);
    const emergencyNumbers = JSON.stringify(formData.emergencyNumbers);

    return {
      ...serverData,
      startTime,
      endTime,
      emergencyNumbers,
      trailLength: `${trailLength} ${trailLengthUnit}`,
    };
  }

  return (
    <ManagedForm
      values={trailData ? convertServerData(trailData) : initialTrailDataConstructor(raceData)}
      validationSchema={createValidationSchema(t)}
      toolbar={{ lastUpdateDate: raceData ? raceData.changeDate : null }}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        const setTrailStartCoordinates = (latitude: number, longitude: number) => {
          setFieldValue("startLat", latitude);
          setFieldValue("startLong", longitude);
        };

        const setTrailFinishCoordinates = (latitude: number, longitude: number) => {
          setFieldValue("finishLat", latitude);
          setFieldValue("finishLong", longitude);
        };

        const setOverlayData = (overlayFile: File, overlayCoordinates: number[][]) => {
          setFieldValue("overlayFile", overlayFile);
          setFieldValue("overlayCoordinates", JSON.stringify(overlayCoordinates));
          onAddOverlayClick();
        };

        const onAddOverlayClick = () => {
          setShowOverlayModal(!showOverlayModal);
        };

        return (
          <Row>
            <Col sm="4">
              <Form.Row>
                <Col md="12">
                  <InputControl type="text" name="trailName" label={t("Trail name")} placeholder={t("Enter name")} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="12">
                  <DatetimeControl name="startTime" label={t("Starting")} placeholder={t("Enter starting")} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="12">
                  <DatetimeControl name="endTime" label={t("Ending")} placeholder={t("Enter ending")} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="6">
                  <InputControl type="number" name="trailLength" label={t("Trail length")} placeholder={t("Length")} />
                </Col>
                <Col md="6">
                  <SelectControl name="trailLengthUnit" options={lengthUnits} />
                </Col>
              </Form.Row>
              <FileControl name="gpxFile" label={t("Upload GPX")} placeholder={t("Upload a GPX file")} accept=".gpx" isGpxControl />
              {values.gpxFile && (
                <Form.Row>
                  <Col md="6">
                    <InputControl type="color" name="gpxLineColor" label={t("GPX line color")} placeholder={t("Line color")} />
                  </Col>
                  <Col md="6">
                    <InputControl type="number" name="gpxLineWidth" label={t("GPX line width")} placeholder={t("Line width")} />
                  </Col>
                </Form.Row>
              )}
              <Form.Row>
                <Col md="12">
                  <Map latitude={values.startLat} longitude={values.startLong} onClick={setTrailStartCoordinates} />
                </Col>
                <Col md="6">
                  <InputControl type="number" name="startLat" label={t("Start lat")} placeholder={t("Latitude")} />
                </Col>
                <Col md="6">
                  <InputControl type="number" name="startLong" label={t("Start long")} placeholder={t("Longitude")} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="12">
                  <Map latitude={values.finishLat} longitude={values.finishLong} onClick={setTrailFinishCoordinates} />
                </Col>
                <Col md="6">
                  <InputControl type="number" name="finishLat" label={t("End lat")} placeholder={t("Latitude")} />
                </Col>
                <Col md="6">
                  <InputControl type="number" name="finishLong" label={t("End long")} placeholder={t("Longitude")} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={12} className="overlay-image">
                  <Form.Label>Overlay</Form.Label>
                  {!previousOverlayImage && <div className="overlay-placeholder" />}
                  {!!previousOverlayImage && <img src={previousOverlayImage} alt={t("Overlay")} className="overlay" />}
                </Col>
                <Button color="primary" type="button" name="addOverlay" className="add-overlay-btn" onClick={onAddOverlayClick}>
                  {t("Add overlay")}
                </Button>
              </Form.Row>
              <InputControl type="number" name="passId" label={t("Pass ID")} placeholder={t("Enter pass ID")} />
              <Form.Row>
                <Col md="6">
                  <InputControl type="number" name="targetDistanceM" label={t("Target distance")} placeholder={t("Distance")} />
                </Col>
                <Col md="6">
                  <SelectControl name="targetDistanceUnit" options={lengthUnitsMetersOnly} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="6">
                  <InputControl type="number" name="targetElevationM" label={t("Target elevation")} placeholder={t("Elevation")} />
                </Col>
                <Col md="6">
                  <SelectControl name="targetElevationUnit" options={lengthUnitsMetersOnly} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="12">
                  <InputArrayControl
                    values={values}
                    maxItems={MAX_EMERGENCY_NUMBER}
                    name="emergencyNumbers"
                    label={t("Emergency numbers")}
                    placeholder={t("Enter a phone number")}
                    type="text"
                  />
                </Col>
              </Form.Row>
            </Col>
            <Col sm="8">
              <Placeholder title={t("No preview yet.")} description={t("Here you will find the preview of this trail.")} />
            </Col>
            <MapOverlayModal
              show={showOverlayModal}
              onHide={onAddOverlayClick}
              onSave={setOverlayData}
              trailStart={[values.startLong, values.startLat]}
              trailFinish={[values.finishLong, values.finishLat]}
              previousOverlayCoordinates={
                values.overlayCoordinates && Array.isArray(JSON.parse(values.overlayCoordinates)) ? JSON.parse(values.overlayCoordinates) : []
              }
              previousOverlay={previousOverlayImage}
            />
          </Row>
        );
      }}
    </ManagedForm>
  );
}
