import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Button, Tab, Tabs } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { FieldArray } from "formik";
import * as Yup from "yup";
import { TrailNavPage } from "../TrailNavPage/TrailNavPage";
import { ManagedForm } from "../../../shared/form/Form/ManagedForm";
import { InputControl } from "../../../shared/form/controls/InputControl/InputControl";
import { Placeholder } from "../../../shared/Placeholder/Placeholder";
import Slider from "../../../shared/Slider/Slider";
import { FIRST_RANGE_COLOR, generateColor, LAST_RANGE_COLOR } from "../../../shared/utils";
import { useApi } from "../../../shared/useApi";
import { genderOptions } from "../../../helpers/enums/genders";
import { AgeGroupContent, AgeGroupsExtended } from "../../../helpers/types/ageGroups";
import { Gender } from "../../../helpers/types/genders";
import iconTrash from "../../../assets/svg/iconTrash.svg";
import iconPlus from "../../../assets/svg/iconPlusWhite.svg";
import iconExport from "../../../assets/svg/iconExport.svg";
import iconImport from "../../../assets/svg/iconImport.svg";
import "./AgeGroups.scss";

type AgeGroupsFormProps = { trailId: string | null };
const STEP = 1;
const MIN = 1;
const MAX = 99;

const AgeGroups = () => {
  const { t } = useTranslation();

  return (
    <TrailNavPage
      title={t("Age groups")}
      className="age-groups"
      hideButtons
      placeholder={() => <Placeholder title={t("No age groups.")} description={t("Add some trails on the Trail info tab.")} />}
    >
      {({ trailData }) => <AgeGroupsForm trailId={trailData ? trailData.id : null} />}
    </TrailNavPage>
  );
};

const AgeGroupsForm = ({ trailId }: AgeGroupsFormProps) => {
  const { t } = useTranslation();
  const { get, put } = useApi(`/trails/${trailId}/age-groups`);
  const ageRangeStartRef = useRef({ male: MIN, female: MIN, agender: MIN });
  const ageRangeEndRef = useRef({ male: MAX, female: MAX, agender: MAX });
  const [maleFormData, setMaleFormData] = useState<AgeGroupsExtended>({ ageGroups: [] });
  const [femaleFormData, setFemaleFormData] = useState<AgeGroupsExtended>({ ageGroups: [] });
  const [agenderFormData, setAgenderFormData] = useState<AgeGroupsExtended>({ ageGroups: [] });
  const [maleChangeDate, setMaleChangeDate] = useState<string | null>(null);
  const [femaleChangeDate, setFemaleChangeDate] = useState<string | null>(null);
  const [agenderChangeDate, setAgenderChangeDate] = useState<string | null>(null);
  const [triggerSliderRender, setTriggerSliderRender] = useState(false);
  const [ageGroupsToBeDeleted, setAgeGroupsToBeDeleted] = useState<number[] | never[]>([]);
  const [disableAddButtons, setDisableAddButtons] = useState<{ [gender in Gender]: boolean }>({
    male: false,
    female: false,
    agender: false,
  });
  // some layout-related issues with sliders in tabs (all thumbs are at slider's minimum point before hovering slider),
  // needed to trigger onMouseOver() function of react-slider on the mount of each slider
  const [tab, setTab] = useState<string | null>("male");

  useEffect(() => {
    if (trailId) {
      makeRequest(get);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailId]);

  useEffect(() => {
    if (maleFormData.ageGroups.length) {
      setDisableAddButtons({ ...disableAddButtons, male: maleFormData.ageGroups.every(({ ageTo, ageFrom }) => ageTo - ageFrom < 3) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maleFormData]);

  useEffect(() => {
    if (femaleFormData.ageGroups.length) {
      setDisableAddButtons({ ...disableAddButtons, female: femaleFormData.ageGroups.every(({ ageTo, ageFrom }) => ageTo - ageFrom < 3) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [femaleFormData]);

  useEffect(() => {
    if (agenderFormData.ageGroups.length) {
      setDisableAddButtons({ ...disableAddButtons, agender: agenderFormData.ageGroups.every(({ ageTo, ageFrom }) => ageTo - ageFrom < 3) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenderFormData]);

  const makeRequest = async (requestType: (...params: any) => any, ...requestOptions: any): Promise<any> => {
    const { data, success, error } = await requestType(...requestOptions);

    if (!success) {
      toastr.error(t("Error"), error);
      return new Promise(() => null);
    }
    if (!data.length) {
      return new Promise(() => {
        setMaleFormData({ ageGroups: [] });
        setMaleChangeDate(null);
        setFemaleFormData({ ageGroups: [] });
        setFemaleChangeDate(null);
        setAgenderFormData({ ageGroups: [] });
        setAgenderChangeDate(null);
        setAgeGroupsToBeDeleted([]);
      });
    }

    const formData = createFormData(data);

    return new Promise(() => {
      setMaleFormData({ ageGroups: formData.male });
      setMaleChangeDate(formData.maleChangeDate);
      setFemaleFormData({ ageGroups: formData.female });
      setFemaleChangeDate(formData.femaleChangeDate);
      setAgenderFormData({ ageGroups: formData.agender });
      setAgenderChangeDate(formData.agenderChangeDate);
      setAgeGroupsToBeDeleted([]);
    });
  };

  const createPutRequestBody = (formData: AgeGroupContent[], gender: Gender) => {
    return formData.map(({ internalId, firstColor, color, lastColor, ...filteredGroup }: any) => {
      const genderOption = genderOptions.basic.find(({ name }) => name === gender);
      return {
        ...filteredGroup,
        gender: genderOption?.value || "Ag",
      };
    });
  };

  const createFormData = (ageGroups: any[]) => {
    const ageGroupsByGenders = ageGroups.reduce(
      (acc: AgeGroupsExtended, ageGroup: { id?: number; gender: Gender; ageFrom: number; ageTo: number; name: string; abbreviation: string }) => {
        const genderOption = genderOptions.basic.find(({ value }) => value === ageGroup.gender);
        if (!genderOption) return acc;
        const genderName = genderOption.name;
        return {
          ...acc,
          [genderName]: [...acc[genderName], ageGroup],
        };
      },
      {
        male: [],
        female: [],
        agender: [],
      }
    );

    const mappedMaleAgeGroups = ageGroupsByGenders.male
      .sort((a: AgeGroupContent, b: AgeGroupContent) => a.ageFrom - b.ageFrom)
      .map((ageGroup: any, index: number) => onAgeGroupFormat(ageGroup, index, "male" as Gender, ageGroupsByGenders.male.length));
    const mappedFemaleAgeGroups = ageGroupsByGenders.female
      .sort((a: AgeGroupContent, b: AgeGroupContent) => a.ageFrom - b.ageFrom)
      .map((ageGroup: any, index: number) => onAgeGroupFormat(ageGroup, index, "female" as Gender, ageGroupsByGenders.female.length));
    const mappedAgenderAgeGroups = ageGroupsByGenders.agender
      .sort((a: AgeGroupContent, b: AgeGroupContent) => a.ageFrom - b.ageFrom)
      .map((ageGroup: any, index: number) => onAgeGroupFormat(ageGroup, index, "agender" as Gender, ageGroupsByGenders.agender.length));
    const maleChangeDate = ageGroupsByGenders.male.map(({ changeDate }: any) => changeDate).sort()[ageGroupsByGenders.male.length - 1];
    const femaleChangeDate = ageGroupsByGenders.female.map(({ changeDate }: any) => changeDate).sort()[ageGroupsByGenders.female.length - 1];
    const agenderChangeDate = ageGroupsByGenders.agender.map(({ changeDate }: any) => changeDate).sort()[ageGroupsByGenders.agender.length - 1];

    return {
      male: mappedMaleAgeGroups,
      maleChangeDate,
      female: mappedFemaleAgeGroups,
      femaleChangeDate,
      agender: mappedAgenderAgeGroups,
      agenderChangeDate,
    };
  };

  const onAgeGroupFormat = (ageGroup: any, index: number, gender: Gender, ageGroupsLength: number) => {
    const formattedAgeGroup = createEmptyAgeGroup(ageGroup.ageTo, ageGroup.id, ageGroup.ageFrom);

    if (ageGroupsLength === 1) {
      formattedAgeGroup.firstColor = FIRST_RANGE_COLOR;
      formattedAgeGroup.lastColor = LAST_RANGE_COLOR;
      ageRangeStartRef.current[gender as Gender] = ageGroup.ageFrom;
      ageRangeEndRef.current[gender as Gender] = formattedAgeGroup.ageTo;
    } else if (index === 0) {
      ageRangeStartRef.current[gender as Gender] = formattedAgeGroup.ageFrom;
      formattedAgeGroup.firstColor = FIRST_RANGE_COLOR;
    } else if (index === ageGroupsLength - 1) {
      formattedAgeGroup.lastColor = LAST_RANGE_COLOR;
      ageRangeEndRef.current[gender as Gender] = formattedAgeGroup.ageTo;
    }

    formattedAgeGroup.ageFrom = ageGroup.ageFrom;
    formattedAgeGroup.name = ageGroup.name;
    formattedAgeGroup.abbreviation = ageGroup.abbreviation;

    return formattedAgeGroup;
  };

  const createValidationSchema = (t: TFunction) =>
    Yup.object({
      ageGroups: Yup.array().of(
        Yup.object({
          name: Yup.string().required(t("Name is required")),
          abbreviation: Yup.string().required(t("Abbreviation is required")),
          ageFrom: Yup.number().required(t("Age (From) is required")),
          ageTo: Yup.number().required(t("Age (To) is required")),
        })
      ),
    });

  const createEmptyAgeGroup = (ageTo: number, id?: number, ageFrom?: number) => ({
    [id ? "id" : "internalId"]: id || Math.random(),
    name: "",
    abbreviation: "",
    ageFrom: ageFrom || 1,
    ageTo,
    firstColor: "",
    color: generateColor(),
    lastColor: "",
  });

  const onRangeCreation = (gender: Gender, ageTo: number) => {
    const newAgeGroup = createEmptyAgeGroup(ageTo);
    const genderData = getFormDataByGender(gender);
    let sortedAgeGroups;

    const ageGroupsExtended = [...genderData, newAgeGroup];

    if (ageTo === 500) {
      const rangeToAddNewGroup = genderData
        .map(({ ageTo, ageFrom }, index) => ({
          index,
          ageTo,
          ageFrom,
          difference: ageTo - ageFrom,
        }))
        .sort((a, b) => b.difference - a.difference)[0];
      const newGroupAgeTo = Math.floor(rangeToAddNewGroup.ageFrom + rangeToAddNewGroup.difference / 2);
      const newEmptyAgeGroup = createEmptyAgeGroup(newGroupAgeTo, undefined, rangeToAddNewGroup.ageFrom);
      genderData[rangeToAddNewGroup.index].ageFrom = newGroupAgeTo + 1;
      sortedAgeGroups = [...genderData, newEmptyAgeGroup].sort((a, b) => a.ageTo - b.ageTo);
    } else {
      sortedAgeGroups = ageGroupsExtended
        .sort((a, b) => a.ageTo - b.ageTo)
        .map(({ firstColor, lastColor, ...ageGroupData }, index) => ({
          ...ageGroupData,
          ageFrom: ageGroupsExtended[index - 1] ? ageGroupsExtended[index - 1].ageTo + 1 : ageRangeStartRef.current[gender],
          firstColor: "",
          lastColor: "",
        }));

      sortedAgeGroups[0].firstColor = FIRST_RANGE_COLOR;
      sortedAgeGroups[sortedAgeGroups.length - 1].lastColor = LAST_RANGE_COLOR;
    }
    switch (gender) {
      case "male":
        setMaleFormData({ ageGroups: sortedAgeGroups });
        break;
      case "female":
        setFemaleFormData({ ageGroups: sortedAgeGroups });
        break;
      case "agender":
      default:
        setAgenderFormData({ ageGroups: sortedAgeGroups });
        break;
    }
    setTriggerSliderRender(true);
  };

  const onCreateRangeButtonClick = (gender: Gender) => {
    let sortedAgeGroups: AgeGroupContent[];
    const genderData = getFormDataByGender(gender);

    if (!genderData.length) {
      const newEmptyAgeGroup = createEmptyAgeGroup(ageRangeEndRef.current[gender as Gender]);
      newEmptyAgeGroup.firstColor = FIRST_RANGE_COLOR;
      newEmptyAgeGroup.lastColor = LAST_RANGE_COLOR;
      sortedAgeGroups = [newEmptyAgeGroup];
    } else {
      const rangeToAddNewGroup = genderData
        .map(({ ageTo, ageFrom }, index) => ({
          index,
          ageTo,
          ageFrom,
          difference: ageTo - ageFrom,
        }))
        .sort((a, b) => b.difference - a.difference)[0];
      const newGroupAgeTo = Math.floor(rangeToAddNewGroup.ageFrom + rangeToAddNewGroup.difference / 2);
      const newEmptyAgeGroup = createEmptyAgeGroup(newGroupAgeTo, undefined, rangeToAddNewGroup.ageFrom);
      genderData[rangeToAddNewGroup.index].ageFrom = newGroupAgeTo + 1;
      const ageGroupsExtended = [...genderData, newEmptyAgeGroup];
      sortedAgeGroups = ageGroupsExtended
        .sort((a, b) => a.ageTo - b.ageTo)
        .map(({ firstColor, lastColor, ...ageGroupData }, index) => ({
          ...ageGroupData,
          ageFrom: ageGroupsExtended[index - 1] ? ageGroupsExtended[index - 1].ageTo + 1 : ageRangeStartRef.current[gender as Gender],
          firstColor: "",
          lastColor: "",
        }));

      sortedAgeGroups[0].firstColor = FIRST_RANGE_COLOR;
      sortedAgeGroups[sortedAgeGroups.length - 1].lastColor = LAST_RANGE_COLOR;
    }
    setFormDataByGender(gender, sortedAgeGroups);
  };

  const onRangeRemove = (gender: Gender, ageGroupIndex: number) => {
    const genderData = getFormDataByGender(gender);
    const ageGroupsFiltered = genderData.filter((_, index) => index !== ageGroupIndex);

    if (ageGroupsFiltered.length) {
      if (ageGroupIndex === 0) {
        ageGroupsFiltered[0] = {
          ...ageGroupsFiltered[0],
          ageFrom: ageRangeStartRef.current[gender as Gender],
          firstColor: FIRST_RANGE_COLOR,
        };
      } else {
        ageGroupsFiltered[ageGroupIndex - 1] = {
          ...ageGroupsFiltered[ageGroupIndex - 1],
          ageTo: ageGroupIndex === ageGroupsFiltered.length ? ageRangeEndRef.current[gender as Gender] : ageGroupsFiltered[ageGroupIndex].ageFrom - 1,
          lastColor: ageGroupIndex === ageGroupsFiltered.length ? LAST_RANGE_COLOR : "",
        };
      }
    }
    setFormDataByGender(gender, ageGroupsFiltered);
    setTriggerSliderRender(true);
  };

  const onSliderRangesChange = (gender: Gender, values: number[]) => {
    const genderData = getFormDataByGender(gender);
    const mappedAgeGroups = genderData.map((val, index) => ({
      ...val,
      ageFrom: index === 0 ? ageRangeStartRef.current[gender as Gender] : values[index - 1] + 1,
      ageTo: index === genderData.length - 1 ? ageRangeEndRef.current[gender as Gender] : values[index],
    }));
    setFormDataByGender(gender, mappedAgeGroups);
  };

  const onSliderBordersChange = (gender: Gender, value: number, left: boolean): boolean => {
    const genderData = getFormDataByGender(gender);
    let areAgeGroupsOverlaping = false;
    let index = genderData.length - 1;
    if (genderData.length > 1) {
      if (left) {
        areAgeGroupsOverlaping = genderData[1].ageTo <= value + 2;
      } else {
        areAgeGroupsOverlaping = genderData[index - 1].ageTo >= value - 1;
      }
    }
    if (areAgeGroupsOverlaping) {
      return false;
    }
    if (left) {
      index = 0;
      genderData[index].ageFrom = value;
      if (genderData[index + 1] && value >= genderData[index + 1].ageFrom - 1) {
        genderData[index + 1].ageFrom = value + 2;
      }
      if (value === genderData[index].ageTo) {
        genderData[index].ageTo = value + 1;
      }
      ageRangeStartRef.current[gender] = genderData[index].ageFrom;
    } else {
      genderData[index].ageTo = value;
      ageRangeEndRef.current[gender] = value;
    }

    setFormDataByGender(gender, genderData);
    return true;
  };

  const getFormDataByGender = (gender: Gender) => {
    switch (gender) {
      case "male":
        return maleFormData.ageGroups;
      case "female":
        return femaleFormData.ageGroups;
      case "agender":
      default:
        return agenderFormData.ageGroups;
    }
  };

  const setFormDataByGender = (gender: Gender, mappedAgeGroups: AgeGroupContent[]) => {
    switch (gender) {
      case "male":
        setMaleFormData({ ageGroups: mappedAgeGroups });
        break;
      case "female":
        setFemaleFormData({ ageGroups: mappedAgeGroups });
        break;
      case "agender":
      default:
        setAgenderFormData({ ageGroups: mappedAgeGroups });
        break;
    }
  };

  const onAfterTrigger = () => setTriggerSliderRender(false);

  return (
    <Tabs defaultActiveKey="male" className="slider-tabs" onSelect={(val) => setTab(val)}>
      {[
        { gender: "male", data: maleFormData, changeDate: maleChangeDate },
        { gender: "female", data: femaleFormData, changeDate: femaleChangeDate },
        { gender: "agender", data: agenderFormData, changeDate: agenderChangeDate },
      ].map(({ gender, data, changeDate }: any) => (
        <Tab key={gender} eventKey={gender} title={gender} tabClassName="age-group-tab">
          <ManagedForm
            values={data}
            validationSchema={createValidationSchema(t)}
            toolbar={{ lastUpdateDate: changeDate }}
            onSubmit={(formData: { ageGroups: AgeGroupContent[] }) =>
              makeRequest(put, {
                data: createPutRequestBody(formData.ageGroups, gender),
                dataToBeDeleted: ageGroupsToBeDeleted,
              })
            }
          >
            {({ values }) => {
              return (
                <>
                  <div className="top-block">
                    {!!values.ageGroups.length && (
                      <Slider
                        ageGroups={values.ageGroups}
                        activeTab={tab}
                        gender={gender}
                        disableAddButtons={disableAddButtons}
                        rangeStart={ageRangeStartRef.current[gender as Gender]}
                        rangeEnd={ageRangeEndRef.current[gender as Gender]}
                        triggerSliderRender={triggerSliderRender}
                        onAfterTrigger={onAfterTrigger}
                        onSliderRangesChange={onSliderRangesChange}
                        onSliderBordersChange={onSliderBordersChange}
                        onRangeCreation={onRangeCreation}
                        onRangeRemove={(gender, rangeIndex) => {
                          onRangeRemove(gender, rangeIndex);
                          const ageGroupFormData = values.ageGroups[rangeIndex];
                          if (ageGroupFormData.id) {
                            setAgeGroupsToBeDeleted([...ageGroupsToBeDeleted, ageGroupFormData.id]);
                          }
                        }}
                      />
                    )}
                  </div>
                  <FieldArray name="ageGroups">
                    {({ remove }) => (
                      <div>
                        {values &&
                          values.ageGroups.map((data: any, index: number) => {
                            return (
                              <div key={index} className="age-group-container">
                                <div className="order" style={{ background: data.firstColor || data.lastColor || data.color }}>
                                  {index + 1}
                                </div>
                                <div key={data.id || data.internalId} className="d-flex align-items-center justify-content-between array-item age-group">
                                  <div className="name">
                                    <InputControl
                                      type="text"
                                      name={`ageGroups.${index}.name`}
                                      label={t("Age Group name")}
                                      placeholder={t("Enter name")}
                                      popUpError
                                    />
                                  </div>
                                  <div className="abbreviation">
                                    <InputControl
                                      type="text"
                                      name={`ageGroups.${index}.abbreviation`}
                                      label={t("Abbreviation")}
                                      placeholder={t("Abbreviation")}
                                      popUpError={true}
                                    />
                                  </div>
                                  <div className="age-range-container">
                                    <div className="label">{t("Age range")}</div>
                                    <div className="fieldset">
                                      <div className="age-from">
                                        <InputControl
                                          popUpError
                                          type="number"
                                          name={`ageGroups.${index}.ageFrom`}
                                          placeholder={ageRangeStartRef.current[gender as Gender].toString()}
                                          label=""
                                          min={index === 0 ? MIN : values.ageGroups[index - 1].ageFrom + 2}
                                          max={
                                            index === values.ageGroups.length - 1
                                              ? ageRangeEndRef.current[gender as Gender]
                                              : values.ageGroups[index + 1].ageFrom - 2
                                          }
                                          step={STEP}
                                          onChange={(event) => {
                                            const value = +event.target.value;
                                            if (index === 0) {
                                              ageRangeStartRef.current[gender as Gender] = value;
                                            }
                                            if (values.ageGroups[index - 1]) {
                                              values.ageGroups[index - 1].ageTo = value - 1;
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="ruler" />
                                      <div className="age-to">
                                        <InputControl
                                          popUpError
                                          type="number"
                                          name={`ageGroups.${index}.ageTo`}
                                          placeholder={ageRangeEndRef.current[gender as Gender].toString()}
                                          label=""
                                          min={index === 0 ? ageRangeStartRef.current[gender as Gender] + 1 : values.ageGroups[index - 1].ageTo + 2}
                                          max={index === values.ageGroups.length - 1 ? MAX : values.ageGroups[index + 1].ageTo - 2}
                                          step={STEP}
                                          onChange={(event) => {
                                            const value = +event.target.value;
                                            if (index === values.ageGroups.length - 1) {
                                              ageRangeEndRef.current[gender as Gender] = value;
                                            }
                                            if (values.ageGroups[index + 1]) {
                                              values.ageGroups[index + 1].ageFrom = value + 1;
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <Button
                                      variant="link"
                                      size="sm"
                                      className="text-danger delete-item"
                                      onClick={() => {
                                        remove(index);
                                        onRangeRemove(gender as Gender, index);
                                        if (data.id) {
                                          setAgeGroupsToBeDeleted([...ageGroupsToBeDeleted, data.id]);
                                        }
                                      }}
                                    >
                                      <img src={iconTrash} alt="" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </FieldArray>
                </>
              );
            }}
          </ManagedForm>
          <div className="actions-block">
            <button
              className="button add-group-btn"
              type="button"
              disabled={disableAddButtons[gender as Gender]}
              onClick={() => onCreateRangeButtonClick(gender as Gender)}
            >
              <img src={iconPlus} alt="" width={16} height={16} className="btn-icon" />
              {t("Add Age Group")}
            </button>
            <div className="export-import-block">
              <button disabled type="button" className="button export-btn">
                <img src={iconImport} alt="" width={16} height={20} className="btn-icon" />
                <span>{t("Import")}</span>
              </button>
              <button disabled type="button" className="button import-btn">
                <img src={iconExport} alt="" width={16} height={20} className="btn-icon" />
                <span>{t("Export")}</span>
              </button>
            </div>
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default AgeGroups;
