import { ChangeEvent, ReactElement } from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";
import { ReactComponent as Check } from "../../../../assets/svg/iconCheck.svg";
import "./CheckboxControl.scss";

type CheckboxClasses = {
  formGroup?: string;
  formCheck?: string;
  checkMimic?: string;
  formCheckInput?: string;
  formLabel?: string;
  formFeedback?: string;
};

type CheckboxControlProps = {
  classNames?: CheckboxClasses;
  name: string;
  label: string | ReactElement;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};
export function CheckboxControl({ classNames = {}, name, label, onChange }: CheckboxControlProps) {
  const [field, meta, helpers] = useField(name);
  const hasCustomStyles = !!Object.keys(classNames).length;

  const triggerCheckbox = () => {
    helpers.setValue(!field.value);
  };

  return (
    <Form.Group controlId={name} className={`checkbox-control ${classNames.formGroup ? classNames.formGroup : ""}`}>
      <Form.Check className={classNames.formCheck}>
        {hasCustomStyles && (
          <button
            type="button"
            className={`${classNames.checkMimic ? classNames.checkMimic : ""} ${field.value ? "checked" : ""}`}
            onClick={triggerCheckbox}
            aria-labelledby="checkbox-label"
          >
            {field.value && <Check />}
          </button>
        )}
        <Form.Check.Input
          className={`checkbox-input ${classNames.formCheckInput ? classNames.formCheckInput : ""} ${hasCustomStyles ? "d-none" : ""}`}
          type="checkbox"
          {...field}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
          isInvalid={meta.touched && !!meta.error}
        />
        <Form.Check.Label
          id="checkbox-label"
          className={`checkbox-label ${classNames.formLabel ? classNames.formLabel : ""}`}
          onClick={hasCustomStyles ? () => triggerCheckbox() : () => {}}
        >
          {label}
        </Form.Check.Label>
        <Form.Control.Feedback type="invalid" className={classNames.formFeedback ? classNames.formFeedback : ""}>
          {meta.error}
        </Form.Control.Feedback>
      </Form.Check>
    </Form.Group>
  );
}
