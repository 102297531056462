import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { DatetimeControl } from "../../../../shared/form/controls/DateTimeControl/DateTimeControl";
import { InputControl } from "../../../../shared/form/controls/InputControl/InputControl";
import { SelectControl } from "../../../../shared/form/controls/SelectControl/SelectControl";
import { ManagedForm } from "../../../../shared/form/Form/ManagedForm";
import { useApi } from "../../../../shared/useApi";
import { getStatusLabel, useParticipantStatuses } from "../statusHelper";

type EditActivityModalProps = {
  raceStatus: string;
  activityId: number | undefined;
  onHide: () => void;
  onSave: () => void;
};

const initialFormData = {};
export function EditActivityModal({ onHide, onSave, raceStatus, activityId }: EditActivityModalProps) {
  const { t } = useTranslation();
  const statuses = useParticipantStatuses(t);
  const [formData, setFormData] = useState(initialFormData);
  const { get, put, response } = useApi(`/activities/${activityId}`);

  useEffect(() => {
    if (activityId) {
      loadActivity();
    } else {
      setFormData(initialFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);

  async function loadActivity() {
    const activityData = await get();

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to save activity"));
      return null;
    }

    setFormData(activityData);
  }

  async function onSubmit(formData: any) {
    await put({
      ...formData,
      startTime: moment(formData.startTime).valueOf(),
      finishTime: moment(formData.finishTime).valueOf(),
    });

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to save activity"));
      return null;
    }

    onSave();
  }

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Edit result")}</Modal.Title>
      </Modal.Header>
      <ManagedForm values={formData} validationSchema={createValidationSchema(t)} onSubmit={onSubmit}>
        {({ values, handleChange }) => (
          <>
            <Modal.Body>
              <div>
                <InputControl type="text" name={`userName`} label={t("Name")} placeholder={t("Name")} readonly></InputControl>
                <SelectControl
                  name="status"
                  label={t("Status")}
                  placeholder={t("Select status")}
                  options={(Object.keys(statuses) as any as number[])
                    .filter((s) => !isNaN(s))
                    .map((s: number) => ({
                      value: s,
                      name: `${s} - ${getStatusLabel(statuses[s], raceStatus)}`,
                    }))}
                ></SelectControl>
                <DatetimeControl name="startTime" label={t("Start time")} placeholder={t("Enter start time")}></DatetimeControl>
                <DatetimeControl name="finishTime" label={t("Finish time")} placeholder={t("Enter finish time")}></DatetimeControl>
                <InputControl type="number" name={`currentDistanceM`} label={t("Distance")} placeholder={t("Enter distance")}></InputControl>
                <InputControl type="number" name={`currentElevationM`} label={t("Elevation")} placeholder={t("Enter elevation")}></InputControl>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="outline-gray" onClick={onHide}>
                {t("Close")}
              </Button>
              <Button type="submit" size="sm" variant="primary" onClick={() => {}}>
                <FontAwesomeIcon className="button-icon" icon={faCheckCircle}></FontAwesomeIcon>
                {t("Save")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </ManagedForm>
    </Modal>
  );
}

function createValidationSchema(t: TFunction) {
  return null;
}
