import { AppHeader } from "../../../shared/AppHeader/AppHeader";
import "./AuthLayout.scss";

type AuthLayoutProps = { className: string; children: React.ReactElement };

export function AuthLayout({ className, children }: AuthLayoutProps) {
  return (
    <div className={`auth-layout ${className}`}>
      <AppHeader></AppHeader>
      <div className="auth-layout-frame">
        <div className="form-container">
          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
}
