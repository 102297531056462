import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import reducer from "./global/reducer";
import results from "../pages/event/Results/store";
import cart from "../pages/Cart/store";

const appReducer = combineReducers({
  global: reducer,
  results,
  cart,
  toastr: toastrReducer,
});

export default appReducer;
