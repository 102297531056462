import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Col, Row } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { ImageControl } from "../../shared/form/controls/ImageControl/ImageControl";
import { InputControl } from "../../shared/form/controls/InputControl/InputControl";
import { ManagedForm } from "../../shared/form/Form/ManagedForm";
import { FormToolbar } from "../../shared/form/FormToolbar/FormToolbar";
import { Tabs } from "../../shared/Tabs/Tabs";
import { useApi } from "../../shared/useApi";
import { reload } from "../../store/global/actions";
import { selectUser } from "../../store/global/selectors";
import { contentWidth, contentWidthCls } from "../consts";
import "./Profile.scss";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string | File;
};
const initialValues: FormData = {
  firstName: "",
  lastName: "",
  email: "",
  profilePicture: "",
};

export function Profile() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { put, post, response } = useApi("/users/me");
  const [formData, setFromData] = useState(initialValues);

  useEffect(() => {
    setFromData(user ? createFormData(user) : initialValues);
  }, [user]);

  async function onSubmit(formData: FormData) {
    const { profilePicture, ...values } = formData;

    await put("", values);

    if (!response.ok) {
      toastr.error(t("Error"), t("Failed to update"));
      return null;
    }

    if (profilePicture instanceof File) {
      await uploadProfilePicture(profilePicture).catch(() => {
        toastr.error(t("Error"), t("Couldn't upload image"));
      });
    }
    dispatch(reload());
  }

  async function uploadProfilePicture(image: File) {
    const uploadUrl = await post(`/picture-upload-url`);

    await fetch(uploadUrl, { method: "PUT", body: image });
  }

  return (
    <div className="profile">
      <div className="d-flex justify-content-center header">
        <Col sm={contentWidth}>
          <h2 className="title">{t("My profile")}</h2>
          <Tabs
            className="profile-tabs"
            tabs={[
              {
                url: `${url}`,
                name: t("My details"),
              },
            ]}
          ></Tabs>
        </Col>
      </div>
      <div className="d-flex justify-content-center mt-4 content">
        <Col sm={contentWidth}>
          <ManagedForm values={formData} validationSchema={createValidationSchema(t)} onSubmit={onSubmit}>
            {({ saving, saved }) => (
              <Row>
                <Col sm="3">
                  <div className="image-container">
                    <ImageControl
                      name="profilePicture"
                      label={t("Image")}
                      uploadText={t("Upload image")}
                      changeText={t("Change image")}
                      previewClassName="profile-picture"
                    ></ImageControl>
                  </div>
                </Col>
                <Col sm="9">
                  <h4>{t("My details")}</h4>
                  <Row className="mt-3">
                    <Col sm="6">
                      <InputControl name="firstName" type="text" label={t("First name")} placeholder={t("Enter your first name")}></InputControl>
                      <InputControl name="lastName" type="text" label={t("Last name")} placeholder={t("Enter your last name")}></InputControl>
                      <InputControl name="email" type="text" label={t("Email")} placeholder={t("Your email")} readonly></InputControl>
                      <FormToolbar className={contentWidthCls} lastUpdateDate={user.changeDate} saving={saving} saved={saved}></FormToolbar>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </ManagedForm>
        </Col>
      </div>
    </div>
  );
}

function createValidationSchema(t: TFunction) {
  return null;
}

function createFormData(user: any): FormData {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    profilePicture: user.profilePicture,
  };
}
