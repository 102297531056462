import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Logo } from "../../Logo/Logo";
import { selectLoggedIn, selectUser } from "../../store/global/selectors";
import iconCalendar from "../../assets/svg/iconCalendar.svg";
import iconHelp from "../../assets/svg/iconHelp.svg";
import "./AppHeader.scss";

export function AppHeader() {
  const { t } = useTranslation();
  const loggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);

  return (
    <Navbar expand="lg" fixed="top" className="app-header">
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <Logo />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="nav-bar" />
        <Navbar.Collapse id="nav-bar">
          <Nav className="ml-auto">
            {loggedIn && (
              <LinkContainer to="/" exact>
                <Nav.Link className="app-nav-link nav-events">
                  <img src={iconCalendar} alt="" height={13} width={14} className="nav-icon" />
                  {t("My Events")}
                </Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to="/help">
              <Nav.Link className="app-nav-link nav-help">
                <img src={iconHelp} alt="" height={14} width={14} className="nav-icon" />
                {t("Help")}
              </Nav.Link>
            </LinkContainer>
            {loggedIn && (
              <LinkContainer to="/profile">
                <Nav.Link className="nav-profile">
                  <div
                    className="profile-image"
                    style={{
                      backgroundImage: user.profilePicture ? `url('${user.profilePicture}')` : "",
                    }}
                  />
                </Nav.Link>
              </LinkContainer>
            )}
            {!loggedIn && (
              <div className="auth-buttons">
                <LinkContainer to="/sign-in">
                  <Nav.Link className="btn btn-primary btn-sm nav-btn">
                    <FontAwesomeIcon icon={faPlusCircle} className="button-icon" />
                    {t("Log in")}
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/sign-up">
                  <Nav.Link className="btn btn-outline-light btn-sm ml-4 nav-btn">{t("Register")}</Nav.Link>
                </LinkContainer>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
